import { useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { apiBaseURL } from '../config';
import {
  useProvidedConfiguration,
  useProvidedUserInfo,
} from '../context';
import useAPIAuthorized from './useAPIAuthorized';

const useBulldogAPI = (resourcename, qs = {}, endPoint = 'query') => {
  const [{ data: { tmp } = {} }] = useProvidedConfiguration();
  const [{ auto, profiles = [] } = {}] = useProvidedUserInfo();
  const { docId } = useParams();
  const { isLoading } = useAuth0();
  const [response, doFetch, ...rest] = useAPIAuthorized({
    method: 'GET',
    qs: {
      tmp,
      ...qs,
    },
    wait: true,
  });

  useEffect(() => {
    if (!auto && docId && profiles.length && !isLoading) {
      if (!profiles.find((profile) => profile._id === docId)) {
        // eslint-disable-next-line no-console
        console.error(`debería ser forze logout porque ${JSON.string(profiles)} !== ${docId}`);
        // logout();
      }
    }
  }, [docId, profiles, isLoading]);

  useEffect(() => {
    if (docId) {
      doFetch({
        url: `${apiBaseURL}/${endPoint}/${resourcename}/${docId}`,
      });
    }
  }, [docId]);

  return [response, doFetch, ...rest];
};

export default useBulldogAPI;
