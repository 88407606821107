import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedUserInfo,
} from '../../../../context';
import {
  useBulldogAPI,
} from '../../../../hooks';
import DocumentList from '../../../DocumentList';
import BoxHeroActivoPasivo from '../../../Box/HeroActivoPasivo';
import KnowMore from '../../../KnowMore';
import Link from '../../../Link';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  i18nKey: 'dash.sociedades-no-cotizadas',
};

const params = {
  ...cfg,
  defaults: [
    'name',
    'participacion',
    'adquisicion',
    'valor-teorico-participacion',
    'prestamo',
    'deudas-socio',
    'detalles',
    'comentario',
  ],
  endPoint: 'otras-sociedades',
  resources: [
    {
      id: 'name',
      className: 'sticky-col first-col',
      src: (data) => data?.name,
    },
    {
      id: 'participacion',
      src: (data) => `${data?.participacion}%`,
    },
    {
      id: 'adquisicion',
      src: (data, w = 'value') => data?.adquisicion?.[w],
      num: true,
    },
    {
      id: 'valor-teorico-participacion',
      src: (data, w = 'value') => data?.valorTeoricoParticipacion?.[w],
      num: true,
    },
    {
      id: 'prestamo',
      src: (data, w = 'value') => data?.prestamo?.[w],
      num: true,
    },
    {
      id: 'deudas-socio',
      src: (data, w = 'value') => data?.deudasSocio?.[w],
      num: true,
    },
    {
      id: 'detalles',
      className: 'has-text-centered',
      src: (data, w, translations) => (
        <Link
            className="button is-primary is-outlined is-small"
            params={{ sociedadId: data.id }}
            toId="datos-otras-sociedad"
        >
          {translations.formatMessage('dash.inmuebles.resources._ver-inmueble')}
        </Link>
      ),
    },
    {
      id: 'comentario',
      src: (data, w, translations) => {
        if (!data?.resumen) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: translations.formatMessage(`${cfg.i18nKey}.modal.title`, { name: data?.name }),
              body: data?.resumen,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const SociedadesDetallesOtrasSociedades = (props) => {
  const { qs } = props;
  const [{
    data: { data: totals = {} } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('otras-sociedades', { tmp: undefined, ...qs }, 'totals');
  const [{
    profiles = [],
  } = {}] = useProvidedUserInfo();
  const profile = profiles.find(({ type }) => type === 'company') || {};
  const { _id: docId } = profile || {};

  return (
    <>
      <BoxHeroActivoPasivo
        totals={totals}
        isLoading={isLoading || isFetching}
      />
      <div className="box box-white">
        <DocumentList
          {...params}
          docId={docId}
          qs={{ ...(params.qs ? params.qs : {}), ...qs }}
        />
      </div>
    </>
  );
};


SociedadesDetallesOtrasSociedades.defaultProps = defaultProps;
SociedadesDetallesOtrasSociedades.propTypes = propTypes;

export default SociedadesDetallesOtrasSociedades;
