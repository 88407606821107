/* eslint-disable no-undef */
import React, { useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
  useProvidedUserInfo,
} from '../context';
import Link from './Link';
import LoadingSpinner from './LoadingSpinner';
import Footer from './Footer';

import {
  staticContent,
} from '../config';

const cfg = {
  columns: [
    [
      { key: 'resumen', isPublic: true },
      { key: 'caracteristias-contratos', params: { tab: 'caracteristicas' } },
      { key: 'bancos' },
      { key: 'mutualidades' },
    ],
    [
      { key: 'inmuebles' },
      { key: 'sociedades-no-cotizadas', toId: 'sociedades' },
      { key: 'prestamos' },
      { key: 'otros-patrimonios' },
    ],
    [
      { key: 'seguros' },
      { key: 'declaraciones' },
      { key: 'entorno' },
    ],
    [
      { key: 'rankings' },
      { key: 'evolucion-ingresos' },
      { key: 'temporizadores' },
      { key: 'cronologia' },
    ],
  ],
};

const propTypes = {
  children: PropTypes.node.isRequired,
  mainClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
};
const defaultProps = {
  mainClassName: 'main',
};

const LayoutNav = (props) => {
  const { children, mainClassName, title } = props;
  const [displayModal] = useProvidedModal();
  const {
    isLoading, logout, user: { nickname, picture },
  } = useAuth0();
  const { docId } = useParams();
  const { pathname } = useLocation();
  const [{
    auto = false,
    profiles = [],
    scopes = [],
  } = {}, , setAuto] = useProvidedUserInfo();
  const profile = profiles.find((element) => element._id === docId) || {};
  const {
    permissions = [],
    img: pictureProfile = picture,
    name,
  } = profile || {};
  const [display, setDisplay] = useState(false);
  const translations = useProvidedTranslations();
  const isAdmin = useMemo(() => scopes.includes('admin'), [scopes]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div>
        <nav className="nav-main">
          <div className="nav-main-item nav-left">
            <div className="d-flex">
              <button className="button is-transparent is-rounded is-circle is-nav-toggle" type="button" onClick={() => setDisplay(!display)}>
                <span className="material-icons">menu</span>
              </button>
              <img src={`${staticContent}/images/pkf-attest.svg`} alt="PKF Attest" className="logo-nav is-hidden-mobile"></img>
            </div>
          </div>
          <div className="nav-main-item nav-center">
            <h1 className="has-text-centered">{title}</h1>
          </div>
          <div className="nav-main-item nav-right">
            <div className="dropdown is-hoverable is-right is-pulled-right">
              <div className="dropdown-trigger">
                <div className="d-flex button-persona">
                  <p>{name}</p>
                  <button className="button is-transparent is-circle" aria-haspopup="true" aria-controls="menuUser">
                    {auto && <span title="auto" className="badge is-warning" />}
                    <img alt={nickname} className="persona" src={pictureProfile} />
                  </button>
                </div>
              </div>
              <div className="dropdown-menu" id="menuUser" role="menu">
                <div className="dropdown-content">
                  {auto && (
                    <button type="button" className="dropdown-item button is-rounded-disabled is-warning" onClick={() => setAuto()}>
                      {translations.formatMessage('dash.layout-nav.disable-auto')}
                    </button>
                  )}
                  <Link toId="mis-datos" className="dropdown-item">
                    {translations.formatMessage('dash.layout-nav.mis-datos')}
                  </Link>
                  <Link toId="ayuda" className="dropdown-item">
                    {translations.formatMessage('dash.layout-nav.ayuda')}
                  </Link>
                  {isAdmin && (
                    <Link toId="admin" className="dropdown-item">
                      {translations.formatMessage('dash.layout-nav.admin')}
                    </Link>
                  )}
                  <hr className="dropdown-divider" />
                  <a className="dropdown-item" href="#" onClick={() => logout({ returnTo: window.location.origin })}>
                    {translations.formatMessage('dash.layout-nav.cerrar')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <main className={mainClassName}>
          <div className="container is-fluid">
            {children}
          </div>
        </main>
        <Footer />
      </div>
      {display && (
        <div className="overlay-nav" style={{ display: 'block' }}>
          <div className="overlay-content">
            <div className="columns is-multiline">
              {cfg.columns.map((column, i) => (
                <div key={i} className="column is-6-tablet is-3-desktop mb-0 p-0">
                  {column
                    .map(({
                      key, params, isPublic = false, toId = key,
                    }) => {
                      if (!permissions.includes(key) && !isPublic) {
                        return (
                        <Link key={`${i}${key}`} className='nav-item is-locked' onClick={() => displayModal({ id: 'upgrade' })}>
                            <>
                              <span className="material-icons">
                                lock
                              </span>
                              {' '}
                            </>
                          {translations.formatMessage(`dash.main-menu._${key}`)}
                        </Link>
                        );
                      }
                      return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Link key={`${i}${key}`} params={params} className="nav-item" toId={toId} pathname={pathname}>
                        {translations.formatMessage(`dash.main-menu._${key}`)}
                      </Link>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

LayoutNav.propTypes = propTypes;
LayoutNav.defaultProps = defaultProps;

export default LayoutNav;
