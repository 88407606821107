import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';
import BoxSingle from './Single';

const propTypes = {
  Activos: PropTypes.node,
  className: PropTypes.string,
  totals: PropTypes.shape({
    activos: PropTypes.number,
    pasivos: PropTypes.number,
  }).isRequired,
  i18nKey: PropTypes.string,
  isLoading: PropTypes.bool,
  Pasivos: PropTypes.node,
};
const defaultProps = {
  Activos: undefined,
  className: 'bg8',
  i18nKey: 'hero-activo-pasivo',
  Pasivos: undefined,
};

const BoxHeroActivoPasivo = (props) => {
  const {
    Activos: ActivosComponent,
    className,
    i18nKey,
    isLoading,
    Pasivos: PasivosComponent,
    totals: {
      activos,
      pasivos,
    } = {},
  } = props;
  const translations = useProvidedTranslations();

  return (
    <div className="box">
      <div className={`body-box bg-cover ${className}`}>
        <div className="columns is-multiline">
          {ActivosComponent || (
            <BoxSingle
              className="is-success"
              label={translations.formatMessage(`dash.box.${i18nKey}.activos`)}
              value={activos === 0 ? '-' : `${translations.formatNumber(activos)}€`}
              isLoading={isLoading}
            />)}
          {PasivosComponent || (
            <BoxSingle
              className="is-danger"
              label={translations.formatMessage(`dash.box.${i18nKey}.pasivos`)}
              value={pasivos === 0 ? '-' : `${translations.formatNumber(pasivos)}€`}
              isLoading={isLoading}
            />)}
        </div>
        <div className="has-text-centered">
          {!isLoading && <p className="qty big">{`${translations.formatNumber(activos + pasivos)}€`}</p>}
          <p className="qty-title">{translations.formatMessage(`dash.box.${i18nKey}.cifra-neta`)}</p>
        </div>
      </div>
    </div>
  );
};

BoxHeroActivoPasivo.propTypes = propTypes;
BoxHeroActivoPasivo.defaultProps = defaultProps;

export default BoxHeroActivoPasivo;
