import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCache } from '../../hooks';
// eslint-disable-next-line no-unused-vars
import context from './context';

const cfg = {
  default: 'en',
  endpoint: 'users/me/settings',
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

const LanguageProvider = (props) => {
  const { children } = props;
  const [language, setLanguage] = useCache('language', cfg.default);

  const value = useMemo(() => [
    language,
    setLanguage,
  ], [language, setLanguage]);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

LanguageProvider.propTypes = propTypes;

export default LanguageProvider;
