import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../context';

const propTypes = {
  inmueble: PropTypes.shape({
    id: PropTypes.string.isRequired,
    alquiler: PropTypes.shape({
      inquilino: PropTypes.string,
      inicio: PropTypes.number,
      fin: PropTypes.number,
      renta: PropTypes.shape({
        value: PropTypes.string,
      }),
      fianza: PropTypes.shape({
        value: PropTypes.string,
      }),
      estimado: PropTypes.shape({
        value: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

const cfg = [
  { id: 'ingresos-anuales-estimados', src: (inmueble) => inmueble?.alquiler?.estimado?.value },
  { id: 'gastos-comunidad-anual', src: (inmueble) => inmueble?.gastosComunidad?.value },
  { id: 'ibi', src: (inmueble) => inmueble?.ibi?.value },
  { id: 'seguro', src: (inmueble) => inmueble?.seguro?.value },
  { id: 'cuota-prestamo', src: (inmueble) => inmueble?.prestamo?.value },
  { id: 'rendimiento', src: (inmueble) => inmueble?.rendimento?.value },
  { id: 'rentabilidad', src: (inmueble) => inmueble?.rentabilidad },
];

const InmueblesDetallesIngresosGastos = (props) => {
  const { inmueble } = props;
  const translations = useProvidedTranslations();

  return (
    <>
      <header className="header-box ">
        <h2>{translations.formatMessage('dash.inmueble.ingresos-gastos.title')}</h2>
      </header>
      <div className="body-box b-0">
        <table className="table is-striped is-fullwidth mt-1">
          <tbody>
            {cfg.map(({ id, src }, i) => (
              <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                <td>{translations.formatMessage(`dash.inmueble.list._${id}`)}</td>
                <td className="has-text-right">{src(inmueble, translations)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

InmueblesDetallesIngresosGastos.propTypes = propTypes;

export default InmueblesDetallesIngresosGastos;
