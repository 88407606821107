import React from 'react';
import PropTypes from 'prop-types';
import ModalContainerBase from './Base';

const Container = ({ children, className }) => (
  <div className={className} style={{ zIndex: 20 }}>
    {children}
  </div>
);
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

const ModalContainerStandard = (props) => (
  <ModalContainerBase
    {...props}
    Container={Container}
  />
);

export default ModalContainerStandard;
