import React from 'react';
import PropTypes from 'prop-types';
import EntornosListado from './Listado';
import LayoutNav from '../LayoutNav';
import {
  useProvidedTranslations,
} from '../../context';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const Entornos = (props) => {
  const { qs } = props;
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage('dash.entorno.header')}>
      <div className="box box-white">
      <div className="body-box">
          <EntornosListado i18nKey="dash.entorno" qs={qs} {...props} />
      </div>
    </div>
    </LayoutNav>
  );
};

Entornos.defaultProps = defaultProps;
Entornos.propTypes = propTypes;

export default Entornos;
