import { useContext } from 'react';
import context from './context';

const useProvidedLanguage = () => {
  const languageContext = useContext(context);
  if (!languageContext) {
    throw new Error('language context its mandatory');
  }

  return languageContext;
};

export default useProvidedLanguage;
