/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../../../context';
import {
  useAPIAuthorized,
} from '../../../hooks';
import { apiBaseURL } from '../../../config';
import Notification from '../../Notification';

const cfg = {
  orderBy: [
    'ingresos',
    'patrimonios',
    'caracteristias-contratos',
    'datos-economicos-temporadas',
    'primas-individuales',
    'avance-primas',
    'calendario-cobro-temporadas',
    'bancos',
    'avales',
    'inmuebles',
    'cronologia-inmuebles',
    'mutualidades',
    'sociedades-no-cotizadas',
    'prestamos',
    'otros-patrimonios',
    'seguros',
    'entorno',
    'declaraciones',
    'rankings',
    'evolucion-patrimonio',
    'temporizadores',
    'cronologia',
    'personales',
  ],
  routines: {
    company: [],
    player: [{
      _id: 'contratos',
      payload: [
        'avance-primas',
        'calendario-cobro-temporadas',
        'caracteristias-contratos',
        'datos-economicos-temporadas',
        'primas-individuales',
      ],
    },
    ],
  },
};

const LoadingSpinner = () => (
  <div className="loader-wrapper">
    <div className="loader is-loading" />
  </div>
);

const propTypes = {
  docId: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
};

const Layout = ({
  children, error, handleCTA, hideModal, length, message, translations,
}) => (
  <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {translations.formatMessage('dash.admin.update-modal.title')}
          </p>
          <a className="button is-circle-sm" onClick={() => hideModal()}>
            <span className="material-icons">
              close
            </span>
          </a>
        </header>
        <section className="modal-card-body">
          {error && (
            <Notification
              message={message}
              type="danger"
            />
          )}
          {length !== 0 && (
            <Notification
              message={translations.formatMessage('dash.admin.update-modal.notification.success')}
              timeout={5}
            />
          )}
          <div className="columns is-multiline">
            {children}
          </div>
        </section>
        <footer className="footer-card">
          <div className="buttons-footer">
            <div className="buttons">
              <button
                className="button is-primary btn-block-xs"
                type="button"
                onClick={() => handleCTA()}
              >
                {translations.formatMessage('dash.document-list.modal.cta')}
              </button>
              <button
                className="button is-primary btn-block-xs"
                type="button"
                onClick={() => hideModal()}
              >
                {translations.formatMessage('dash.document-list.modal.close')}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
);

const AdminModalUpdate = (props) => {
  const {
    docId,
    profileType,
    resources,
  } = props;
  const [updating, setUpdating] = useState([]);
  const translations = useProvidedTranslations();
  const [, hideModal] = useProvidedModal();
  const [{
    data: { error, message, updating: { length } = [] } = {},
    isFetching,
    isLoading,
  }, doFetch] = useAPIAuthorized({
    method: 'POST',
    url: `${apiBaseURL}/import/${docId}`,
    wait: true,
  }, () => {
    setUpdating([]);
    setTimeout(() => {
      hideModal();
    }, 5500);
  });
  const handleClick = (resource) => {
    if (updating.includes(resource)) {
      setUpdating(updating.filter((el) => el !== resource));
      return;
    }
    setUpdating([...updating, resource]);
  };
  const handleCTA = () => {
    if (updating.length) {
      doFetch({ body: { updating } });
    }
  };
  const loading = isFetching || isLoading;

  if (length === 0 && !error && loading) {
    return (
      <Layout
        error={error}
        handleCTA={handleCTA}
        hideModal={hideModal}
        length={length}
        message={message}
        translations={translations}>
        <LoadingSpinner />
      </Layout>
    );
  }

  return (
    <Layout
      error={error}
      handleCTA={handleCTA}
      hideModal={hideModal}
      length={length}
      message={message}
      translations={translations}>
      {cfg.routines[profileType].length !== 0 && (
        <div className="content column is-12 mb-0 mt-0">
            <ul>
              {cfg.routines[profileType].map((routine) => (
                <li key={routine._id}>
                  <a onClick={() => (
                    setUpdating([...updating, ...routine.payload])
                  )}>
                    {translations.formatMessage(`dash.admin-modal.routine._${routine._id}`)}
                  </a>
                </li>
              ))}
            </ul>
        </div>
      )}
      {resources
        .map((id) => {
          const peso = cfg.orderBy.indexOf(id);
          return { id, peso: peso === -1 ? 100 : peso };
        })
        .sort((
          { peso: peso1 },
          { peso: peso2 },
        ) => peso1 - peso2)
        .map(({ id }) => (
        <div key={id} className="column is-6-tablet">
          <div className="field">
            <input
              checked={updating.includes(id)}
              className="is-checkradio is-info"
              id={`checkbox-${id}`}
              type="checkbox"
              onChange={() => handleClick(id)}
            />
            <label htmlFor={`checkbox-${id}`}>
              {translations.formatMessage(`dash.admin-list.resources._${id}`)}
            </label>
          </div>
        </div>
        ))}
    </Layout>
  );
};

AdminModalUpdate.propTypes = propTypes;

export default AdminModalUpdate;
