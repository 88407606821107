import { useEffect } from 'react';

/**
 * Listen for a shortcut
 * @param {String} shortcut - Shortcut key
 * @param {Function} onShortcut - Function to call on Shortcut
 * @param {Node} node - By default it's document (anywhere)
 */
// eslint-disable-next-line no-undef
const useShortcut = (shortcut, onShortcut, node = document) => {
  useEffect(() => {
    if (!shortcut) {
      return;
    }

    const handler = (e) => {
      if (e.key === shortcut) {
        onShortcut(e);
      }
    };
    node.addEventListener('keydown', handler);
    // eslint-disable-next-line consistent-return
    return () => node.removeEventListener('keydown', handler);
  }, [node, onShortcut, shortcut]);
};

export default useShortcut;
