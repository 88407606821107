import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../../context';

const propTypes = {
  body: PropTypes.string.isRequired,
  cta: PropTypes.string,
  title: PropTypes.string,
};
const defaultProps = {
  cta: undefined,
  title: undefined,
};

const ModalBasic = (props) => {
  const { body, cta, title } = props;
  const [, hideModal] = useProvidedModal();
  const translations = useProvidedTranslations();

  return (
    <div className="modal-card">
      <header className="modal-card-head">
        {title && (
          <p
            className="modal-card-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        )}
        <button className="button is-circle-sm" type="button" onClick={() => hideModal()}>
          <span className="material-icons">
            close
          </span>
        </button>
      </header>
      <section className="modal-card-body">
        <p className="lead markdown">
          <ReactMarkdown>
            {body}
          </ReactMarkdown>
        </p>
      </section>
      <footer className="modal-card-foot">
        <button className="button" type="button" onClick={() => hideModal()}>
          {cta || translations.formatMessage('dash.modal.basic.cta')}
        </button>
      </footer>
    </div>
  );
};

ModalBasic.propTypes = propTypes;
ModalBasic.defaultProps = defaultProps;

export default ModalBasic;
