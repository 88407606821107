import { useEffect } from 'react';
import {
  useParams,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { apiBaseURL } from '../config';
import {
  useProvidedUserInfo,
} from '../context';
import useAPIAuthorized from './useAPIAuthorized';

const useBulldogAPIByPk = (resourcename, id) => {
  const [{ profiles = [] } = {}] = useProvidedUserInfo();
  const { docId } = useParams();
  const { isLoading } = useAuth0();
  const [response, doFetch, ...rest] = useAPIAuthorized({
    method: 'GET',
    wait: true,
  });

  useEffect(() => {
    if (docId && profiles.length && !isLoading) {
      if (!profiles.find((profile) => profile._id === docId)) {
        // eslint-disable-next-line no-console
        console.error(`debería ser forze logout porque ${JSON.string(profiles)} !== ${docId}`);
        // logout();
      }
    }
  }, [docId, profiles, isLoading]);

  useEffect(() => {
    if (docId && id) {
      doFetch({
        url: `${apiBaseURL}/query/${resourcename}/${docId}/${id}`,
      });
    }
  }, [docId]);

  return [response, doFetch, ...rest];
};

export default useBulldogAPIByPk;
