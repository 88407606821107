import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import Inmuebles from './index';

const InmueblesWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage('dash.inmuebles.header')}>
      <Inmuebles />
    </LayoutNav>
  );
};

export default InmueblesWithLayout;
