import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../../context';
import LoadingSpinner from '../LoadingSpinner';
import formatDateFullYear from '../../lib/formatDateFullYear';

const propTypes = {
  cta: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      id: PropTypes.string,
      title: PropTypes.string,
      more: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
  cta: undefined,
};

const CronologiaLayout = (props) => {
  const {
    cta, data, isLoading,
  } = props;
  const translations = useProvidedTranslations();
  const [displayModal] = useProvidedModal();
  const handleDisplayModal = (params) => {
    displayModal({
      id: 'basic',
      params,
    });
  };

  const years = useMemo(() => {
    const allYears = data
      .map(({ date }) => new Date(date * 1000).getFullYear())
      .sort((dateA, dateB) => dateB - dateA);
    return [...new Set(allYears)];
  }, [data]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="columns is-centered">
        <div className="column is-6-tablet">
          <div className="timeline">
            {years.map((year) => (
              <Fragment key={year}>
                {data
                  .filter((element) => (
                    new Date(element.date * 1000).getFullYear() === year
                  ))
                  .sort(({ date: dateA }, { date: dateB }) => dateB - dateA)
                  .map((item) => (
                    <div key={item?.id} className="timeline-item">
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <p className="heading">{formatDateFullYear(item?.date * 1000)}</p>
                        <p>{item?.title}</p>
                        <p className="mt-1">
                          <a
                            className="button is-primary is-outlined is-small"
                            onClick={() => handleDisplayModal({
                              body: item?.more?.join(''),
                              title: item?.title,
                            })}
                            >
                              {cta || translations.formatMessage('dash.cronologia.cta.know-more')}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
                <header className="timeline-header">
                  <span className="tag is-primary">{year}</span>
                </header>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

CronologiaLayout.propTypes = propTypes;
CronologiaLayout.defaultProps = defaultProps;

export default CronologiaLayout;
