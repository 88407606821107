import React from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import ContratoCalendario from './Calendario';
import ContratoCaracteristicas from './Caracteristicas';
import Link from '../Link';

const cfg = {
  tabs: [
    { _id: 'caracteristicas', default: true, Component: ContratoCaracteristicas },
    { _id: 'calendario', default: true, Component: ContratoCalendario },
  ],
};

const Contrato = (props) => {
  const translations = useProvidedTranslations();
  const { tab: selected = 'caracteristicas' } = useParams();
  const Tab = cfg.tabs.find(({ _id }) => selected === _id);

  if (!Tab) {
    return null;
  }

  return (
    <LayoutNav title={translations.formatMessage('dash.personales.header')}>
      <div className="container is-fluid">
        <div className="tabs is-centered mt-1">
          <ul>
            {cfg.tabs.map((tab) => (
              <li key={tab._id} className={tab._id === selected ? 'is-active' : undefined}>
                <Link toId="caracteristias-contratos" params={{ tab: tab._id }}>
                  {translations.formatMessage(`dash.contrato.tab._${tab._id}`)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="box box-white">
          <Tab.Component {...props} />
        </div>
      </div>
    </LayoutNav>
  );
};

export default Contrato;
