import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import {
  useProvidedTranslations,
} from '../../../context';
import DocumentList from '../../DocumentList';
import LoadingSpinner from '../../LoadingSpinner';
import KnowMore from '../../KnowMore';
import {
  useBulldogAPI,
} from '../../../hooks';
import {
  chartColors,
} from '../../../config';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  chart: {
    options: {
      colors: chartColors,
      legend: { position: 'bottom' },
      isStacked: true,
      hAxis: {
        format: '####',
      },
    },
  },
  i18nKey: 'dash.declaraciones',
};

const params = {
  ...cfg,
  defaults: [
    'year',
    'imp-sociedades',
    'iva',
    'ibi',
    'itp',
    'otros',
    'comentario',
  ],
  endPoint: 'declaraciones',
  resources: [
    {
      id: 'year',
      className: 'sticky-col first-col',
      src: (data) => data?.year,
    },
    {
      id: 'imp-sociedades',
      className: 'has-text-right',
      src: (data, w = 'value') => data?.iSociedades?.[w],
      num: true,
    },
    {
      id: 'iva',
      className: 'has-text-right',
      src: (data, w = 'value') => data?.iva?.[w],
      num: true,
    },
    {
      id: 'ibi',
      className: 'has-text-right',
      src: (data, w = 'value') => data?.ibi?.[w],
      num: true,
    },
    {
      id: 'itp',
      className: 'has-text-right',
      src: (data, w = 'value') => data?.itp?.[w],
      num: true,
    },
    {
      id: 'otros',
      className: 'has-text-right',
      src: (data, w = 'value') => data?.otros?.[w],
      num: true,
    },
    {
      id: 'comentario',
      graph: false,
      src: (data) => {
        if (!data?.comentario) {
          return '-';
        }

        return (
          <KnowMore
            modal={{
              title: `${data?.year}`,
              body: data?.comentario,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Declaraciones = (props) => {
  const { qs } = props;
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI(params.endPoint, { tmp: undefined, ...qs });
  const chartData = useMemo(() => {
    const sortedData = data.sort(cfg.sort);
    const arr = [
      params.resources
        .filter(({ graph = true }) => graph)
        .map(({ id }) => (
          translations.formatMessage(`${cfg.i18nKey}.resources._${id}`)
        ))];
    sortedData
      .sort(cfg.sort)
      .forEach((element) => {
        const row = [];
        params.resources
          .filter(({ graph = true }) => graph)
          .forEach(({ src }) => {
            row.push(src(element, 'raw'));
          });
        arr.push(row);
      });
    return arr;
  }, [data]);
  const loading = isLoading || isFetching;

  return (
    <>
      <div className="box box-white">
        <DocumentList
          {...params}
          qs={{ ...(params.qs ? params.qs : {}), ...qs }}
        />
      </div>
      <div className="box box-white">
        <div className="body-box b-0">
          {loading
            ? <LoadingSpinner />
            : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={chartData}
                options={cfg.chart.options}
              />
            )}
        </div>
      </div>
    </>
  );
};

Declaraciones.defaultProps = defaultProps;
Declaraciones.propTypes = propTypes;

export default Declaraciones;
