import { useCallback } from 'react';
import {
  autoStorageName,
  isAutoEnabled,
} from '../config';
import useCache from './useCache';

function useAuto(key = autoStorageName) {
  // eslint-disable-next-line no-console
  const [data, saveData] = useCache(key, null);

  const set = useCallback((sub) => {
    if (!isAutoEnabled) {
      return;
    }
    saveData(sub);
  }, []);

  const clear = (() => {
    saveData(null);
  }, []);

  return [data, set, clear];
}

export default useAuto;
