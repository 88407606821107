import Debug from 'debug';

const debug = Debug('bulldog:parseTimeAmount');
const defaultTimeUnits = {
  year: {
    en: 'year',
    es: 'año',
  },
  month: {
    en: 'month',
    es: 'mes|es',
  },
  day: {
    en: 'day',
    es: 'día',
  },
  hour: {
    en: 'hour',
    es: 'hora',
  },
  minute: {
    en: 'minute',
    es: 'minuto',
  },
  now: {
    en: 'now',
    es: 'ahora',
  },
};

const parseTimeUnit = (countToParse, name, locale, timeUnits) => {
  const count = Math.trunc(countToParse);
  const translation = timeUnits[name][locale] || name;
  if (count < 2) {
    const translationSingular = translation.split('|')[0];
    return `${(count).toFixed(0)} ${translationSingular}`;
  }

  const translationPlural = translation.includes('|') ? translation.replace('|', '') : `${translation}s`;
  return `${count.toFixed(0)} ${translationPlural}`;
};

/**
 * Returns an string with elapsed time since date
 * @param {Number} timeAmount - Seconds passed
 * @param {String} locale - Ex. es, en
 */
const parseTimeAmount = (timeAmount, locale, timeUnits = defaultTimeUnits) => {
  debug('parseTimeAmount', timeAmount, locale);
  const years = timeAmount / (365 * 24 * 60 * 60);
  if (years >= 1) {
    return parseTimeUnit(years, 'year', locale, timeUnits);
  }
  const months = timeAmount / (30 * 24 * 60 * 60);
  if (months >= 1) {
    return parseTimeUnit(months, 'month', locale, timeUnits);
  }

  const days = timeAmount / (24 * 60 * 60);
  if (days >= 1) {
    return parseTimeUnit(days, 'day', locale, timeUnits);
  }

  const hours = timeAmount / (60 * 60);
  if (hours >= 1) {
    return parseTimeUnit(hours, 'hour', locale, timeUnits);
  }

  const minutes = timeAmount / 60;
  if (minutes >= 1) {
    return parseTimeUnit(minutes, 'minute', locale, timeUnits);
  }

  return timeUnits.now[locale];
};

export default parseTimeAmount;
