/* eslint-disable max-len */
import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';

const Ayuda = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage('dash.ayuda.header')}>
      <div className="columns is-centered">
        <div className="column is-8-desktop">
            <div className="box box-white">
              <div className="body-box">
                  <div className="article">
                    <h2>1. DASHBOARD O PANTALLA PRINCIPAL</h2>
                    <p>Se mostrar&aacute;n los datos de ingresos y patrimonio m&aacute;s importantes.</p>
                    <p>En relaci&oacute;n a los ingresos Brutos:</p>
                    <ul>
                        <li><strong>Cantidad bruta total (en verde)</strong>: Cantidad bruta a cobrar en la temporada seg&uacute;n el contrato
                          de trabajo. Si la cantidad fijada en el contrato fuera &uacute;nicamente en neto, se calcular&aacute; el bruto
                          correspondiente aplicando la normativa tributaria vigente.
                        </li>
                        <li><strong>Cantidad bruta pagada (en rojo)</strong>: Cantidad bruta cobrada correspondiente a esa temporada de
                          conformidad con la informaci&oacute;n suministrada.
                        </li>
                        <li><strong>Cantidad bruta pendiente (en blanco)</strong>: Cantidad bruta pendiente de cobrar correspondiente a esa
                          temporada de conformidad con la informaci&oacute;n suministrada.
                        </li>
                    </ul>
                    <p>En relaci&oacute;n a los ingresos netos:</p>
                    <ul>
                        <li><strong>Cantidad neta total (en verde)</strong>: Cantidad neta a cobrar en la temporada seg&uacute;n el contrato
                          de trabajo. Si la cantidad fijada en el contrato fuera &uacute;nicamente en bruto, se calcular&aacute; el bruto
                          correspondiente aplicando la normativa tributaria vigente. Si el contrato fijara una cantidad bruta pero
                          tambi&eacute;n una cantidad m&iacute;nima neta, figurar&aacute; en este apartado esta &uacute;ltima.
                        </li>
                        <li><strong>Cantidad neta pagada (en rojo)</strong>: Cantidad neta cobrada correspondiente a esa temporada de
                          conformidad con la informaci&oacute;n suministrada.
                        </li>
                        <li><strong>Cantidad bruta pendiente (en blanco)</strong>: Cantidad neta pendiente de cobrar correspondiente a esa
                          temporada de conformidad con la informaci&oacute;n suministrada.
                        </li>
                    </ul>
                    <p>En relaci&oacute;n con las cantidades de temporadas anteriores figurar&aacute;n las cantidades brutas y netas,
                        totales, pagadas y pendientes correspondientes a la temporada anterior y que se prevea que se van a recibir durante la
                        temporada actual. El cuadro de la situaci&oacute;n patrimonial no cambiar&aacute; y seguir&aacute; refiri&eacute;ndose
                        a la fecha que figure.
                    </p>
                    <p className="strong">En relaci&oacute;n al Resumen de Patrimonio:</p>
                    <p>Resumen del patrimonio a la fecha que figura en el t&iacute;tulo de la tabla resumen. Figurar&aacute; la fecha de la
                        &uacute;ltima actualizaci&oacute;n, aunque no se haya actualizado la totalidad del patrimonio. Por ejemplo, si se han
                        actualizado las posiciones patrimoniales de todos los bancos menos de uno, que no ha suministrado la
                        informaci&oacute;n, se pondr&aacute; la fecha actualizada y se har&aacute; menci&oacute;n en el apartado
                        correspondiente del banco, que falta por tener una actualizaci&oacute;n de esa entidad.
                    </p>
                    <ul>
                        <li><strong>Activos Bancos</strong>: Posiciones patrimoniales con saldo acreedor en el banco.</li>
                        <li><strong>Pasivos Bancos</strong>: Posiciones con saldo deudor con el banco.</li>
                        <li><strong>Activos Inmuebles</strong>: Valor de adquisici&oacute;n de los inmuebles en propiedad</li>
                        <li><strong>Pasivos Inmuebles</strong>: Cargas o grav&aacute;menes del inmueble. Las deudas contra&iacute;das para la
                          compra de los inmuebles o sus hipotecas no figurar&aacute;n en este apartado si no en el de pasivos del banco o de
                          Otros pasivos.
                        </li>
                        <li><strong>Activos Sociedades</strong>: Valor te&oacute;rico contable de la sociedad no cotizada en la que se tenga
                          participaci&oacute;n, seg&uacute;n el &uacute;ltimo balance que se posea. Si no se tuviera o se optase por otro,
                          figurar&aacute; el de adquisici&oacute;n y se detallar&aacute; en el apartado de sociedades. Tambi&eacute;n
                          figurar&aacute; en esta cifra, el valor de los pr&eacute;stamos que se hayan concedido por parte del usuario a la
                          sociedad.
                        </li>
                        <li><strong>Pasivos Sociedades</strong>: Valor de las deudas que tiene el usuario con esa sociedad.</li>
                        <li><strong>Activos Pr&eacute;stamos</strong>: Valor de las cantidades prestadas a terceros. Representan las deudas
                          que otras personas tienen con el usuario salvo si es una sociedad del propio usuario. En ese caso, figurar&aacute;
                          en el apartado de sociedades.
                        </li>
                        <li><strong>Pasivos pr&eacute;stamos</strong>: Si al prestar una cantidad a otra persona, se genera alguna
                          obligaci&oacute;n con contenido econ&oacute;mico, figurar&aacute; aqu&iacute;.
                        </li>
                        <li><strong>Activos Otros</strong>: Valores de bienes patrimoniales no comprendidos en los apartados anteriores
                          (coches, caballos, cuadros&hellip;)
                        </li>
                        <li><strong>Pasivos Otros</strong>: Deudas contra&iacute;das con terceros u otras obligaciones de pago no comprendidas
                          en los apartados anteriores.
                        </li>
                    </ul>
                    <h2>2. CONTRATO. CARACTER&Iacute;STICAS PRINCIPALES Y CALENDARIO</h2>
                    <h3>2.A. Caracter&iacute;sticas principales del contrato. Datos B&aacute;sicos:</h3>
                    <ul>
                        <li><strong>Nombre del equipo y duraci&oacute;n del contrato</strong>: La fecha de comienzo ser&aacute; la del
                          &uacute;ltimo contrato independientemente de que haya habido adendas o actualizaciones.
                        </li>
                        <li><strong>Temporadas contractuales</strong>: N&uacute;mero de temporadas fijadas en el contrato de trabajo</li>
                        <li><strong>Temporada opcional</strong>: Temporada adicional que no es segura que suceda ya que depende de hechos
                          todav&iacute;a por suceder. Por ejemplo, ser&aacute; temporada adicional si depende de que en la anterior temporada
                          se hayan disputado una serie de partidos m&iacute;nimos.
                        </li>
                        <li><strong>Cl&aacute;usula de rescisi&oacute;n</strong>: Clausula de resoluci&oacute;n anticipada que figure en el
                          contrato de trabajo.
                        </li>
                        <li><strong>Clausulas con seguimiento</strong>: Cl&aacute;usulas del contrato sobre las que se considere que hay que
                          hacer un especial control.
                        </li>
                        <li><strong>Cesi&oacute;n</strong>: Figurar&aacute; s&iacute; o no dependiendo de c&oacute;mo sea la naturaleza
                          contractual del equipo en el que est&eacute; jugado en ese momento.
                        </li>
                    </ul>
                    <h3>2.B. Caracter&iacute;sticas principales del contrato. Datos econ&oacute;micos temporadas</h3>
                    <ul>
                        <li>
                          Datos econ&oacute;micos:
                          <ul>
                              <li><strong>Salario fijo</strong>: N&oacute;minas o primas que se percibir&aacute;n de forma segura y no dependen
                                de ning&uacute;n elemento adicional para que se generen. Pueden ser n&oacute;minas y/o prima de fichaje.
                              </li>
                              <li><strong>Primas individuales</strong>: Son la parte de retribuci&oacute;n variable que consistan en primas
                                fijadas de forma individual en el contrato de trabajo y que son exclusivas del usuario.
                              </li>
                              <li><strong>Primas colectivas</strong>: Parte de retribuci&oacute;n variable que se negocie entre el club y la
                                plantilla para cada temporada y que dependa de objetivos colectivos.
                              </li>
                              <li>
                                <strong>Otras consideraciones</strong>:
                                <ul>
                                    <li><strong>Retribuciones en Especie</strong>: Retribuciones no dinerarias: Coche, casa&hellip;</li>
                                    <li><strong>Reducci&oacute;n en caso de descenso</strong>: Penalizaciones salariales previstas en el contrato
                                      en caso de descenso de categor&iacute;a
                                    </li>
                                    <li><strong>Aumentos en caso de ascenso</strong>: Aumentos individuales previstos en el contrato en caso de
                                      ascenso de categor&iacute;a.
                                    </li>
                                </ul>
                              </li>
                              <li>Cuadro de primas:</li>
                          </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>Caracter&iacute;sticas concretas de cada prima, que est&eacute; vigente en esa temporada, con el dato del objetivo
                          junto con un % que representa su nivel de cumplimiento.
                        </li>
                    </ul>
                    <ul>
                        <li>
                          Avance de primas:
                          <ul>
                              <li>Figurar&aacute;n cada uno de los hechos relevantes (ej: partidos o goles) que puedan provocar acercarse
                                m&aacute;s al objetivo fijado en la prima, detallando si computan o no para la prima. El apartado de m&aacute;s
                                informaci&oacute;n conecta con la informaci&oacute;n web de d&oacute;nde se han extractado los datos. El
                                apartado comentarios servir&aacute; para detallar alguna cuesti&oacute;n que se estime conveniente.
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <h3>2.C. Calendario contractual de cobros</h3>
                    <ul>
                        <li>Figuran las cantidades que, seg&uacute;n el contrato, se devengar&aacute;n durante la temporada y que se conozcan
                          con antelaci&oacute;n
                        </li>
                        <li>
                          <strong>Tipo de Ingresos</strong>: Pueden ser:
                          <ul>
                              <li>N + mes: N&oacute;mina de un mes concreto</li>
                              <li>Prima de fichaje</li>
                              <li>Otros</li>
                          </ul>
                        </li>
                        <li><strong>Equipo</strong>: Equipo en el que se genera la cantidad</li>
                        <li><strong>Cantidad Bruta</strong>: Cantidad antes de impuestos y seguridad social</li>
                        <li><strong>IRPF/IRNR</strong>: Cantidad retenida en concepto de impuestos personales</li>
                        <li><strong>Seguridad Social</strong>: Cantidad retenida en concepto de cuotas a la Seguridad Social</li>
                        <li><strong>Otros</strong>: Cantidades restadas por el club. Ej: Entradas, camisetas&hellip;</li>
                        <li><strong>Cantidad Neta</strong>: Cantidad prevista de ingreso en la cuenta del usuario</li>
                        <li><strong>Fecha prevista de cobro</strong>: Fecha en la que seg&uacute;n el contrato se deba abonar</li>
                        <li><strong>Cantidad Bruta cobrada</strong>: Cantidad cobrada ya sea total o parcial</li>
                        <li><strong>Cantidad Neta cobrada</strong>: Cantidad cobrada ya sea total o parcial</li>
                        <li><strong>Fecha de cobro</strong>: Fecha en la que realmente se cobr&oacute;</li>
                        <li><strong>Cantidad bruta pendiente</strong>: Cantidad sin percibir ya sea total o parcial</li>
                        <li><strong>Cantidad neta pendiente</strong>: Cantidad sin percibir ya sea total o parcial</li>
                        <li><strong>Comentarios</strong>: Anotaciones que se entiendan adecuadas para explicar alg&uacute;n aspecto</li>
                    </ul>
                    <h2>3. BANCOS</h2>
                    <ul>
                        <li><strong>TOTAL ACTIVOS FINANCIEROS:</strong> Suma de todas las posiciones de activos que haya en los bancos.</li>
                        <li><strong>TOTAL PASIVOS FINANCIEROS:</strong> Suma de todas las posiciones de pasivos que haya en los bancos.
                          Fundamentalmente cantidades adeudadas en concepto de pr&eacute;stamos.
                        </li>
                        <li><strong>CIFRA NETA:</strong> Diferencia entre los activos y pasivos de los bancos.</li>
                        <li>
                          <strong>Cuadro de &ldquo;PATRIMONIO FINANCIERO&rdquo;:</strong> En cada l&iacute;nea figurar&aacute;n las
                          cantidades que haya depositadas en cada uno de los bancos diferenciando el tipo de producto financiero (Cuentas
                          corrientes, Cuentas valores, Fondos de Inversi&oacute;n, ETFS e Inversiones alternativas, Otros, planes de pensiones
                          y pasivos):
                          <ul>
                              <li>Se detallar&aacute; el activo, pasivo y neto patrimonial de cada banco.</li>
                              <li>
                                En la opci&oacute;n ver detalles figurar&aacute;n datos que se consideren relevantes como
                                <ul>
                                    <li>Detalles de la oficina donde figuren las posiciones</li>
                                    <li>Detalles de los activos: Coincidir&aacute;n con los del cuadro anterior</li>
                                    <li>Detalles de los pasivos: Coincidir&aacute;n con los del cuadro anterior</li>
                                </ul>
                              </li>
                              <li>
                                <strong>Gr&aacute;ficos:</strong>
                                <ul>
                                    <li><strong>Gr&aacute;fico de Activos:</strong> Distribuci&oacute;n porcentual de cada uno de los tipos de
                                      productos de activos respecto del total de activos bancarios
                                    </li>
                                    <li><strong>Gr&aacute;fico de solvencia:</strong> % que representan los pasivos bancarios (deudas) respecto de
                                      los activos bancarios.
                                    </li>
                                    <li><strong>Distribuci&oacute;n de activos:</strong> Distribuci&oacute;n porcentual de activos detallando el %
                                      que hay depositado en cada banco.
                                    </li>
                                    <li><strong>Distribuci&oacute;n de pasivos:</strong> Distribuci&oacute;n porcentual de pasivos detallando el %
                                      que hay contratado en cada banco.
                                    </li>
                                </ul>
                              </li>
                              <li>
                                Otros productos:
                                <ul>
                                    <li><strong>Seguros:</strong> Datos de cada uno de los seguros contratados por el usuario</li>
                                    <li><strong>Avales:</strong> Avales otorgado a un banco como garant&iacute;a del cumplimiento de una deuda de
                                      otra persona ya sea f&iacute;sica como jur&iacute;dica.
                                    </li>
                                </ul>
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <h2>4. MUTUALIDAD DEPORTISTAS</h2>
                    <ul>
                        <li><strong>TOTAL ACTIVOS:</strong> Derechos consolidados de la Mutualidad de deportistas profesionales. Representa la
                          cantidad de aportaciones realizadas durante los diferentes ejercicios, sumando y/o restando la rentabilidad
                          producida.
                        </li>
                        <li><strong>Cuadro de hist&oacute;rico de aportaciones: </strong>Detalle de cada uno de los ejercicios diferenciando
                          la cantidad al comienzo del mismo, la aportaci&oacute;n, la rentabilidad y la cantidad al final del ejercicio.
                        </li>
                    </ul>
                    <h2>5. INMUEBLES</h2>
                    <ul>
                        <li>
                          <strong>TOTAL ACTIVOS INMOBILIARIOS:</strong> Valor de los diferentes inmuebles que se tenga en propiedad.
                          <ul>
                              <li>El valor que se tendr&aacute; en cuenta, salvo que se indique lo contrario, ser&aacute; el valor de
                                adquisici&oacute;n.
                              </li>
                              <li>En el caso de tener tan s&oacute;lo un %, figurar&aacute; el valor correspondiente al % del usuario.</li>
                              <li>Si el usuario est&aacute; casado en gananciales, podr&aacute; figurar la cantidad global seg&uacute;n se haya
                                convenido previamente. Se detallar&aacute; en el apartado de comentarios de cada inmueble.
                              </li>
                          </ul>
                        </li>
                        <li>
                          Listado de inmuebles:
                          <ul>
                              <li>
                                Listado de los diferentes inmuebles en propiedad, ya sea parcial o total, del usuario, detallando:
                                <ul>
                                    <li>Municipio</li>
                                    <li>Direcci&oacute;n</li>
                                    <li>Titularidad: % en propiedad sobre el inmueble</li>
                                    <li>Destinos: Desocupado, alquilado y/o vivienda habitual</li>
                                    <li>Valor de adquisici&oacute;n</li>
                                    <li>Valor de pr&eacute;stamos: La cantidad adeudada para la compra del inmueble figurar&aacute; en el apartado
                                      de pasivos bancarios
                                    </li>
                                    <li>Detalles: Ficha de cada uno de los inmuebles</li>
                                </ul>
                              </li>
                              <li>
                                Ficha de cada uno de los inmuebles:
                                <ul>
                                    <li>Datos del inmueble: Datos con relevancia econ&oacute;mica o jur&iacute;dica</li>
                                    <li>Cronolog&iacute;a: Hechos relevantes de cada uno de los inmuebles con conveniencia de ser recordados</li>
                                    <li>Ingresos y gastos: Estimados de cada inmueble para obtener su rentabilidad.</li>
                                </ul>
                              </li>
                              <li>
                                Ingresos y gastos:
                                <ul>
                                    <li>Cuadro de los inmuebles, detallando cada uno de los ingresos y gastos que se hayan considerado relevantes
                                      para saber la rentabilidad. En este cuadro figurar&aacute;n todos los inmuebles que posea el usuario
                                    </li>
                                </ul>
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <h2>6. SOCIEDADES</h2>
                    <ul>
                        <li><strong>TOTAL ACTIVOS:</strong> Valor te&oacute;rico contable de la sociedad no cotizada en la que se tenga
                          participaci&oacute;n, seg&uacute;n el &uacute;ltimo balance que se posea. Si no se tuviera o se optase por otro,
                          figurar&aacute; el de adquisici&oacute;n y se detallar&aacute; en el apartado de sociedades. Tambi&eacute;n
                          figurar&aacute; en esta cifra, el valor de los pr&eacute;stamos que se hayan concedido por parte del usuario a la
                          sociedad y resten por devolver.
                        </li>
                        <li><strong>TOTAL PASIVOS:</strong> Valor total de las deudas contra&iacute;das por el usuario y que se deban a la
                          sociedad.
                        </li>
                        <li>
                          Listado de sociedades:
                          <ul>
                              <li>
                                Listado de las diferentes sociedades en las que se ostente participaci&oacute;n en su capital social,
                                detallando:
                                <ul>
                                    <li>Nombre de la sociedad</li>
                                    <li>% de participaci&oacute;n</li>
                                    <li>Valor de adquisici&oacute;n: Coste de adquisici&oacute;n de las participaciones.</li>
                                    <li>Valor te&oacute;rico de participaci&oacute;n: Resulta de la contabilidad. Es la cifra resultante de
                                      dividir el Patrimonio neto entre el n&uacute;mero de participaciones totales y multiplicarlo por el
                                      n&uacute;mero de participaciones del usuario.
                                    </li>
                                    <li>Pr&eacute;stamos concedidos: Cantidades adeudadas por la sociedad al socio.</li>
                                    <li>Deudas del socio: Cantidades adeudadas por el socio a la sociedad.</li>
                                    <li>Ver detalles: Seleccionando VER DETALLES se dirigir&aacute; a un Men&uacute; propio de la sociedad.</li>
                                </ul>
                              </li>
                              <li>
                                MEN&Uacute; PROPIO DE LA SOCIEDAD: Cada sociedad del usuario tendr&aacute; un submen&uacute; propio.
                                Partir&aacute; de un cuadro resumen del patrimonio de la propia sociedad en el que se detallar&aacute; las
                                posiciones patrimoniales de Activos y Pasivos de:
                                <ul>
                                    <li>Bancos</li>
                                    <li>Inmuebles</li>
                                    <li>Pr&eacute;stamos</li>
                                    <li>Otras sociedades</li>
                                    <li>Otros</li>
                                </ul>
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <p>El Men&uacute; de la sociedad tendr&aacute; pr&aacute;cticamente el mismo contenido y estructura que el de usuario
                        persona f&iacute;sica con la caracter&iacute;stica de que tiene un apartado adicional explicativo de los socios y
                        administradores dentro de la opci&oacute;n &ldquo;Datos sociedad&rdquo;.
                    </p>
                    <p>Al seleccionar la X de la parte de arriba a la derecha, se sale del men&uacute; de la sociedad y se vuelve a la parte
                        de la aplicaci&oacute;n del usuario persona f&iacute;sica.
                    </p>
                    <h2>7. PR&Eacute;STAMOS CONCEDIDOS</h2>
                    <ul>
                        <li><strong>TOTAL ACTIVOS:</strong> Cantidad total adeudada por terceros en concepto de pr&eacute;stamo.</li>
                        <li><strong>TOTAL PASIVOS:</strong> Cantidad adeudada que se haya generado por cualquier motivo al haber concedido un
                          pr&eacute;stamo a un tercero
                        </li>
                        <li>
                          <strong>Listado de pr&eacute;stamos:</strong> Relaci&oacute;n de los diferentes pr&eacute;stamos concedidos a
                          terceros con las caracter&iacute;sticas principales de cada uno de ellos:
                          <ul>
                              <li>Deudor: Persona que debe el pr&eacute;stamo.</li>
                              <li>Fecha de concesi&oacute;n: Fecha en la que se inici&oacute; el pr&eacute;stamo</li>
                              <li>Fecha de vencimiento: Fecha en la que se debe devolver el pr&eacute;stamo</li>
                              <li>Capital prestado: Cantidad prestada al inicio</li>
                              <li>Capital devuelto: Cantidad ya amortizada por el deudor</li>
                              <li>Capital pendiente: Cantidad todav&iacute;a por amortizar</li>
                              <li>Tipo de inter&eacute;s: % de inter&eacute;s remuneratorio</li>
                              <li>Intereses cobrados: Cantidad de intereses sin retenci&oacute;n fiscal ya cobrados.</li>
                          </ul>
                        </li>
                    </ul>
                    <h2>8. OTROS PATRIMONIOS</h2>
                    <ul>
                        <li><strong>TOTAL ACTIVOS:</strong> Valor total del resto de elementos patrimoniales que no se hayan integrados en
                          ninguna de las anteriores categor&iacute;as y que se consideran relevantes.
                        </li>
                        <li><strong>TOTAL PASIVOS:</strong> Valor total del resto de pasivos patrimoniales que no se hayan integrados en
                          ninguna de las anteriores categor&iacute;as y que se consideran relevantes.
                        </li>
                        <li>Los valores se considerar&aacute;n inicialmente los de adquisici&oacute;n salvo que se decida que hay otro
                          m&aacute;s adecuado (Ej: Tablas de hacienda de los veh&iacute;culos)
                        </li>
                        <li>La valoraci&oacute;n se referir&aacute; siempre a la fecha que figure en el t&iacute;tulo de la tabla</li>
                    </ul>
                    <h2>9. SEGUROS</h2>
                    <ul>
                        <li>Listado de los diferentes seguros contratados por el usuario.</li>
                        <li>No tienen un valor patrimonial porque depender&aacute; de un hecho incierto del que no se sabe si ocurrir&aacute;.
                        </li>
                    </ul>
                    <h2>10. DECLARACIONES FISCALES</h2>
                    <ul>
                        <li>
                          Resultados de los diferentes impuestos abonados por el usuario seg&uacute;n el ejercicio fiscal:
                          <ul>
                              <li><strong>IRPF:</strong> Impuesto sobre la Renta de las Personas F&iacute;sicas</li>
                              <li><strong> Patrimonio:</strong> Impuesto sobre el patrimonio</li>
                              <li><strong>IVA:</strong> Impuesto sobre el Valor A&ntilde;adido</li>
                              <li><strong>IBI:</strong> Sumatorio de las contribuciones urbanas de todos los inmuebles en propiedad</li>
                              <li><strong>ITP:</strong> Impuesto sobre Transmisiones Patrimoniales</li>
                              <li><strong>Retenciones IRPF:</strong> Cantidades retenidas en concepto de IRPF en el ejercicio.</li>
                              <li><strong>Comentarios:</strong> Explicaciones adicionales de cada uno de los ejercicios.</li>
                          </ul>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Gr&aacute;fico impuestos:</strong> Presentaci&oacute;n gr&aacute;fica de los resultados fiscales expuestos
                          en la relaci&oacute;n anterior.
                        </li>
                    </ul>
                    <h2>11. REPRESENTANTES</h2>
                    <ul>
                        <li>Obligaciones contractuales con los representantes del usuario</li>
                    </ul>
                    <h2>12. RANKINGS</h2>
                    <ul>
                        <li><strong>Ranking por equipos:</strong> Listado de equipos ordenados seg&uacute;n el nivel de salarios acumulados,
                          percibidos durante todas las temporadas de la vida deportiva. Indica cuales son los equipos en los que se han
                          percibido mayores retribuciones acumuladas brutas y netas. Si no se conoce exactamente la cantidad percibida, se
                          calcular&aacute; seg&uacute;n el contrato y/o las indicaciones del usuario.
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Ranking por temporadas:</strong> Listado de temporadas ordenadas seg&uacute;n el nivel de salarios
                          percibidos en cada una de ellas. Indica cu&aacute;les son las temporadas en las que se han percibido mayores
                          retribuciones butas y netas. Si no se conoce exactamente la cantidad percibida, se calcular&aacute; seg&uacute;n el
                          contrato y/o las indicaciones del usuario
                        </li>
                    </ul>
                    <h2>13. EVOLUCIONES</h2>
                    <ul>
                        <li><strong>Evoluci&oacute;n de ingresos:</strong> Bien sea en formato tabla, bien sea en formato gr&aacute;fica, se
                          indica la evoluci&oacute;n de los ingresos contractuales percibidos cada una de las temporadas desde que se tengan
                          datos. Se trata de los ingresos brutos y netos exclusivamente provenientes de los contratos con clubes deportivos,
                          excluyendo otro tipo de ingresos como arrendamientos o ingresos financieros
                        </li>
                        <li><strong>Evoluci&oacute;n de patrimonio:</strong> Bien sea en formato tabla, bien sea en formato gr&aacute;fica, se
                          indica la evoluci&oacute;n del patrimonio del usuario desde que se tengan datos. Se puede ver la evoluci&oacute;n
                          del valor del patrimonio total y de cada uno de los elementos que lo componen (Patrimonio bancario, inmobiliario,
                          sociedades, pr&eacute;stamos y otros). Se fijar&aacute; la evoluci&oacute;n a 31 de diciembre de cada ejercicio
                          pudiendo establecer fechas intermedias seg&uacute;n inter&eacute;s del usuario.
                        </li>
                    </ul>
                    <h2>14. TEMPORIZADOR</h2>
                    <ul>
                        <li>
                          D&iacute;as restantes para:
                          <ul>
                              <li><strong>Siguiente n&oacute;mina:</strong> D&iacute;as que restan para el devengo de la siguiente
                                n&oacute;mina.
                              </li>
                              <li><strong>Siguiente cobro de la ficha:</strong> D&iacute;as que restan para el devengo de la siguiente ficha.
                              </li>
                              <li><strong>Fin de temporada:</strong> D&iacute;as que restan para el fin de la temporada actual.</li>
                              <li><strong>Fin de contrato:</strong> D&iacute;as que restan para el fin del contrato actual.</li>
                              <li><strong>Fin de carrera profesional:</strong> D&iacute;as que restan para el fin de la carrera profesional
                                seg&uacute;n estimaci&oacute;n realizada con el usuario.
                              </li>
                              <li><strong>Fin del contrato con el representante:</strong> D&iacute;as que restan para el fin del contrato con el
                                representante si estuviera prevista.
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                          D&iacute;as transcurridos desde:
                          <ul>
                              <li><strong>Comienzo Temporada:</strong> D&iacute;as transcurridos desde el comienzo de la temporada actual.</li>
                              <li><strong>Comienzo contrato club:</strong> D&iacute;as transcurridos desde el comienzo del actual contrato.</li>
                              <li><strong>Comienzo carrera profesional:</strong> D&iacute;as transcurridos desde el comienzo de la carrera
                                profesional.
                              </li>
                          </ul>
                        </li>
                    </ul>
                    <h2>15. CRONOLOG&Iacute;A</h2>
                    <ul>
                        <li>Hechos relevantes ocurridos durante la carrera profesional del usuario que tengan especial trascendencia o que
                          tengan un componente patrimonial.
                        </li>
                    </ul>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </LayoutNav>
  );
};

export default Ayuda;
