import { useContext } from 'react';
import context from './context';

const useProvidedConfiguration = () => {
  const configurationContext = useContext(context);

  if (!configurationContext) {
    throw new Error('configuration context its mandatory');
  }
  return configurationContext;
};

export default useProvidedConfiguration;
