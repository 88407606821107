import React from 'react';
import { useParams } from 'react-router-dom';
import { useBulldogAPIByPk } from '../../../../hooks';
import Link from '../../../Link';
import LoadingSpinner from '../../../LoadingSpinner';
import SociedadesDetallesDatosSociedad from '../DatosSociedad';

const SociedadesDetallesOtrasSociedadesDetalles = () => {
  const { sociedadId, ...rest } = useParams();
  const [
    {
      isLoading,
      isFetching,
      data: {
        data: sociedad = {},
      } = {},
    },
  ] = useBulldogAPIByPk('otras-sociedades', sociedadId);
  const loading = isLoading || isFetching;

  return (
    <>
      <nav className="nav-main">
        <div className="nav-main-item">
          {!loading && <h1>{sociedad.name}</h1>}
        </div>
        <div className="nav-main-item">
          <Link
            toId="detalles-sociedad"
            className="button is-circle"
            params={{
              ...rest,
              section: 'otras-sociedades',
              sociedad: sociedad.sociedad,
            }}
          >
            <span className="material-icons">close</span>
          </Link>
        </div>
      </nav>
      <main className="main">
        <div className="container is-fluid">
          {loading
            ? <LoadingSpinner />
            : (
            <SociedadesDetallesDatosSociedad
              endPoint="otras-sociedades"
              qs={{ id: sociedadId }}
            />
            )}
        </div>
      </main>
    </>
  );
};

export default SociedadesDetallesOtrasSociedadesDetalles;
