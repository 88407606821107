import Debug from 'debug';

const debug = Debug('bulldog:parseAPIQuery');

const parseQueryParam = (type, key, value) => {
  debug('parseQueryParam %j', { type, key, value });
  switch (type) {
    case 'contains':
      return ((value && value.length) ? { [key]: `/${value}/i` } : { [key]: '' });
    case 'equal':
      return value !== null ? { [key]: value } : { [key]: '' };
    case 'text':
      return ((value && value.length) ? { [key]: `/${value}/i` } : { [key]: '' });
    case 'less':
      return { [key]: value === null ? '' : `lt|${value}` };
    case 'more':
      return { [key]: value === null ? '' : `gt|${value}` };
    case 'boolean':
      return ({ [key]: value !== null ? (value && value !== 'false') : '' });
    case 'sort':
      return { order: `${key}|${value}` };
    case 'offset':
      return { offset: value };
    case 'limit':
      return { limit: value };
    default:
      throw new Error(`invalid type ${type}`);
  }
};

/**
 * Parses API query to TB express middleware spec
 * @param {Object} query
 */
const parseAPIQuery = (query) => {
  debug('parseApiQuery', query);
  return Object.assign({}, ...Object
    .entries(query)
    .map(([key, { type, value }]) => parseQueryParam(type, key, value)));
};

export default parseAPIQuery;
