import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Debug from 'debug';
import useAPIDefault from './useAPI';
import useCache from './useCache';

const debug = Debug('bulldog:useAPICache');

/**
 * Uses API if response is not in cache
 * @param {String} key - Key to find resp in cache
 * @param {Number} expiresIn - Time to expire key
 * @param {Object} requestOpts
 * @param {function} onSuccess
 * @param {function} onError
 * @param {Hook?} useAPI - useAPI hook to use
 */
// eslint-disable-next-line max-len
const useAPICache = (key, expiresIn, requestOpts, onSuccess, onError, useAPI = useAPIDefault) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isLoadingCache, setIsLoadingCache] = useState(true);
  const [data, saveData] = useCache(
    key,
    expiresIn,
    useCallback(() => {
      debug('did not find cache content');
      if (!requestOpts.waitForRefreshCache) {
        setShouldFetch(true);
      }
    }, [requestOpts.waitForRefreshCache]),
    useCallback(() => setIsLoadingCache(false), []),
  );

  const handleSuccess = useCallback((newData) => {
    setIsLoadingCache(false);
    saveData(newData);
    if (onSuccess) {
      onSuccess(newData);
    }
  }, [onSuccess, saveData]);

  const [{ error, isLoading: isLoadingAPI }, doFetch] = useAPI(
    { ...requestOpts, wait: true },
    handleSuccess,
    onError,
  );

  useEffect(() => {
    debug('check should fetch cache');
    if (shouldFetch) {
      debug('fetch cache');
      doFetch({});
    }
  }, [doFetch, shouldFetch]);

  const resp = useMemo(
    () => ({ data, error, isLoading: isLoadingAPI || isLoadingCache }),
    [data, error, isLoadingAPI, isLoadingCache],
  );

  return [resp, doFetch];
};

export default useAPICache;
