import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import KnowMore from '../KnowMore';
import Bancos from './index';

const cfg = {
  extra: ['seguros', 'avales'],
};

const BancosWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage('dash.entidades-financieras.header')}>
      <Bancos />
      <div className="box box-white mt-1">
        <header className="header-box">
          <h2>{translations.formatMessage('dash.entidades-financieras.otros')}</h2>
        </header>
        <div className="body-box">
          <table className="table is-fullwidth">
            <tbody>
              {cfg.extra.map((element) => (
                <tr key={element}>
                  <td>{translations.formatMessage(`dash.entidades-financieras.otros-productos._${element}`)}</td>
                  <td className="has-text-right">
                    <KnowMore
                      label={translations.formatMessage('dash.know-more.detalles')}
                      toId={element}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutNav>
  );
};

export default BancosWithLayout;
