/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react';
import Debug from 'debug';

const debug = Debug('bulldog:useCache');

const defaultFnCache = {
  read: (key) => {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  write: (key, value) => {
    try {
      if (!value) {
        localStorage.removeItem(key);
        return;
      }
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      localStorage.setItem(key, value);
    }
  },
};

/**
 * Sotres a value in cache
 * @param {!String} key _ Value key
 * @param {!Number} expiresIn - Seconds to expire value (null to never)
 * @param {function|Object|String} onMiss - Function to call when
 * should cb with new value / OR New value if nothing in cache
 * @param {Function} onHit - Function to call when value has been found
 * @param {?Object} fnCache - Functions to handle cache
 * @param {Function} fnCache.read -
 * @param {Function} fnCache.write -
 * @param {Any} defaultValue - Default value if there are nothing in cache
 * @returns {[cache,setCache,refreshCache]}
 */
function useCache(key, expiresIn, onMiss, onHit, fnCache = defaultFnCache) {
  const { read, write } = fnCache;
  const [value, setValue] = useState();
  const [reloadCacheCount, setRequestCacheCount] = useState(0);
  const reloadCache = useCallback(() => {
    setRequestCacheCount((prev = 0) => prev + 1);
  }, []);

  const saveValue = useCallback((newValue) => {
    if (!newValue) {
      write(key, null);
      setValue();
      return;
    }
    write(key, { data: newValue, createdAt: Math.round(Date.now() / 1000) });
    if (onHit) {
      onHit(newValue);
    }
    setValue(newValue);
  }, [key, write, onHit]);

  useEffect(() => {
    const now = Math.round(Date.now() / 1000);
    const newValue = read(key);
    if (newValue && (!expiresIn || (newValue.createdAt + expiresIn) >= now)) {
      debug('hit', key, newValue);
      if (onHit) {
        onHit(newValue.data);
      }
      setValue(newValue.data);
      return;
    }

    debug('miss', key);
    let isCanceled = false;
    if (!onMiss) {
      return;
    }
    if (typeof onMiss !== 'function') {
      saveValue(onMiss);
      return;
    }
    onMiss((newData) => {
      if (isCanceled || !newData) {
        return;
      }
      debug('saveValue', newData);
      saveValue(newData);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
    };
  }, [read, expiresIn, onMiss, saveValue, key, onHit, reloadCacheCount]);
  return [value, saveValue, reloadCache];
}

export default useCache;
