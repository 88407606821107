import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
  useUpdatedDate,
} from '../../hooks';
import BoxHeroActivoPasivo from '../Box/HeroActivoPasivo';
import LoadingSpinner from '../LoadingSpinner';
import KnowMore from '../KnowMore';
import formatDateFullYear from '../../lib/formatDateFullYear';
import { numberFormat } from '../../config';

const dateSRC = (data) => {
  const date = data?.adquisicion?.date;
  return date
    ? formatDateFullYear(date * 1000)
    : null;
};

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  columns: [
    {
      id: 'coches',
      filter: ({ type }) => type === 'coches',
      resources: [
        { id: 'descripcion', className: 'sticky-col first-col' },
        { id: 'potencia' },
        { id: 'adquisicion', src: dateSRC },
        { id: 'compra', src: (data) => data?.adquisicion?.value?.value, className: 'has-text-right' },
        {
          id: 'comentario',
          src: (data) => {
            if (!data?.comentario) {
              return '-';
            }

            return (
              <KnowMore
                modal={{
                  title: data?.descripcion,
                  body: data?.comentario,
                }}
              />
            );
          },
        },
      ],
    },
    {
      id: 'otros',
      filter: ({ type }) => !['coches', 'pasivos'].includes(type),
      resources: [
        { id: 'type', src: ({ type: string = '' }) => string.charAt(0).toUpperCase() + string.slice(1), className: 'sticky-col first-col' },
        { id: 'descripcion' },
        { id: 'adquisicion', src: dateSRC },
        { id: 'compra', src: (data) => data?.adquisicion?.value?.value, className: 'has-text-right' },
        {
          id: 'comentario',
          src: (data) => {
            if (!data?.comentario) {
              return '-';
            }

            return (
              <KnowMore
                modal={{
                  title: data?.descripcion,
                  body: data?.comentario,
                }}
              />
            );
          },
        },
      ],
    },
    {
      id: 'pasivos',
      filter: ({ type }) => type === 'pasivos',
      resources: [
        { id: 'descripcion', className: 'sticky-col first-col' },
        { id: 'adquisicion', src: dateSRC },
        { id: 'valor-otros-pasivos', src: (data) => data?.pasivos?.value?.value, className: 'has-text-right' },
        {
          id: 'comentario',
          src: (data) => {
            if (!data?.comentario) {
              return '-';
            }

            return (
              <KnowMore
                modal={{
                  title: data?.descripcion,
                  body: data?.comentario,
                }}
              />
            );
          },
        },
      ],
      src: 'pasivos',
    },
  ],
};

const reducer = (data = [], src = 'adquisicion') => data.reduce((accumulator, element) => {
  const currentValue = element?.[src]?.value?.raw || 0;
  return accumulator + currentValue;
}, 0);

const OtrosPatrimonios = (props) => {
  const { qs } = props;
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isFetching: isLoadingData,
    isLoading: isFetchingData,
  }] = useBulldogAPI('otros-patrimonios', { tmp: undefined, ...qs });
  const [{
    data: { data: totals = {} } = {},
    isLoading: isLoadingTotals,
    isFetching: isFetchingTotals,
  }] = useBulldogAPI('otros-patrimonios', { tmp: undefined, ...qs }, 'totals');
  const date = useUpdatedDate('otrosPatrimonio');
  const loading = isLoadingData || isFetchingData;
  const tableBodyMinlength = useMemo(() => (
    Math.max(
      ...cfg.columns.map(({ filter }) => (
        data.filter(filter).length
      )),
    )
  ), [data.length]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <BoxHeroActivoPasivo
        totals={totals}
        isLoading={isLoadingTotals || isFetchingTotals}
      />
      <div className="columns is-multiline">
        {cfg.columns.map(({
          filter, id, resources, src,
        }) => (
          <div key={id} className="column is-6-desktop is-12-tablet">
            <div className="box box-white">
              <header className="header-box ">
                <h2>{translations.formatMessage(`dash.otros-patrimonios.table._${id}.header`, { date })}</h2>
              </header>
              <div className="body-box">
                <div className="table-container">
                  <table className="table is-fullwidth is-striped">
                    <thead>
                      <tr>
                        {resources.map((resource) => (
                          <th key={resource.id} className={resource.className || ''}>
                            {translations.formatMessage(`dash.otros-patrimonios._${id}._${resource.id}`)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.filter(filter).map((element) => (
                        <tr key={element.id}>
                          {resources.map(({
                            className,
                            id: resourceId,
                            // eslint-disable-next-line no-shadow
                            src = (payload) => payload?.[resourceId],
                          }) => (
                            <td key={resourceId} className={className || ''}>
                              {src(element, translations)}
                            </td>
                          ))}
                        </tr>
                      ))}
                      {[...Array(
                        (tableBodyMinlength - data.filter(filter).length),
                      ).keys()].map((k) => (
                        <tr key={k}>
                          <td colSpan={resources.length}>&nbsp;</td>
                        </tr>
                      ))}
                      <tr className="tr-total">
                        <td className="sticky-col first-col">
                          {translations.formatMessage('dash.document-list.table.total')}
                        </td>
                        {[...Array(resources.length - 3).keys()].map((i) => (
                          <td key={i}>&nbsp;</td>
                        ))}
                        <td className="has-text-right">
                          {`${translations.formatNumber(reducer(data.filter(filter), src), numberFormat)}€`}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

OtrosPatrimonios.defaultProps = defaultProps;
OtrosPatrimonios.propTypes = propTypes;

export default OtrosPatrimonios;
