import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';

const valuePropTypes = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const propTypes = {
  background: PropTypes.string,
  bottom: valuePropTypes.isRequired,
  left: valuePropTypes.isRequired,
  right: valuePropTypes.isRequired,
  title: PropTypes.string,
};
const defaultProps = {
  background: '',
  title: undefined,
};

const BoxOverview = (props) => {
  const {
    background, bottom, left, right, title,
  } = props;
  const translations = useProvidedTranslations();

  return (
    <div className="column is-6-desktop is-6-tablet">
      <div className="box">
        {title && (
          <header className="header-box">
            <h2>{title}</h2>
          </header>
        )}
        <div className={`body-box bg-cover ${background}`}>
          <div className="columns is-multiline">
            <div className="column is-6-desktop is-12-tablet">
              <div className="ticket is-success">
                <p className="qty">{left.value}</p>
                <p className="qty-title">{left.label || translations.formatMessage('dash.box.overview.left')}</p>
              </div>
            </div>
            <div className="column is-6-desktop is-12-tablet">
              <div className="ticket is-danger">
                <p className="qty">{right.value}</p>
                <p className="qty-title">{right.label || translations.formatMessage('dash.box.overview.right')}</p>
              </div>
            </div>
          </div>
          <div className="has-text-centered">
            <p className="qty big">{bottom.value}</p>
            <p className="qty-title">{bottom.label || translations.formatMessage('dash.box.overview.bottom')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

BoxOverview.propTypes = propTypes;
BoxOverview.defaultProps = defaultProps;

export default BoxOverview;
