import React, { Fragment } from 'react';
import {
  useProvidedTranslations,
} from '../context';
import {
  useBulldogAPI,
} from '../hooks';
import LoadingSpinner from './LoadingSpinner';
import LayoutNav from './LayoutNav';

const cfg = {
  i18nKey: 'dash.mis-datos',
  resources: {
    personal: [
      { id: 'name', src: (data) => `${data?.apellidoI} ${data?.apellidoII}, ${data?.nombre}` },
      { id: 'estado-civil', src: (data) => data?.estado },
      { id: 'conyuge', src: (data) => data?.conyuge },
      { id: 'regimen-economico', src: (data) => data?.regimenEconomico },
      { id: 'debut', src: (data) => data?.debut },
      {
        id: 'web',
        src: (data) => (
          data?.web
            ? (
              <span dangerouslySetInnerHTML={{
                __html: `<a href="${data?.web}" target="_blank">${data?.web}</a>`,
              }}
              />
            ) : ''),
      },
    ],
    domicilios: [
      { id: 'habitual', src: (data) => data?.domicilio?.habitual },
      { id: 'fiscal', src: (data) => data?.domicilio?.fiscal },
      { id: 'dgt', src: (data) => data?.domicilio?.dgt },
      { id: 'padron', src: (data) => data?.domicilio?.padron },
    ],
  },
};


const MisDatos = () => {
  const translations = useProvidedTranslations();
  const [{
    data: { data: [data] = [] } = {},
    isFetching,
    isLoading,
  }] = useBulldogAPI('personales');
  const loading = isLoading || isFetching;

  return (
    <LayoutNav title={translations.formatMessage(`${cfg.i18nKey}.header`)}>
      <div className="box box-white mt-2">
        {loading
          ? <LoadingSpinner />
          : (
            <div className="body-box b-0">
              {Object.keys(cfg.resources).map((element) => (
                <Fragment key={element}>
                  <h3 className="h3-header">{translations.formatMessage(`${cfg.i18nKey}.detalles._${element}`)}</h3>
                  <table className="table is-striped is-fullwidth mt-1">
                    <tbody>
                      {cfg.resources[element]
                        .map(({ className, id, src }, i) => (
                          <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                            <td className={className}>{translations.formatMessage(`${cfg.i18nKey}.list._${id}`)}</td>
                            <td className={`has-text-right ${className}`}>{src(data, translations)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Fragment>
              ))}
            </div>
          )}
        </div>
    </LayoutNav>
  );
};

export default MisDatos;
