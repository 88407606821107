import React from 'react';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const sort = ({
  adquision: adquisionA,
}, {
  adquision: adquisionB,
}) => (adquisionA - adquisionB);

const params = {
  defaults: [
    'municipio',
    'direccion',
    'ingresos-anuales-estimados',
    'gastos-comunidad-anual',
    'ibi',
    'seguro',
    'cuota-prestamo',
    'rendimiento',
    'rentabilidad',
    'comentario',
  ],
  endPoint: 'inmuebles',
  i18nKey: 'dash.inmuebles',
  resources: [
    {
      id: 'municipio',
      className: 'sticky-col first-col',
      src: (data) => data?.municipio,
      disabled: true,
    },
    {
      id: 'direccion',
      className: 'has-text-left',
      src: (data) => data?.direccion,
    },
    {
      id: 'ingresos-anuales-estimados',
      src: (data, w = 'value') => data?.alquiler?.estimado?.[w],
      num: true,
    },
    {
      id: 'gastos-comunidad-anual',
      src: (data, w = 'value') => data?.gastosComunidad?.[w],
      num: true,
    },
    {
      id: 'ibi',
      src: (data, w = 'value') => data?.ibi?.[w],
      num: true,
    },
    {
      id: 'seguro',
      src: (data, w = 'value') => data?.seguro?.[w],
      num: true,
    },
    {
      id: 'cuota-prestamo',
      src: (data, w = 'value') => data?.prestamo?.[w],
      num: true,
    },
    {
      id: 'rendimiento',
      src: (data, w = 'value') => data?.rendimento?.[w],
      num: true,
    },
    {
      id: 'rentabilidad',
      src: (data) => data?.rentabilidad,
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentario?.alquiler) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: `${data?.direccion}, ${data?.municipio}`,
              body: data?.comentario?.alquiler,
            }}
          />
        );
      },
    },
  ],
  sort,
  qs: { tmp: undefined },
  Header: () => null,
};

const InmueblesIngresosGastos = (props) => {
  const { qs } = props;
  return (
    <DocumentList
      {...params}
      {...props}
      qs={{ ...(params.qs ? params.qs : {}), ...qs }}
    />
  );
};

InmueblesIngresosGastos.propTypes = propTypes;
InmueblesIngresosGastos.defaultProps = defaultProps;

export default InmueblesIngresosGastos;
