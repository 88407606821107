import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../Tabs';
import {
  useBulldogAPI,
} from '../../../../hooks';
import Detalles from './Detalles';
import SociosAdministradores from './SociosAdministradores';

const i18nKey = 'dash.sociedades.datos';

const propTypes = {
  endPoint: PropTypes.string,
  qs: PropTypes.shape(),
};

const defaultProps = {
  endPoint: 'datos-sociedad',
  qs: {},
};

const params = {
  i18nKey,
  tabs: [
    {
      id: 'datos-sociedad',
      Component: (props) => (
        <Detalles {...props} />
      ),
      isDefault: true,
      title: (translations) => translations.formatMessage(`${i18nKey}.tabs._datos-sociedad`),
    },
    {
      id: 'socios-administradores',
      Component: (props) => (
        <SociosAdministradores {...props} />
      ),
      isDefault: false,
      title: (translations) => translations.formatMessage(`${i18nKey}.tabs._socios-administradores`),
    },
  ],
};

const SociedadesDetallesDatosSociedad = (props) => {
  const { endPoint, qs } = props;
  const [{
    data: { data: [data = {}] = [] } = {},
    isFetching,
    isLoading,
  }] = useBulldogAPI(endPoint, { tmp: undefined, ...qs });

  return <Tabs isLoading={isLoading || isFetching} data={data} {...params} />;
};

SociedadesDetallesDatosSociedad.defaultProps = defaultProps;
SociedadesDetallesDatosSociedad.propTypes = propTypes;

export default SociedadesDetallesDatosSociedad;
