import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../context';
import LoadingSpinner from './LoadingSpinner';

const propTypes = {
  i18nKey: PropTypes.string,
  isLoading: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      Component: PropTypes.func.isRequired,
      isDefault: PropTypes.bool,
      title: PropTypes.func,
      value: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

const defaultProps = {
  isLoading: false,
  i18nKey: 'dash.tabs',
};

const Tabs = (props) => {
  const { i18nKey, isLoading, tabs } = props;
  const translations = useProvidedTranslations();
  const defaultTab = (
    tabs.find(({ isDefault = false }) => isDefault).id
    || tabs[0].id
  );
  const [selected, setSelected] = useState(defaultTab);
  const { Component, title, ...params } = (
    tabs.find(({ id }) => id === selected)
  );

  return (
    <>
      <div className="tabs is-centered mt-1">
        <ul>
          {tabs.map((tab) => (
            <li key={tab.id} className={`${tab.id === selected ? 'is-active' : ''}`}>
              <a onClick={() => setSelected(tab.id)}>
                {tab.value
                  ? tab.value(translations)
                  : translations.formatMessage(`${i18nKey}.tabs._${tab.id}`)}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="box box-white mt-2">
        {title && <header className="header-box ">
          <h2>{title(translations)}</h2>
        </header>}
          <div className="body-box b-0">
            {isLoading && <LoadingSpinner />}
            {!isLoading && <Component {...props} {...params} />}
          </div>
        </div>
    </>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
