import React from 'react';
import {
  useProvidedTranslations,
  useProvidedUserInfo,
} from '../../context';
import {
  useBulldogAPI,
} from '../../hooks';
import DocumentList from '../DocumentList';
import LayoutNav from '../LayoutNav';
import BoxHeroActivoPasivo from '../Box/HeroActivoPasivo';
import KnowMore from '../KnowMore';
import Link from '../Link';

const cfg = {
  i18nKey: 'dash.sociedades-no-cotizadas',
};

const params = {
  ...cfg,
  defaults: [
    'name',
    'participacion',
    'adquisicion',
    'valor-teorico-participacion',
    'prestamo',
    'deudas-socio',
    'detalles',
    'comentario',
  ],
  endPoint: 'sociedades-no-cotizadas',
  resources: [
    {
      id: 'name',
      className: 'sticky-col first-col',
      src: (data) => data?.name,
    },
    {
      id: 'participacion',
      src: (data) => `${data?.participacion}%`,
    },
    {
      id: 'adquisicion',
      src: (data, w = 'value') => data?.adquisicion?.[w],
      num: true,
    },
    {
      id: 'valor-teorico-participacion',
      src: (data, w = 'value') => data?.valorTeoricoParticipacion?.[w],
      num: true,
    },
    {
      id: 'prestamo',
      src: (data, w = 'value') => data?.prestamo?.[w],
      num: true,
    },
    {
      id: 'deudas-socio',
      src: (data, w = 'value') => data?.deudasSocio?.[w],
      num: true,
    },
    {
      id: 'detalles',
      src: (data, w, translations, { docId } = {}) => (
          <Link
            className="button is-small flex-end"
            params={{ docId, section: 'resumen', sociedad: data?.sociedad }}
            toId="detalles-sociedad"
          >
            {translations.formatMessage(`${cfg.i18nKey}.resource._ver-ficha`)}
          </Link>
      ),
    },
    {
      id: 'comentario',
      src: (data, w, translations) => {
        if (!data?.resumen) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: translations.formatMessage(`${cfg.i18nKey}.modal.title`, { name: data?.name }),
              body: data?.resumen,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Sociedades = () => {
  const translations = useProvidedTranslations();
  const [{
    data: { data: totals = {} } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('sociedades-no-cotizadas', { tmp: undefined }, 'totals');
  const [{
    profiles = [],
  } = {}] = useProvidedUserInfo();
  const profile = profiles.find(({ type }) => type === 'company') || {};
  const { _id: docId } = profile || {};

  return (
    <LayoutNav title={translations.formatMessage(`${params.i18nKey}.header`)}>
      <BoxHeroActivoPasivo
        totals={totals}
        isLoading={isLoading || isFetching}
      />
      <div className="box box-white">
        <DocumentList {...params} docId={docId} />
      </div>
    </LayoutNav>
  );
};

export default Sociedades;
