/* eslint-disable max-len */
import { name } from '../package.json';

// APP
export const publicURL = process.env.PUBLIC_URL || 'localhost:3000';

// APP routes base path
const pathPrefix = (process.env.REACT_APP_PATH_PREFIX || '').replace(/^\/|\/$/g, '');
const parts = publicURL.split('/').filter((el) => !!el);
if (parts[0].startsWith('http')) {
  parts.splice(0, 2);
}
if (pathPrefix.length) {
  parts.push(pathPrefix);
}

export const basePath = parts.length ? ['', ...parts].join('/') : '';

// URLs
export const apiBaseURL = process.env
  .REACT_APP_API_BASE_URL || 'https://hx2refddfi.execute-api.eu-west-3.amazonaws.com';
export const configurationURL = process.env
  .REACT_APP_CONFIGURATION_URL || 'https://bulldog-private.s3.eu-west-3.amazonaws.com/config/index.json';
export const translationsBaseURL = process.env
  .REACT_APP_TRANSLATIONS_BASE_URL || 'https://bulldog-private.s3.eu-west-3.amazonaws.com/translations/';

// Analytics
export const gaEnabled = true;
export const gaId = process.env.REACT_APP_GA_ID || 'UA-197671921-1';

// auth0
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || 'pasf.eu.auth0.com';
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'zybGfqCKRUcBw0AS5HJZhf1rlZwqk1nL';
export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api-pasf-es';


// eslint-disable-next-line no-undef
export const auth0Redirect = process.env.REACT_APP_AUTH0_REDIRECT || window.location.origin;

// static content
export const staticContent = process.env.REACT_APP_STATIC_CONTENT || 'https://bulldog-public.s3.eu-west-3.amazonaws.com';

// date format: https://www.w3schools.com/jsref/jsref_tolocalestring.asp
export const toLocaleStringOptions = {
  basic: { year: '2-digit', month: '2-digit', day: '2-digit' },
  fullYear: { year: 'numeric', month: '2-digit', day: '2-digit' },
};

// https://tc39.es/ecma402/#numberformat-objects
export const numberFormat = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

// charts default colors
export const chartColors = ['#194891', '#75C043', '#525F29', '#FFC43D', '#CFDE5A', '#F15E2A', '#D4303B', '#C5299B', '#5F4E7D', '#0C76BA', '#36C6F4', '#6DCAF2', '#618299'];

export const contactUs = 'javier.sanchezostiz@gmail.com';

// auto
export const isAutoEnabled = process.env.REACT_APP_IS_AUTO_ENABLED || true;
export const autoStorageName = process.env.REACT_APP_AUTO_STORAGE_NAME || `${name}-auto`;
