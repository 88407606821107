import React from 'react';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import formatDateFullYear from '../../lib/formatDateFullYear';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  i18nKey: 'dash.seguros',
};

const params = {
  ...cfg,
  defaults: [
    'beneficario',
    'company',
    'cobertura',
    'indemnizacion',
    'prima',
    'pago',
    'comentarios',
  ],
  endPoint: 'seguros',
  resources: [
    {
      id: 'company',
      src: (data) => data?.company,
      className: 'sticky-col first-col',
      disabled: true,
    },
    {
      id: 'beneficario',
      src: (data) => data?.beneficario,
      className: 'has-text-left',
    },
    {
      id: 'cobertura',
      src: (data) => data?.cobertura,
    },
    {
      id: 'indemnizacion',
      src: (data, w = 'value') => data?.indemnizacion?.[w],
      num: true,
    },
    {
      id: 'prima',
      src: (data, w = 'value') => data?.prima?.[w],
      num: true,
    },
    {
      id: 'pago',
      src: (data) => {
        const date = data?.pago;
        return date
          ? formatDateFullYear(date * 1000)
          : null;
      },
    },
    {
      id: 'comentarios',
      src: (data) => {
        if (!data?.comentarios) {
          return '-';
        }

        return (
          <KnowMore
            modal={{
              title: data?.company,
              body: data?.comentarios,
            }}
            />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Seguros = (props) => {
  const { qs } = props;

  return (
    <div className="box box-white">
      <DocumentList
        {...params}
        qs={{ ...(params.qs ? params.qs : {}), ...qs }}
      />
    </div>
  );
};

Seguros.defaultProps = defaultProps;
Seguros.propTypes = propTypes;

export default Seguros;
