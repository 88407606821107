import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  useProvidedTranslations,
} from '../../../context';
import formatDateFullYear from '../../../lib/formatDateFullYear';

const propTypes = {
  inmueble: PropTypes.shape({
    id: PropTypes.string.isRequired,
    alquiler: PropTypes.shape({
      inquilino: PropTypes.string,
      inicio: PropTypes.number,
      fin: PropTypes.number,
      renta: PropTypes.shape({
        value: PropTypes.string,
      }),
      fianza: PropTypes.shape({
        value: PropTypes.string,
      }),
    }).isRequired,
    comentario: PropTypes.shape({
      alquiler: PropTypes.string,
    }),
  }).isRequired,
};

const defaultProps = {
  comentario: '',
};

const cfg = [
  { id: 'inquilino', src: (inmueble) => inmueble?.alquiler?.inquilino },
  { id: 'fecha-inicio-alquiler', src: (inmueble) => formatDateFullYear(inmueble?.alquiler?.inicio * 1000) },
  { id: 'fecha-fin-alquiler', src: (inmueble) => formatDateFullYear(inmueble?.alquiler?.fin * 1000) },
  { id: 'renta-mensual', src: (inmueble) => inmueble?.alquiler?.renta?.value },
  { id: 'iva', src: (inmueble) => inmueble?.alquiler?.iva?.value },
  { id: 'irpf', src: (inmueble) => inmueble?.alquiler?.irpf?.value },
  { id: 'fianza-recibida', src: (inmueble) => inmueble?.alquiler?.fianza?.value },
];

const InmueblesDetallesAlquiler = (props) => {
  const { inmueble } = props;
  const translations = useProvidedTranslations();

  return (
    <>
      <header className="header-box ">
        <h2>{translations.formatMessage('dash.inmueble.alquiler.title')}</h2>
      </header>
      <div className="body-box b-0">
        <table className="table is-striped is-fullwidth mt-1">
          <tbody>
            {cfg.map(({ id, src }, i) => (
              <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                <td>{translations.formatMessage(`dash.inmueble.list._${id}`)}</td>
                <td className="has-text-right">{src(inmueble, translations)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {inmueble?.comentario?.alquiler && (
          <ReactMarkdown className="has-text-dark mt-1">
            {inmueble?.comentario?.alquiler}
          </ReactMarkdown>
        )}
      </div>
    </>
  );
};

InmueblesDetallesAlquiler.propTypes = propTypes;
InmueblesDetallesAlquiler.defaultProps = defaultProps;

export default InmueblesDetallesAlquiler;
