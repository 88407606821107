/* eslint-disable no-undef */
import { useEffect, useState } from 'react';

const useActiveWindowInterval = (cb, timeout = 60 * 5, isWait = false) => {
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    const fn = () => {
      setActive(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', fn);
    return () => {
      document.removeEventListener('visibilitychange', fn);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isActive && !interval && !isWait) {
      interval = setInterval(() => cb(), timeout * 1000);
    } else if (!isActive || isWait) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, cb, timeout, isWait]);
};

export default useActiveWindowInterval;
