import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPIByPk,
} from '../../hooks';
import LoadingSpinner from '../LoadingSpinner';
import Link from '../Link';
import formatDateFullYear from '../../lib/formatDateFullYear';

const cfg = {
  contacto: [
    { id: 'oficina', src: (banco) => banco?.oficina },
    { id: 'contacto', src: (banco) => banco?.personaContacto },
    { id: 'direccion', src: (banco) => banco?.direccionPostalPropia },
    { id: 'fecha', src: (banco) => formatDateFullYear(banco?.date * 1000) },
    { id: 'email', src: (banco) => banco?.emailContacto },
    { id: 'phone', src: (banco) => banco?.telefonoContacto },
  ],
  activos: [
    { id: 'activos-pensiones', src: (banco) => banco?.activos?.pensiones?.value },
    { id: 'activos-corrientes', src: (banco) => banco?.activos?.corrientes?.value },
    { id: 'activos-etfs', src: (banco) => banco?.activos?.etfs?.value },
    { id: 'activos-valores', src: (banco) => banco?.activos?.valores?.value },
    { id: 'activos-fondos', src: (banco) => banco?.activos?.fondos?.value },
    { id: 'activos-otros', src: (banco) => banco?.activos?.otros?.value },
    { id: 'activos-total', src: (banco) => banco?.activos?.total?.value, className: 'has-text-weight-bold' },
  ],
  pasivos: [
    { id: 'pasivos-total', src: (banco) => banco?.pasivos?.value, className: 'has-text-weight-bold' },
  ],
};


const BancosDetalles = () => {
  const { id: pk, sociedad } = useParams();
  const translations = useProvidedTranslations();
  const [{
    isLoading,
    isFetching,
    data: {
      data: banco = {},
    } = {},
  }] = useBulldogAPIByPk('bancos', pk);
  const loading = (
    isLoading
    || isFetching
    || !Object.keys(banco).length
  );

  return (
    <>
      <nav className="nav-main">
          <div className="nav-main-item">
            <h1>{banco?.banco}</h1>
          </div>
          <div className="nav-main-item">
              <Link
                toId={sociedad ? 'detalles-sociedad' : 'bancos'}
                className="button is-circle"
                params={{
                  section: 'bancos',
                  sociedad,
                }}
                >
                <span className="material-icons">
                  close
                </span>
              </Link>
          </div>
      </nav>
      <main className="main">
        <div className="container is-fluid">
          <div className="box box-white mt-2">
            {loading
              ? <LoadingSpinner />
              : (
                <div className="body-box b-0">
                  {Object.keys(cfg).map((element) => (
                    <Fragment key={element}>
                      <h3 className="h3-header">{translations.formatMessage(`dash.entidades-financieras.detalles._${element}`)}</h3>
                      <table className="table is-striped is-fullwidth mt-1">
                        <tbody>
                          {cfg[element].map(({ className, id, src }, i) => (
                            <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                              <td className={className}>{translations.formatMessage(`dash.inmueble.list._${id}`)}</td>
                              <td className={`has-text-right ${className}`}>{src(banco, translations)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Fragment>
                  ))}
                  {banco?.comentarios && (
                    <ReactMarkdown className="has-text-dark mt-1">
                      {banco?.comentarios}
                    </ReactMarkdown>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </main>
  </>
  );
};

export default BancosDetalles;
