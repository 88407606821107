import parseTimeAmount from './parseTimeAmount';

const defaultTimeUnits = {
  now: {
    en: 'now',
    es: 'ahora',
  },
};

const parseElapsed = (date, locale = 'en', timeUnits) => {
  const elapsedTime = Math.round(Date.now() / 1000 - date);
  const elapsed = parseTimeAmount(elapsedTime, locale, timeUnits);
  const now = (timeUnits && timeUnits.now
    ? timeUnits.now[locale]
    : defaultTimeUnits.now[locale]);
  if (locale === 'en') {
    return elapsed === now ? now : `${elapsed} ago`;
  }

  return elapsed === now ? now : `hace ${elapsed}`;
};

export default parseElapsed;
