import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../../context';
import formatDateFullYear from '../../../../lib/formatDateFullYear';

const propTypes = {
  data: PropTypes.shape().isRequired,
  i18nKey: PropTypes.string.isRequired,
};

const SociedadesDetallesDatosSociedadDetalles = (props) => {
  const { data, i18nKey } = props;
  const translations = useProvidedTranslations();

  return (
    <>
      <p className="has-text-dark mt-1">
        <b>{data.name}</b><br />
        {data.domicilio}<br />
        CIF: {data.cif}
      </p>
      <table className="table is-striped is-fullwidth mt-1">

        <tbody>
          <tr className="tableizer-firstrow">
            <td>
              {translations.formatMessage(`${i18nKey}.detalles.fecha-incorporacion`)}
            </td>
            <td className="has-text-right">
              {formatDateFullYear(data.fechaIncorporacion * 1000)}
            </td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.porcentaje-capital`)}</td>
            <td className="has-text-right">{data.participacion}%</td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.cantidad.invertida`)}</td>
            <td className="has-text-right">{data.cantidadInvertida?.value}</td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.cantidad.prima-emision`)}</td>
            <td className="has-text-right">{data.cantidadPrimasEmision?.value}</td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.valor-teorico`)}</td>
            <td className="has-text-right">{data.valorTeoricoUltimoBalance?.value}</td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.prestamos`)}</td>
            <td className="has-text-right">{data.prestamo?.value}</td>
          </tr>
          <tr>
            <td>{translations.formatMessage(`${i18nKey}.detalles.desembolso-pendiente`)}</td>
            <td className="has-text-right">{data.pendiente?.value}</td>
          </tr>
          </tbody>
      </table>
    </>
  );
};

SociedadesDetallesDatosSociedadDetalles.propTypes = propTypes;

export default SociedadesDetallesDatosSociedadDetalles;
