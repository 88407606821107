import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  staticContent,
  apiBaseURL,
} from '../config';
import {
  useProvidedTranslations,
} from '../context';
import {
  useAPI,
} from '../hooks';
import AyudaModalContact from './Ayuda/ModalContact';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const translations = useProvidedTranslations();
  useAPI({
    url: `${apiBaseURL}/wake-up`,
    method: 'GET',
  });
  const [displayAyuda, setDisplayAyuda] = useState(false);

  return (
    <div className="login bg-login bg-cover is-100vh is-relative">
      {displayAyuda && (
        <AyudaModalContact
          hide={() => setDisplayAyuda(false)}
        />
      )}
      <div className="content">
        <div className="image logo mb-4">
          <img alt="PKF Attest" className="logo" src={`${staticContent}/images/pkf-attest.svg`} />
        </div>
        <div className="control has-text-centered">
          <button
            className="button is-large is-rounded is-primary is-login is-fullwidth"
            type="button"
            onClick={() => loginWithRedirect()}
          >
            {translations.formatMessage('dash.login.inciar')}
          </button>
        </div>
      </div>

      <div className="need-help">
        <a className="button is-white is-rounded" onClick={() => setDisplayAyuda(true)}>
          <span className="material-icons mr-1">
            help_outline
          </span>
          {translations.formatMessage('dash.login.help')}
        </a>
      </div>
    </div>
  );
};

export default Login;
