import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../context';

const propTypes = {
  className: PropTypes.string,
  i18nKey: PropTypes.string,
  right: PropTypes.node,
  title: PropTypes.string,
};

const defaultProps = {
  className: '',
  i18nKey: undefined,
  right: undefined,
  title: undefined,
};

const HeadersBlue = (props) => {
  const {
    className, i18nKey, right, title,
  } = props;
  const translations = useProvidedTranslations();

  return (
    <header className={`header-box ${className}`}>
      <div className="d-flex">
        <h2
          dangerouslySetInnerHTML={{
            __html: title || translations.formatMessage(`${i18nKey}.h3`),
          }}
        />
        {right}
      </div>
    </header>
  );
};

HeadersBlue.propTypes = propTypes;
HeadersBlue.defaultProps = defaultProps;

export default HeadersBlue;
