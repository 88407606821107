import React, { useState } from 'react';
import {
  useProvidedTranslations,
} from '../context';
import DocumentList from './DocumentList';
import LayoutNav from './LayoutNav';
import EscudosEquipos from './EscudosEquipos';
import DocumentListHeadersBlue from './DocumentList/Headers/Blue';
import KnowMore from './KnowMore';

const sort = (
  { order: order1 = 0 },
  { order: order2 = 0 },
) => order1 - order2;

const i18nKey = 'dash.rankings';

const resources = {
  equipo: {
    id: 'equipo',
    src: (data) => <EscudosEquipos name={data?.equipo} />,
  },
  bruto: {
    id: 'salario-bruto',
    src: (data, w = 'value') => (
      // eslint-disable-next-line no-nested-ternary
      data?.salario?.bruto
        ? data?.salario?.bruto?.[w]
        : (w === 'value' ? '0€' : 0)
    ),
    num: true,
  },
  neto: {
    id: 'salario-neto',
    src: (data, w = 'value') => (
      // eslint-disable-next-line no-nested-ternary
      data?.salario?.neto
        ? data?.salario?.neto?.[w]
        : (w === 'value' ? '0€' : 0)
    ),
    num: true,
  },
  tmp: {
    id: 'tmp',
    src: (data) => data?.tmp,
  },
  comentario: {
    id: 'comentario',
    src: (data, w, translations) => {
      if (!data?.comentario) {
        return null;
      }
      return (
          <KnowMore
            modal={{
              title: translations.formatMessage(`${i18nKey}.modal.title`),
              body: data?.comentario,
            }}
          />
      );
    },
  },
};

const params = {
  equipos: {
    defaults: [
      'equipo',
      'tmp',
      'salario-neto',
      'salario-bruto',
      'comentario',
    ],
    endPoint: 'rankings',
    i18nKey,
    resources: [
      {
        ...resources.equipo,
        className: 'sticky-col first-col',
        disabled: true,
      },
      resources.bruto,
      resources.neto,
      resources.comentario,
    ],
    sort,
    qs: { tmp: undefined, type: 'equipo' },
    Header: DocumentListHeadersBlue,
    HeaderPosition: 'outside',
  },
  temporadas: {
    defaults: [
      'tmp',
      'salario-bruto',
      'salario-neto',
      'equipo',
      'comentario',
    ],
    endPoint: 'rankings',
    i18nKey,
    resources: [
      {
        ...resources.tmp,
        className: 'sticky-col first-col',
        disabled: true,
      },
      resources.bruto,
      resources.neto,
      resources.equipo,
      resources.comentario,
    ],
    sort,
    qs: { tmp: undefined, type: 'temporada' },
    Header: DocumentListHeadersBlue,
    HeaderPosition: 'outside',
  },
};

const cfg = {
  tabs: [
    { _id: 'equipos' },
    { _id: 'temporadas' },
  ],
};

const Rankings = () => {
  const translations = useProvidedTranslations();
  const [display, setDisplay] = useState(cfg.tabs[0]._id);
  const current = cfg.tabs.find((element) => element._id === display);

  return (
    <LayoutNav title={translations.formatMessage(`${i18nKey}.header`)}>
      <div className="tabs is-centered mt-1">
        <ul>
          {cfg.tabs.map((tab) => (
            <li
              key={tab._id}
              className={`${tab._id === display ? 'is-active' : ''}`}
              >
                <a onClick={() => setDisplay(tab._id)}>
                  {translations.formatMessage(`${i18nKey}.tabs._${tab._id}`)}
                </a>
              </li>
          ))}
        </ul>
      </div>
      <div className="box box-white" key={current._id}>
        <DocumentList
          {...params[current._id]}
          title={translations.formatMessage(`${i18nKey}.table._${current._id}.title`)}
        />
      </div>
    </LayoutNav>
  );
};

export default Rankings;
