import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedConfiguration,
} from '../../context';
import {
  staticContent,
} from '../../config';

const propTypes = {
  banco: PropTypes.shape({
    id: PropTypes.string.isRequired,
    banco: PropTypes.string.isRequired,
  }).isRequired,
};

const BancosImageProfile = (props) => {
  const { banco: { banco } } = props;
  const [{ data: { bancos: pictures = {} } = {} }] = useProvidedConfiguration();
  const name = banco.toLowerCase().replace(/ /g, '-');
  const src = pictures[name] || `${name}.jpg`;

  return (
    <img
      src={`${staticContent}/images/bancos/${src}`}
      alt={name}
    />
  );
};

BancosImageProfile.propTypes = propTypes;

export default BancosImageProfile;
