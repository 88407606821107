/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingDefault from '../../components/Loading';
import { useAPICache } from '../../hooks';
import TranslationsProvider from './Provider';

const propTypes = {
  Loading: PropTypes.elementType,
  cacheExpiration: PropTypes.number,
  cachePrefix: PropTypes.string,
  children: PropTypes.node.isRequired,
  // TODO extraDictionary is not validated, format might be wrong
  extraDictionary: PropTypes.shape(),
  lang: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const defaultProps = {
  Loading: LoadingDefault,
  cacheExpiration: 60 * 60, // 1 hour
  cachePrefix: 'translations',
  extraDictionary: undefined,
  lang: 'es',
};

const TranslationsHTTPProvider = (props) => {
  const {
    Loading, cacheExpiration, cachePrefix, children, extraDictionary, lang, url,
  } = props;

  const [fetchedLang, setFetchedLang] = useState(lang);
  const [{ data: dictionary, isLoading }, doFetch] = useAPICache(`${cachePrefix}-${lang}`, cacheExpiration, {
    method: 'GET',
    wait: true,
    url: `${url}${lang}.json`,
  });

  useEffect(() => {
    if (lang && lang !== fetchedLang) {
      setFetchedLang(lang);
      doFetch({ url: `${url}${lang}.json` });
    }
  }, [doFetch, lang, url, fetchedLang]);

  const fullDictionary = useMemo(
    () => ({
      ...dictionary || {},
      ...Object.entries(extraDictionary || {})
        .map(([key, { [lang]: value = null } = {}]) => ({ [key]: value }))
        // each element will be an object with just one property
        // we need to check its value as falsy elements will make
        .filter((el) => el[Object.keys(el)[0]])
        .reduce((acc, el) => ({ ...acc, ...el }), {}),
    }),
    [dictionary, extraDictionary, lang],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <TranslationsProvider {...props} dictionary={fullDictionary} lang={lang}>
      {children}
    </TranslationsProvider>
  );
};

TranslationsHTTPProvider.propTypes = propTypes;
TranslationsHTTPProvider.defaultProps = defaultProps;

export default TranslationsHTTPProvider;
