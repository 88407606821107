import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import PropTypes from 'prop-types';
import './styles/custom.scss';
import Loading from './components/Loading';
import LoadingSpinner from './components/LoadingSpinner';
import ModalContainerStandard from './components/Modal/Container/Standard';
import Notifications from './components/Notifications';
import Routes from './components/Routes';
import {
  apiBaseURL,
  auth0Audience,
  auth0ClientId,
  auth0Domain,
  auth0Redirect,
  configurationURL,
  translationsBaseURL,
} from './config';
import {
  ProvideConfiguration,
  ProvideHTTPTranslations,
  ProvideLanguage,
  ProvideLoading,
  ProvideModal,
  ProvideNotifications,
  ProvideUserInfo,
  useProvidedLanguage,
} from './context';
import translations from './translations.json';

const ProvideDashboardTranslations = (props) => {
  const { children } = props;
  const [lang] = useProvidedLanguage();

  return (
    <ProvideHTTPTranslations
      Loading={LoadingSpinner}
      extraDictionary={translations}
      lang={lang}
      url={translationsBaseURL}
    >
      {children}
    </ProvideHTTPTranslations>
  );
};

ProvideDashboardTranslations.propTypes = {
  children: PropTypes.node.isRequired,
};

const DashboardProvideConfiguration = (props) => (
  <ProvideConfiguration
    {...props}
    url={configurationURL}
  />
);

const providers = [
  [DashboardProvideConfiguration],
  [ProvideUserInfo, { apiBaseURL }],
  [ProvideLoading],
  [ProvideNotifications],
  [ProvideLanguage, { apiBaseURL }],
  [ProvideDashboardTranslations],
  [ProvideModal],
];

const AppProviders = ({ children }) => providers
  .reduceRight((providerChildren, [Provider, props]) => (
    <Provider {...props}>
      {providerChildren}
    </Provider>
  ), children);

const App = () => (
  <Auth0Provider
    audience={auth0Audience}
    clientId={auth0ClientId}
    domain={auth0Domain}
    redirectUri={auth0Redirect}
  >
    <AppProviders>
      <ModalContainerStandard containerId="main" />
      <Loading />
      <Notifications containerId="main" />
      <Routes />
    </AppProviders>
  </Auth0Provider>
);

export default App;
