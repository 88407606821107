import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import routes from '../routes';
import {
  useProvidedUserInfo,
} from '../context';
import Loading from './Loading';
import Link from './Link';

const ProfileSelector = () => {
  const [{
    profiles = [],
  } = {}] = useProvidedUserInfo();
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const { _id } = profiles.find(({ type }) => type === 'player') || {};
    const { path } = routes.find(({ isDefault = false }) => isDefault) || {};
    if (_id && path) {
      setRedirect(path.replace(':docId', _id));
    }
  }, [profiles]);

  if (!profiles.length) {
    return <Loading />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="login-user bg-user is-relative">
      <div className="user-selector">
        {profiles.map((profile) => (
          <div className="user-item" key={profile.name}>
            <Link
              toId="resumen"
              params={{ docId: profile._id }}
              className={`button is-transparent is-user ${profile.type === 'company' ? 'is-sociedad' : ''}`}
              aria-haspopup="true"
              aria-controls="menuUser">
              <img src={profile.img} alt={profile.name} className="user" />
            </Link>
            <p>{profile.name}</p>
          </div>
        ))}
     </div>
  </div>
  );
};

export default ProfileSelector;
