import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../context';
import HeadersBlue from '../../HeaderBlue';

const propTypes = {
  i18nKey: PropTypes.string,
  isSelectorHidden: PropTypes.bool,
  setDisplay: PropTypes.func,
  title: PropTypes.string,
};

const defaultProps = {
  i18nKey: undefined,
  isSelectorHidden: true,
  setDisplay: () => null,
  title: undefined,
};

const DocumentListHeadersBlue = (props) => {
  const {
    isSelectorHidden, setDisplay,
  } = props;
  const translations = useProvidedTranslations();

  return (
    <HeadersBlue
      {...props}
      right={!isSelectorHidden
          && (
            <a className="flex-end">
              <button
                className="button"
                onClick={() => setDisplay(true)}
                type="button">
                  {translations.formatMessage('dash.document-list.cta.columnas')}
              </button>
            </a>
          )}
    />
  );
};

DocumentListHeadersBlue.propTypes = propTypes;
DocumentListHeadersBlue.defaultProps = defaultProps;

export default DocumentListHeadersBlue;
