import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
  useProvidedLanguage,
} from '../../context';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  type: PropTypes.string.isRequired,
};
const defaultProps = {};


const EvolucionesTable = (props) => {
  const { data, resources, type } = props;
  const translations = useProvidedTranslations();
  const [lang] = useProvidedLanguage();

  return (
    <div className="table-container">
      <table className="table is-fullwidth is-striped">
        <thead>
            <tr className="tableizer-firstrow">
                {resources.map(({ id }) => (
                  <th
                    key={id}
                    className={id !== 'date' ? 'has-text-right' : ''}>
                      {translations.formatMessage(`dash.evoluciones.table.th._${type}._${id}`)}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {data
              .sort((
                { order: order1 = 0 },
                { order: order2 = 0 },
              ) => order1 - order2)
              .map((row) => (
                <tr key={row.id}>
                  {resources.map(({
                    id,
                    src = (element) => element?.[type]?.[id] || {},
                  }) => (
                    <td
                      key={id}
                      className={id !== 'date' ? 'has-text-right' : ''}>
                        {src(row, lang, translations).value}
                      </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        </div>

  );
};

EvolucionesTable.propTypes = propTypes;
EvolucionesTable.defaultProps = defaultProps;

export default EvolucionesTable;
