import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
  useProvidedTranslations,
} from '../../../context';
import formatDateFullYear from '../../../lib/formatDateFullYear';
import { numberFormat } from '../../../config';

const ValueShape = PropTypes.shape({
  value: PropTypes.string,
});

const propTypes = {
  inmueble: PropTypes.shape({
    adquision: PropTypes.number,
    catastro: PropTypes.string,
    comentario: PropTypes.shape({
      general: PropTypes.string,
    }),
    destino: PropTypes.string,
    direccion: PropTypes.string,
    escrituraNotarial: PropTypes.string,
    gastosAdquisicion: ValueShape,
    id: PropTypes.string.isRequired,
    municipio: PropTypes.string,
    provincia: PropTypes.string,
    titular: PropTypes.string,
    valor: PropTypes.shape({
      adquisicion: ValueShape,
      catastro: ValueShape,
      hacienda: ValueShape,
      inmueble: ValueShape,
      prestamo: ValueShape,
    }),
  }).isRequired,
};

const cfg = [
  { id: 'valor-adquisicion-total', src: (inmueble) => inmueble?.valor?.inmueble?.value },
  { id: 'titularidad', src: (inmueble) => `${inmueble?.titularidad}%` },
  { id: 'valor-adquisicion-propio', src: (inmueble, translations) => `${translations.formatNumber(inmueble?.valor?.adquisicion?.raw, numberFormat)}€` },
  { id: 'fecha-adquisicion', src: (inmueble) => formatDateFullYear(inmueble?.adquision * 1000) },
  { id: 'escritura-notarial', src: (inmueble) => inmueble?.escrituraNotarial },
  { id: 'provincia', src: (inmueble) => inmueble?.provincia },
  { id: 'referencia-catastral', src: (inmueble) => inmueble?.catastro },
  { id: 'gastos-impuestos-adquisicion', src: (inmueble) => inmueble?.gastosAdquisicion?.value },
  { id: 'valor-catastral', src: (inmueble) => inmueble?.valor?.catastro?.value },
  { id: 'valor-hacienda', src: (inmueble) => inmueble?.valor?.hacienda?.value },
];

const InmueblesDetallesDatos = (props) => {
  const { inmueble } = props;
  const translations = useProvidedTranslations();

  return (
    <>
      <header className="header-box ">
        <h2>{translations.formatMessage('dash.inmueble.datos.title')}</h2>
      </header>
      <div className="body-box b-0">
        <p className="has-text-dark mt-1">
          <b>{inmueble?.direccion}</b><br />
          {inmueble?.municipio}<br />
          {translations.formatMessage(`dash.inmuebles.destino._${inmueble?.destino.toLowerCase()}`)}
        </p>
        <table className="table is-striped is-fullwidth mt-1">
          <tbody>
            {cfg.map(({ id, src }, i) => (
              <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                <td>{translations.formatMessage(`dash.inmueble.list._${id}`)}</td>
                <td className="has-text-right">{src(inmueble, translations)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {inmueble?.comentario?.general && (
          <ReactMarkdown className="has-text-dark mt-1">
            {inmueble?.comentario?.general}
          </ReactMarkdown>
        )}
      </div>
    </>
  );
};

InmueblesDetallesDatos.propTypes = propTypes;

export default InmueblesDetallesDatos;
