import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../../context';

const cfg = {
  resources: [
    { id: 'name', src: ({ name, lastName = '' }) => `${name} ${lastName}` },
    { id: 'type', src: ({ type }) => type },
    { id: 'participacion', src: ({ participacion }) => (participacion ? `${participacion}%` : '') },
    { id: 'domicilio', src: ({ domicilio }) => domicilio },
  ],
};

const propTypes = {
  data: PropTypes.shape().isRequired,
  i18nKey: PropTypes.string.isRequired,
};

const SociosAdministradores = (props) => {
  const { data, i18nKey } = props;
  const translations = useProvidedTranslations();
  const body = useMemo(() => {
    const rtn = [];
    if (data?.socios?.length) {
      data?.socios.forEach((socio) => {
        rtn.push({
          ...socio,
          type: 'socio',
        });
      });
    }
    if (data?.administradores?.length) {
      data?.administradores.forEach((administrador) => {
        rtn.push({
          name: administrador,
          type: 'administrador',
        });
      });
    }
    return rtn;
  }, [data.administradores, data.socios, translations]);

  return (
    <div className="table-container">
      <table className="table is-striped is-fullwidth mt-1">
        <thead>
          <tr>
            {cfg.resources
              .map(({ id, className = '' }) => (
                <th className={className} key={id}>
                  {translations.formatMessage(`${i18nKey}.resources._${id}`)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {body.map((socio) => (
            <tr key={socio.name}>
              {cfg.resources
                .map(({ id, className = '', src }) => (
                <td className={className} key={id}>
                  {src(socio)}
                </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SociosAdministradores.propTypes = propTypes;

export default SociosAdministradores;
