/* eslint-disable react/prop-types */
import React from 'react';
import {
  useProvidedTranslations,
} from '../context';
import {
  useBulldogAPI,
} from '../hooks';
import LoadingSpinner from './LoadingSpinner';
import LayoutNav from './LayoutNav';
import KnowMore from './KnowMore';

const cfg = {
  left: [
    {
      id: 'agencia',
      Component: ({ data }) => <>{data?.agencia || '-'}</>,
    },
    {
      id: 'contacto',
      Component: ({ data }) => {
        const { contacto: { mail, name, phone } = {} } = data;
        return (
          <>
            {name && <><strong>{name}</strong><br /></>}
            {phone && <>{phone}<br /></>}
            {mail && <a href="mailto:{mail}">{mail}</a>}
          </>
        );
      },
    },
    {
      id: 'honorarios',
      Component: ({ data }) => <>{data?.honorarios}</>,
    },
    {
      id: 'contrato-escrito',
      Component: ({ data, translations }) => {
        const { contrato = {} } = data;
        return (
          <>
            {translations.formatMessage(`dash.representantes.contarto-escrito._${contrato?.escrito ? 'yes' : 'no'}`)}<br />
            {translations.formatUnixDate(contrato?.since)}
          </>
        );
      },
    },
  ],
  right: [
    {
      id: 'situacion-legal',
      Component: ({ data, translations }) => {
        const { situacionLegal } = data;
        if (!situacionLegal) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              body: situacionLegal,
              title: translations.formatMessage('dash.representantes.situacion-legal-modal.title'),
            }}
          />
        );
      },
    },
    {
      id: 'asume-club',
      Component: ({ data, translations }) => (
          <>
            {translations.formatMessage(`dash.representantes.asume-club._${data?.asumeClub ? 'yes' : 'no'}`)}<br />
          </>
      ),
    },
    {
      id: 'cantidad-adeudada',
      Component: ({ data }) => <>{data?.cantidadAdeudada || '-'}</>,
    },
    {
      id: 'penalizacion',
      Component: ({ data }) => <>{data?.penalizacion?.value || '-'}</>,
    },
    {
      id: 'ultima-renovacion',
      Component: ({ data, translations }) => (
        <>{translations.formatUnixDate(data?.ultimaRenovacion)}</>
      ),
    },
  ],
};

const Representantes = () => {
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isFetching,
    isLoading,
  }] = useBulldogAPI('representantes', { tmp: undefined });
  const loading = isLoading || isFetching;

  return (
    <LayoutNav title={translations.formatMessage('dash.representantes.header')}>
      {loading && <LoadingSpinner />}
      {data.map((representante) => (
        <div key={representante.id} className="box box-white">
          <div className="body-box">
            <header className="header-box">
              <div className="d-flex">
                <h2>{translations.formatMessage('dash.representantes.header')}: <b>{representante?.agencia || '-'}</b></h2>
              </div>
            </header>
            <div className="columns is-variable is-6-desktop is-4-tablet">
              <div className="column is-6-tablet">
                <table className="table is-striped is-fullwidth mt-1">
                  <tbody>
                    {cfg.left.map(({ Component, id }) => (
                      <tr key={id}>
                        <th className="text-regular">{translations.formatMessage(`dash.representantes.th._${id}`)}</th>
                        <td className="has-text-right">
                          <Component
                            data={representante}
                            translations={translations}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="column is-6-tablet">
                <table className="table is-striped is-fullwidth mt-1">
                  <tbody>
                    {cfg.right.map(({ Component, id }) => (
                      <tr key={id}>
                        <th className="text-regular">{translations.formatMessage(`dash.representantes.th._${id}`)}</th>
                        <td className="has-text-right">
                          <Component
                            data={representante}
                            translations={translations}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ))}
    </LayoutNav>
  );
};

export default Representantes;
