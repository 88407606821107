/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  hide: PropTypes.func.isRequired,
};

const ModalContact = ({ hide }) => (
    <div className="modal is-active">
      <div className="modal-background" onClick={hide} />
      <div className="modal-card is-dark">
        <header className="modal-card-head">
          <p className="modal-card-title">Ayuda</p>
          <a className="button is-circle is-circle-sm" onClick={hide}>
            <span className="material-icons">
              close
              </span>
            </a>
        </header>
        <section className="modal-card-body bg-card-help">
          <h1>PASF: Gestor de control patrimonial</h1>
         <p className="lead mt-1">
          PASF es la primera y más completa herramienta para el control de los ingresos y patrimonio de deportistas profesionales.
        </p>
        <p className="lead mt-1">
          Para comenzar a utilizar este servicio y/o resolver cualquier duda, puede ponerse en contacto en el email <a href="mailto:pasf@pkf-attest.es" target="_blank" title="Contactar por email" rel="noreferrer">pasf@pkf-attest.es</a>.
        </p>
        <hr />
         <p className="mt-2">
          PKF Attest Legal & Fiscal<br />
          Calle Orense 81, 7<br />
          28020 Madrid<br />
          Tfno: <a href="tel:915561199" target="_blank" title="Contactar por teléfono" rel="noreferrer">915 561 199</a>
         </p>

        </section>
        <footer className="modal-card-foot">
          <a className="button is-primary" href="mailto:pasf@pkf-attest.es" target="_blank" title="Contactar por email" rel="noreferrer">Contacto</a>
          <a className="button" onClick={hide}>Cerrar</a>
        </footer>
      </div>
    </div>
);

ModalContact.propTypes = propTypes;

export default ModalContact;
