import { useContext } from 'react';
import context from './context';

const useProvidedModal = (containerId) => {
  const modalContext = useContext(context);
  if (!modalContext) {
    throw new Error('modal context its mandatory');
  }

  return modalContext(containerId);
};

export default useProvidedModal;
