import {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import Debug from 'debug';
import useAPIDefault from './useAPI';
import useAPIQuery from './useAPIQuery';

const debug = Debug('bulldog:useAPIList');
const reducer = (state, action) => {
  debug('reducer', { state, action });
  switch (action.type) {
    case 'fetch':
      return action.body;
    case 'update':
      return {
        ...state,
        data: action.body,
      };
    default:
      throw new Error('invalid action');
  }
};

/**
 * Takes a default query, a token and a url
 * Handles fetch resource from api, pagination and data update
 * @param {Object} opts - Config
 * @param {Object} opts.qs - Default query to request
 * @param {String} opts.authToken - TB auth token
 * @param {String} opts.url - URL to fetch data
 * @param {useAPI} useAPI - useAPI hook
 */
// eslint-disable-next-line max-len
const useAPIList = (optsRequest, permanentQueryDefault, useAPI = useAPIDefault) => {
  const { qs: defaultQuery } = optsRequest;
  const [query, dispatchQuery] = useAPIQuery(defaultQuery);

  // Lets set a query that it's not in normal query state
  // and cannot be updated via dispatchQuery
  const [permanentQuery, setPermanentQuery] = useState(permanentQueryDefault);

  // Lets update data received from API response
  // This is usefull for example if we receive socket
  // updates.
  const [response, dispatchData] = useReducer(
    reducer,
    { pagination: {}, data: [] },
  );

  const { data, pagination } = response;

  // Callback to api response-> Save data in reducer
  const handleAPIData = useCallback((resp) => dispatchData({ type: 'fetch', body: resp }), []);

  // Lets update data from reducer
  const handleUpdateData = useCallback((resp) => dispatchData({ type: 'update', body: resp }), []);

  const [{ error, isLoading }, fetchData] = useAPI({
    ...optsRequest,
    qs: query,
  }, handleAPIData);

  // Refetch when query changes
  useEffect(() => {
    const qs = { ...query, ...permanentQuery };
    debug('qs is', qs);
    fetchData({ qs });
  }, [fetchData, permanentQuery, query]);

  return [{
    isLoading, error, data, pagination, query, response,
  }, dispatchQuery, handleUpdateData, setPermanentQuery];
};

export default useAPIList;
