import React from 'react';
import {
  useProvidedConfiguration,
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import Resumen from './index';

const ResumenWithLayout = () => {
  const translations = useProvidedTranslations();
  const [{ data: { temporada } = {} }] = useProvidedConfiguration();

  return (
    <LayoutNav title={translations.formatMessage('dash.resumen.header', { temporada })}>
      <Resumen />
    </LayoutNav>
  );
};

export default ResumenWithLayout;
