/* eslint-disable no-unused-vars */
import React, {
  useCallback, useReducer, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import { useShortcut } from '../../hooks';
import modals from './constants';
import context from './context';

const debug = Debug('App:Modal:Provider');

const propTypes = {
  children: PropTypes.node.isRequired,
};

const initialState = {
  contentClassName: undefined,
  modalClassName: undefined,
  containerId: undefined,
  isHiddenOnBlur: undefined,
  id: '',
  params: {},
};

const reducer = (state, payload) => {
  debug('reducer', payload);
  const { id, type, ...overwrite } = payload;
  switch (type) {
    case 'display':
      if (!id || !modals[id]) {
        throw new Error('you must provide a valid modal id');
      }

      // If there are no modal containers with this containerId
      // modal will not appear
      if (!modals[id].containerId && !overwrite.containerId) {
        throw new Error('you must provide a valid container id');
      }

      return {
        ...state,
        ...modals[id],
        ...modals[id].defaultProps,
        ...overwrite,
        id,
      };
    case 'hide': {
      return { ...initialState };
    }
    default:
      throw new Error(`Unexpected action type: ${type}`);
  }
};

const ModalProvider = (props) => {
  const { children } = props;
  const [state, dispatchState] = useReducer(reducer, initialState);
  const refId = useRef();

  const display = useCallback((params) => {
    refId.current = params.id;
    dispatchState({ ...params, type: 'display' });
  }, []);

  const hide = useCallback(() => {
    dispatchState({ type: 'hide' });
  }, []);

  const value = useCallback((containerId) => [
    display,
    hide,
    state.containerId === containerId
      ? state
      : initialState,
  ], [display, hide, state]);

  const onShortcut = useCallback(() => {
    if (state.id && state.isHiddenOnBlur !== false) {
      if (state.params && state.params.onClose) {
        state.params.onClose();
      }
      hide(true);
    }
  }, [hide, state]);

  useShortcut('Escape', onShortcut);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

ModalProvider.propTypes = propTypes;

export default ModalProvider;
