import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';

const propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};
const defaultProps = {
  isLoading: false,
};

const BoxSingle = (props) => {
  const {
    className, label, isLoading, value,
  } = props;

  return (
    <div className="column is-6-desktop is-12-tablet">
      {isLoading
        ? <LoadingSpinner />
        : (
          <div className={`ticket ${className}`}>
            <p className="qty">{value}</p>
            <p className="qty-title">{label}</p>
          </div>
        )
      }
    </div>
  );
};

BoxSingle.propTypes = propTypes;
BoxSingle.defaultProps = defaultProps;

export default BoxSingle;
