import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  link: PropTypes.shape({
    cta: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

const EntornosLink = (props) => {
  const { link: { cta, url } } = props;

  if (!url) {
    return null;
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {cta || url}
    </a>
  );
};

EntornosLink.propTypes = propTypes;

export default EntornosLink;
