import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  timeout: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.string,
};

const defaultProps = {
  timeout: 8,
  type: 'success',
};

const Notification = (props) => {
  const { timeout, type, message } = props;
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay(false);
    }, timeout * 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!display) {
    return null;
  }

  return (
    <div className={`notification is-${type}`}>
      {message}
    </div>
  );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
