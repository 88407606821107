import {
  toLocaleStringOptions,
} from '../config';
import formatDate from './formatDate';

const formatDateFullYear = (date, lang) => (
  formatDate(date, lang, toLocaleStringOptions.fullYear)
);

export default formatDateFullYear;
