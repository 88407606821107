import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import {
  gaEnabled,
  gaId,
} from '../config';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (!gaEnabled) {
      return;
    }
    ReactGA.initialize(gaId);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;
