import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const BoxSinglePopover = (props) => {
  const {
    className, label, value,
  } = props;

  return (
    <div className="column is-6-desktop is-12-tablet">
      <div className={`ticket ${className}`}>
        <div className="popover is-popover-top ml-1 popover-ticket">
          <a className="is-neg popover-trigger">
            <span className="material-icons">
              help
              </span>
            </a>
          <div className="popover-content">
            <p>{value}</p>
          </div>
        </div>
        <p className="qty-title">{label}</p>
      </div>
    </div>
  );
};

BoxSinglePopover.propTypes = propTypes;

export default BoxSinglePopover;
