import React from 'react';
import PropTypes from 'prop-types';
import { useProvidedNotifications } from '../../context';

const propTypes = {
  className: PropTypes.string,
  containerId: PropTypes.string.isRequired,
};

const defaultProps = {
  className: 'alert container-top',
};

const Notification = (props) => {
  const { message, onDelete, type } = props;
  return (
    <div className={`notification ${type ? ` is-${type}` : ''}`}>
      <button className="delete" type="button" onClick={onDelete}>X</button>
      {message}
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const Notifications = (props) => {
  const { className, containerId } = props;
  const [notifications, dispatchNotifications] = useProvidedNotifications();

  const notificationsToDisplay = Object.values(notifications)
    .filter((el) => el.containerId === containerId);

  const remove = (id) => dispatchNotifications({
    type: 'remove',
    data: {
      id,
    },
  });

  if (!notificationsToDisplay.length) {
    return null;
  }

  return (
    <div className={className}>
      {notificationsToDisplay.map((el) => (
        <Notification
          key={el.id}
          message={el.message}
          type={el.type}
          onDelete={() => remove(el.id)}
        />
      ))}
    </div>
  );
};

Notifications.propTypes = propTypes;
Notifications.defaultProps = defaultProps;

export default Notifications;
