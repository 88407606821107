import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../context';
import {
  useBulldogAPI,
  useUpdatedDate,
} from '../../../hooks';
import KnowMore from '../../KnowMore';
import LoadingSpinner from '../../LoadingSpinner';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  columns: ({
    patrimonio, row, toId = row, translations,
  }) => (
    [
      { className: 'sticky-col first-col', value: translations.formatMessage(`dash.resumen.table._${row}`) },
      { className: 'has-text-right', value: patrimonio?.activo?.[row]?.value },
      { className: 'has-text-right', value: patrimonio?.pasivo?.[row]?.value },
      {
        className: 'has-text-right',
        value: toId && (
        <KnowMore
          toId="detalles-sociedad"
          params={{
            section: toId,
            sociedad: patrimonio?.sociedad,
          }}
          />
        ),
      },
    ]),
  rows: [
    { row: 'bancos' },
    { row: 'inmobiliario', toId: 'inmuebles' },
    { row: 'prestamos' },
    { row: 'otros', toId: 'otros-patrimonios' },
    { row: 'sociedades', toId: 'otras-sociedades' },
    { className: 'tr-total', row: 'total', toId: null },
  ],
};

const SociedadesResumen = (props) => {
  const { qs } = props;
  const translations = useProvidedTranslations();
  const date = useUpdatedDate('resumenPatrimonio');
  const [{
    data: {
      data: [patrimonio = {}] = [],
    } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('patrimonios', { tmp: undefined, ...qs });

  return (
    <div className="columns is-multiline">
      <div className="column is-12-desktop is-12-tablet">
        <div className="box box-white">
          <header className="header-box ">
            <h2>{translations.formatMessage('dash.resumen.h2-patrimonio_today', { date })}</h2>
          </header>
          <div className="body-box">
            <div className="table-container">
              <table className="table is-fullwidth is-striped">
                <thead>
                  <tr>
                    <th />
                    <th className="has-text-right">{translations.formatMessage('dash.resumen.table.th.activos')}</th>
                    <th className="has-text-right">{translations.formatMessage('dash.resumen.table.th.pasivos')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {!isLoading
                  && !isFetching
                  && cfg.rows.map((
                    { className: rowClassName, row, toId },
                  ) => (
                    <tr key={row} className={rowClassName}>
                      {cfg.columns({
                        patrimonio, row, toId, translations,
                      })
                        .map(({ className, value }, i) => (
                          <td key={`${row}-${i}`} className={className}>{value}</td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {(isLoading || isFetching)
                && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SociedadesResumen.defaultProps = defaultProps;
SociedadesResumen.propTypes = propTypes;

export default SociedadesResumen;
