import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
} from '../../hooks';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import DocumentListModal from './Modal';
import DocumentListHeadersBlue from './Headers/Blue';
import { numberFormat } from '../../config';

const propTypes = {
  defaults: PropTypes.arrayOf(PropTypes.string).isRequired,
  endPoint: PropTypes.string.isRequired,
  Header: PropTypes.elementType,
  HeaderPosition: PropTypes.string,
  i18nKey: PropTypes.string,
  qs: PropTypes.shape({}),
  resources: PropTypes.arrayOf(PropTypes.shape({
    Component: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    src: PropTypes.func,
  }).isRequired).isRequired,
  sort: PropTypes.func,
};

const defaultProps = {
  Header: DocumentListHeadersBlue,
  HeaderPosition: 'outside',
  i18nKey: 'dash.document-list',
  qs: {},
  sort: (
    { order: order1 = 0 },
    { order: order2 = 0 },
  ) => order1 - order2,
};

const reducer = (data = [], src) => data.reduce((accumulator, element) => {
  const currentValue = src(element, 'raw') || 0;
  return accumulator + currentValue;
}, 0);

const DocumentList = (props) => {
  const {
    defaults,
    endPoint,
    Header,
    HeaderPosition,
    i18nKey,
    qs,
    resources,
    sort,
    ...rest
  } = props;
  const translations = useProvidedTranslations();
  const [{
    data: { data = [], pagination } = {},
    isFetching,
    isLoading,
  }, doFetch] = useBulldogAPI(endPoint, qs);
  const [selected, setSelected] = useState(defaults);
  const [display, setDisplay] = useState(false);
  const displayTotals = useMemo(() => (
    resources.find(({ num }) => num)
  ), [resources]);
  const getCurrency = (src) => (src(data[0], 'value', translations) || '').replace(/[0-9,.]/g, '') || '€';

  if (isLoading || isFetching) {
    return <LoadingSpinner />;
  }

  if (data.length === 0) {
    return (
      <div className="notification is-warning">
        {translations.formatMessage('dash.document-list.empty-result')}
      </div>
    );
  }

  return (
    <>
      {display && (
        <DocumentListModal
          hide={() => setDisplay(false)}
          i18nKey={i18nKey}
          resources={resources}
          selected={selected}
          setSelected={setSelected}
        />
      )}
      {HeaderPosition === 'outside' && <Header {...props} setDisplay={setDisplay} />}
      <div className="body-box b-0">
        {HeaderPosition === 'inside' && <Header {...props} setDisplay={setDisplay} />}
        <div className="table-container">
          <table className="table is-striped is-fullwidth mt-1">
            <thead>
              <tr>
                {resources
                  .filter(({ id }) => selected.includes(id))
                  .map(({ id, className = 'has-text-right' }) => (
                    <th className={className} key={id}>{translations.formatMessage(`${i18nKey}.resources._${id}`)}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data
                .sort(sort)
                .map((row) => (
                  <tr key={row.id}>
                    {resources
                      .filter(({ id }) => selected.includes(id))
                      .map(({ id, className = 'has-text-right', src = () => '_' }) => (
                      <td className={className} key={id}>{src(row, 'value', translations, { ...rest }) || '-'}</td>
                      ))}
                  </tr>
                ))}
              {displayTotals && (
                  <tr className="tr-total">
                    {resources
                      .filter(({ id }) => selected.includes(id))
                      .map(({
                        id, className = 'has-text-right', num = false, src,
                      }) => {
                        const currency = num ? getCurrency(src) : null;

                        if (/first-col/.test(className)) {
                          return (
                            <td key="totales" className={className}>
                              {translations.formatMessage('dash.document-list.table.total')}
                            </td>
                          );
                        }
                        return (num
                          ? (
                            <td className={className} key={id}>
                              {currency === '€' && `${translations.formatNumber(reducer(data, src), numberFormat)} ${currency}`}
                              {currency !== '€' && `${currency} ${translations.formatNumber(reducer(data, src), numberFormat)}`}
                            </td>)
                          : <td className={className} key={id}>&nbsp;</td>);
                      })}
                    </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          pagination={pagination}
          onClick={(page) => doFetch({ qs: { ...qs, page } })}
        />
      </div>
    </>
  );
};

DocumentList.propTypes = propTypes;
DocumentList.defaultProps = defaultProps;

export default DocumentList;
