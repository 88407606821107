import React from 'react';
import {
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import Link from '../Link';

const propTypes = {
  detallesToId: PropTypes.string,
  i18nKey: PropTypes.string.isRequired,
  qs: PropTypes.shape(),
};

const defaultProps = {
  detallesToId: 'detalles-inmuebles',
  qs: {},
};

const cfg = {
  i18nKey: 'dash.inmuebles',
};

const params = {
  ...cfg,
  defaults: [
    'municipio',
    'direccion',
    'titularidad',
    'destino',
    'valor-adquisicion',
    'valor-prestamo',
    'detalles',
    'comentario',
  ],
  endPoint: 'inmuebles',
  resources: [
    {
      id: 'municipio',
      className: 'sticky-col first-col',
      src: (data) => data?.municipio,
      disabled: true,
    },
    {
      id: 'direccion',
      className: 'has-text-left',
      src: (data) => data?.direccion,
    },
    {
      id: 'titularidad',
      src: (data) => `${data?.titularidad}%`,
    },
    {
      id: 'destino',
      src: (data, w, translations) => translations.formatMessage(`dash.inmuebles.destino._${data?.destino.toLowerCase()}`),
    },
    {
      id: 'provincia',
      src: (data) => data?.provincia,
    },
    {
      id: 'country',
      src: (data) => data?.country,
    },
    {
      id: 'valor-hacienda',
      src: (data, w = 'value') => data?.valor?.hacienda?.[w],
      num: true,
    },
    {
      id: 'valor-adquisicion',
      src: (data, w = 'value') => data?.valor?.adquisicion?.[w],
      num: true,
    },
    {
      id: 'valor-inmueble',
      src: (data, w = 'value') => data?.valor?.inmueble?.[w],
      num: true,
    },
    {
      id: 'valor-prestamo',
      src: (data, w = 'value') => data?.valor?.prestamo?.[w],
      num: true,
    },
    {
      id: 'valor-catastro',
      src: (data, w = 'value') => data?.valor?.catastro?.[w],
      num: true,
    },
    {
      id: 'gastos-adquisicion',
      src: (data, w = 'value') => data?.gastosAdquisicion?.[w],
      num: true,
    },
    {
      id: 'escritura-notarial',
      src: (data) => data?.escrituraNotarial,
    },
    {
      id: 'adquision',
      src: (data) => data?.adquision,
    },
    {
      id: 'alquiler',
      src: (data, w, translations) => translations.formatMessage(`dash.inmuebles.alquilado._${data?.alquiler?.enabled ? 'yes' : 'no'}`),
    },
    {
      id: 'titular',
      src: (data) => data?.titular,
    },
    {
      id: 'catastro',
      src: (data) => data?.catastro,
    },
    {
      id: 'detalles',
      className: 'has-text-centered',
      src: (data, w, translations, { detallesToId, sociedad }) => (
        <Link
            className="button is-primary is-outlined is-small"
            params={{ id: data.id, sociedad }}
            toId={detallesToId}
        >
          {translations.formatMessage('dash.inmuebles.resources._ver-inmueble')}
        </Link>
      ),
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentario?.general) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: `${data?.direccion}, ${data?.municipio}`,
              body: data?.comentario?.general,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
  Header: () => null,
};

const InmueblesListado = (props) => {
  const { detallesToId, qs } = props;
  const { sociedad } = useParams();
  return (
    <DocumentList
      {...params}
      {...props}
      detallesToId={detallesToId}
      sociedad={sociedad}
      qs={{ ...(params.qs ? params.qs : {}), ...qs }}
    />
  );
};

InmueblesListado.propTypes = propTypes;
InmueblesListado.defaultProps = defaultProps;

export default InmueblesListado;
