import { useCallback, useState } from 'react';
import Debug from 'debug';
import { v4 as uuid } from 'uuid';

const debug = Debug('bulldog:useTimers');

/**
 * Handles a list of timers
 * @param {Number} defaultTimeout - Default timeout to use with timers
 * @returns {Array[timers,dispatchTimer]}
 */
const useTimers = (defaultTimeout) => {
  const [timers, setTimer] = useState({});

  // eslint-disable-next-line consistent-return
  const dispatchTimer = useCallback((payload) => {
    debug('dispatchTimer', payload);
    const { type } = payload;
    switch (type) {
      case 'add': {
        const { cb, timeout = defaultTimeout } = payload;
        const key = uuid();
        setTimer((current) => ({
          ...current,
          [key]:
            setTimeout(() => {
              if (cb) {
                cb();
              }
              dispatchTimer({ type: 'remove', key });
            }, timeout),
        }));
        return key;
      }
      case 'remove': {
        const { key } = payload;
        setTimer((current) => {
          const newTimer = { ...current };
          if (newTimer[key]) {
            clearTimeout(newTimer[key]);
            delete newTimer[key];
          }
          return newTimer;
        });
        break;
      }
      default: {
        break;
      }
    }
  }, [defaultTimeout]);

  return [timers, dispatchTimer];
};

export default useTimers;
