import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
  useUpdatedDate,
} from '../../hooks';
import KnowMore from '../KnowMore';
import LoadingSpinner from '../LoadingSpinner';
import ResumenCantidadesPendientes from './CantidadesPendientes';

const cfg = {
  columns: ({
    patrimonio, row, toId = row, translations,
  }) => (
    [
      { className: 'sticky-col first-col', value: translations.formatMessage(`dash.resumen.table._${row}`) },
      { className: 'has-text-right', value: patrimonio?.activo?.[row]?.value },
      { className: 'has-text-right', value: patrimonio?.pasivo?.[row]?.value },
      { className: 'has-text-right', value: toId && <KnowMore toId={toId} /> },
    ]),
  rows: [
    { row: 'bancos' },
    { row: 'inmobiliario', toId: 'inmuebles' },
    { row: 'sociedades' },
    { row: 'mutualidad', toId: 'mutualidades' },
    { row: 'prestamos' },
    { row: 'otros', toId: 'otros-patrimonios' },
    { className: 'tr-total', row: 'total', toId: null },
  ],
};

const Resumen = () => {
  const translations = useProvidedTranslations();
  const date = useUpdatedDate('resumenPatrimonio');
  const [{
    data: {
      data: [patrimonio = {}] = [],
    } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('patrimonios', { tmp: undefined });
  const loading = isLoading || isFetching;

  return (
    <>
      <ResumenCantidadesPendientes />
      <div className="columns is-multiline">
        <div className="column is-12-desktop is-12-tablet">
          <div className="box box-white">
            <header className="header-box ">
              <h2>{translations.formatMessage('dash.resumen.h2-patrimonio_today', { date })}</h2>
            </header>
            <div className="body-box">
              <div className="table-container">
                <table className="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th />
                      <th className="has-text-right">{translations.formatMessage('dash.resumen.table.th.activos')}</th>
                      <th className="has-text-right">{translations.formatMessage('dash.resumen.table.th.pasivos')}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {!loading
                    && cfg.rows.map((
                      { className: rowClassName, row, toId },
                    ) => (
                      <tr key={row} className={rowClassName}>
                        {cfg.columns({
                          patrimonio, row, toId, translations,
                        })
                          .map(({ className, value }, i) => (
                            <td key={`${row}-${i}`} className={className}>{value}</td>
                          ))}
                      </tr>
                    ))}
                    {loading && (
                      <tr>
                        <td colSpan="4">
                          <LoadingSpinner />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resumen;
