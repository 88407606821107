import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import {
  useProvidedTranslations,
} from '../../context';
import {
  chartColors,
} from '../../config';


const cfg = {
  options: {
    colors: chartColors,
    legend: { position: 'bottom' },
  },
};

const propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      chartData: PropTypes.func,
    }).isRequired,
  ).isRequired,
  data: PropTypes.array.isRequired,
};
const defaultProps = {};

const BancosChart = (props) => {
  const { charts, data: dataProp } = props;
  const [selected, setSelected] = useState(charts?.[0]?._id);
  const translations = useProvidedTranslations();
  const { chartData } = charts.find(({ _id }) => _id === selected);
  const data = useMemo(() => (
    chartData({ data: dataProp, translations })
  ), [chartData, dataProp]);

  return (
    <div className="column is-6-tablet">
    <div className="box">
      <header className="header-box is-toggle">
        <div className="tabs is-toggle is-fullwidth is-centered">
          <ul>
            {charts.map(({ _id }) => (
              <li className={`${selected === _id ? 'is-active' : ''}`} key={_id}>
                <a onClick={() => setSelected(_id)}>
                  {translations.formatMessage(`dash.entidades-financieras.chart._${_id}`)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </header>
      <div className="body-box bg-cover bg6">
        {data.length > 0 && <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data}
          options={cfg.options}
        />}
      </div>
    </div>
  </div>
  );
};

BancosChart.propTypes = propTypes;
BancosChart.defaultProps = defaultProps;

export default BancosChart;
