/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import context from './context';

const debug = Debug('bulldog:Notifications:Provider');

const propTypes = {
  children: PropTypes.node.isRequired,
  defaultTimeout: PropTypes.number,
};

const defaultProps = {
  defaultTimeout: 3,
};

// TODO: add validation?
const reduceNotifications = (notifications, action) => {
  const { data, type } = action;
  switch (type) {
    case 'add':
      return [...notifications, data];
    case 'remove':
      return notifications.filter(
        (notification) => notification.id !== data.id,
      );
    default:
      throw new Error(`invalid action ${type}`);
  }
};

const NotificationsProvider = (props) => {
  const { children, defaultTimeout } = props;
  const [
    notifications,
    dispatchNotifications,
  ] = useReducer(reduceNotifications, []);

  const handleNotification = useCallback((payload) => {
    debug('onNotification', payload);
    const { data, timeout = defaultTimeout, type } = payload;
    if (type === 'add' && timeout) {
      setTimeout(() => {
        dispatchNotifications({ type: 'remove', data });
      }, timeout * 1000);
    }

    dispatchNotifications(payload);
  }, [defaultTimeout]);

  const value = useMemo(() => [
    notifications,
    handleNotification,
  ], [handleNotification, notifications]);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

NotificationsProvider.propTypes = propTypes;
NotificationsProvider.defaultProps = defaultProps;

export default NotificationsProvider;
