/* eslint-disable no-unused-vars */
import React, {
  useCallback, useMemo, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import { v4 as uuid } from 'uuid';
import { useTimers } from '../../hooks';
import context from './context';

const debug = Debug('App:Loading:Provider');

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const initialState = {
  isLoading: false,
  loading: [],
};

const reducer = (state, payload) => {
  debug('reducer', payload);
  const {
    key, text, translationKey, type,
  } = payload;
  switch (type) {
    case 'add':
      return {
        isLoading: true,
        loading: [...state.loading, key],
        text: text ? { key, text } : state.text,
        translationKey: translationKey
          ? { key, translationKey }
          : state.translationKey,
      };
    case 'remove': {
      const newLoading = state.loading.filter(
        (loadingKey) => loadingKey !== key,
      );
      return {
        loading: newLoading,
        isLoading: !!newLoading.length,
        text: state.text && state.text.key !== key
          ? state.text
          : undefined,
        translationKey: state.translationKey && state.translationKey.key !== key
          ? state.translationKey
          : undefined,
      };
    }
    case 'remove-all':
      return { ...initialState };
    default:
      throw new Error(`Unexpected action type: ${type}`);
  }
};

const useLoading = () => {
  const [state, dispatchState] = useReducer(reducer, initialState);
  const [, dispatchTimer] = useTimers();
  // eslint-disable-next-line consistent-return
  const dispatchLoading = useCallback((payload) => {
    debug('dispatchLoading', payload);
    const { type } = payload;
    switch (type) {
      case 'add': {
        const { timeout } = payload;
        let key;
        if (timeout) {
          key = dispatchTimer({
            type: 'add',
            timeout,
            cb: () => dispatchState({ type: 'remove', key }),
          });
        } else {
          key = uuid();
        }
        dispatchState({ ...payload, key });
        return key;
      }
      case 'remove': {
        const { key } = payload;
        dispatchTimer({ type: 'remove', key });
        dispatchState(payload);
        break;
      }
      default:
        dispatchState(payload);
        break;
    }
  }, [dispatchTimer]);
  return useMemo(
    () => [
      state.isLoading ? {
        text: state.text ? state.text.text : undefined,
        translationKey: state.translationKey
          ? state.translationKey.translationKey
          : undefined,
      } : false,
      dispatchLoading,
    ],
    [state.isLoading, state.text, state.translationKey, dispatchLoading],
  );
};

const LoadingProvider = (props) => {
  const { children } = props;
  const value = useLoading();

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

LoadingProvider.propTypes = propTypes;
LoadingProvider.defaultProps = defaultProps;

export default LoadingProvider;
