import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useProvidedModal,
  useProvidedTranslations,
  useProvidedUserInfo,
} from '../../../context';
import { useBulldogAPI } from '../../../hooks';
import LoadingSpinner from '../../LoadingSpinner';
import Link from '../../Link';
import OtrasSociedades from './OtrasSociedades/index';
import SociedadesResumen from './Resumen';
import SociedadesDetallesDatosSociedad from './DatosSociedad';
import Bancos from '../../Bancos';
import Declaraciones from './Declaraciones';
import Inmuebles from '../../Inmuebles';
import Prestamos from '../../Prestamos';
import OtrosPatrimonios from '../../OtrosPatrimonios';
import Cronologia from '../../Cronologia';
import Avales from '../../Avales';
import Seguros from '../../Seguros';
import Footer from '../../Footer';

const cfg = {
  menu: [
    {
      key: 'resumen', isDefault: true, isPublic: true, Component: SociedadesResumen,
    },
    { key: 'datos-sociedad', Component: SociedadesDetallesDatosSociedad },
    { key: 'bancos', Component: (props) => <Bancos detallesToId="detalles-bancos-sociedad" {...props} /> },
    { key: 'inmuebles', Component: (props) => <Inmuebles detallesToId="detalles-inmuebles-sociedad" {...props} /> },
    { key: 'otras-sociedades', Component: OtrasSociedades },
    { key: 'declaraciones', Component: Declaraciones },
    { key: 'seguros', Component: Seguros },
    { key: 'prestamos', Component: Prestamos },
    { key: 'otros-patrimonios', Component: OtrosPatrimonios },
    { key: 'avales', Component: Avales, display: false },
    {
      key: 'cronologia',
      Component: (params) => (
        <div className="bg-cover is-fixed bg1">
          <Cronologia {...params} />
        </div>
      ),
    },
  ],
};

const SociedadesDetalles = () => {
  const {
    docId,
    section = cfg.menu.find(({ isDefault }) => isDefault).key,
    sociedad: keySociedad,
  } = useParams();
  const [visible, setVisible] = useState(false);
  const [{
    profiles = [],
  } = {}] = useProvidedUserInfo();
  const currentProfile = (
    profiles.find((element) => element._id === docId) || {}
  );
  const defaultProfile = (
    profiles.find((element) => element.type === 'player') || {}
  );
  const {
    permissions = [],
  } = currentProfile || {};
  const translations = useProvidedTranslations();
  const [displayModal] = useProvidedModal();
  const Component = useMemo(() => {
    const selected = cfg.menu.find(({ key }) => key === section);
    if (selected) {
      return selected.Component;
    }
    return null;
  }, [section]);
  const [{
    data: { data: [sociedad = {}] = [] } = {},
    loading: isLoading,
    fetching: isFetching,
  }] = useBulldogAPI('datos-sociedad', { sociedad: keySociedad, tmp: undefined });
  const loading = isLoading || isFetching;

  // cuando cambiamos de pagina ocultamos el menu
  // https://trello.com/c/nx6pM5RG/119-pliegue-del-men%C3%BA-de-sociedades
  useEffect(() => {
    setVisible(false);
  }, [section]);


  return (
    <>
      <nav className="nav-main nav-three">
        <div className="nav-main-item">
          {sociedad?.name && <h1>{sociedad?.name}</h1>}
        </div>
        <div className="nav-main-item">
          <Link toId="sociedades" params={{ docId: defaultProfile?._id }} className="button is-circle">
            <span className="material-icons">close</span>
          </Link>
        </div>
      </nav>
      <nav
        className="navbar navbar-gray"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger burger ${visible ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setVisible(!visible)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${visible ? 'is-active' : ''}`}>
          <div className="navbar-start is-centered">
            {loading && <LoadingSpinner />}
            {!loading && cfg.menu
              .filter(({ display = true }) => display)
              .map(({ key, isPublic = false }) => {
                if (isPublic || permissions.includes(key)) {
                  return (
                  <Link
                    className={`navbar-item ${section === key ? 'is-active' : ''}`}
                    key={key}
                    toId="detalles-sociedad"
                    params={{ docId, section: key, sociedad: keySociedad }}
                    >
                    {translations.formatMessage(`dash.sociedades.main-menu._${key}`)}
                  </Link>
                  );
                }
                return (
              <Link key={key} className='nav-item is-locked' onClick={() => displayModal({ id: 'upgrade' })}>
                  <>
                    <span className="material-icons">
                      lock
                    </span>
                    {' '}
                  </>
                {translations.formatMessage(`dash.sociedades.main-menu._${key}`)}
              </Link>
                );
              })}
          </div>
        </div>
      </nav>

      <main className="main pt-1">
        <div className="container is-fluid">
          <Component qs={{ sociedad: keySociedad }} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SociedadesDetalles;
