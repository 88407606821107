import Admin from './components/Admin';
import AvalesWithLayout from './components/Avales/WithLayout';
import Ayuda from './components/Ayuda/index';
import Contrato from './components/Contrato';
import CronologiaWithLayout from './components/Cronologia/WithLayout';
import DeclaracionesWithLayout from './components/Declaraciones/WithLayout';
import BancosWithLayout from './components/Bancos/WithLayout';
import BancosDetalles from './components/Bancos/Detalles';
import Entornos from './components/Entornos';
import EntornosDetalles from './components/Entornos/Detalles';
import Evoluciones from './components/Evoluciones';
import InmueblesWithLayout from './components/Inmuebles/WithLayout';
import InmueblesDetalles from './components/Inmuebles/Detalles';
import MisDatos from './components/MisDatos';
import Mutualidades from './components/Mutualidades';
import OtrosPatrimoniosWithLayout from './components/OtrosPatrimonios/WithLayout';
import PrestamosWithLayout from './components/Prestamos/WithLayout';
import ProfileSelector from './components/ProfileSelector';
import Rankings from './components/Rankings';
import Representantes from './components/Representantes';
import ResumenWithLayout from './components/Resumen/WithLayout';
import SegurosWithLayout from './components/Seguros/WithLayout';
import Temporada from './components/Temporada';
import Temporizadores from './components/Temporizadores';
import Sociedades from './components/Sociedades';
import SociedadesDetalles from './components/Sociedades/Detalles';
import SociedadesDetallesOtrasSociedadesDetalles from './components/Sociedades/Detalles/OtrasSociedades/Detalles';

const routes = [
  {
    path: '/',
    key: 'homepage',
    exact: true,
    Component: ProfileSelector,
  },
  {
    isDefault: true,
    path: '/resumen/:docId',
    key: 'resumen',
    exact: true,
    Component: ResumenWithLayout,
  },
  {
    path: '/contrato/:tab/:docId',
    key: 'caracteristias-contratos',
    exact: true,
    Component: Contrato,
  },
  {
    path: '/bancos-sociedad/:sociedad/:docId/:id',
    key: 'detalles-bancos-sociedad',
    Component: BancosDetalles,
  },
  {
    path: '/bancos/:docId/:id',
    key: 'detalles-bancos',
    Component: BancosDetalles,
  },
  {
    path: '/bancos/:docId',
    key: 'bancos',
    Component: BancosWithLayout,
  },
  {
    path: '/seguros/:docId',
    key: 'seguros',
    Component: SegurosWithLayout,
  },
  {
    path: '/otros-patrimonios/:docId',
    key: 'otros-patrimonios',
    Component: OtrosPatrimoniosWithLayout,
  },
  {
    path: '/temporizadores/:docId',
    key: 'temporizadores',
    Component: Temporizadores,
  },
  {
    path: '/evoluciones/:docId',
    key: 'evolucion-ingresos',
    Component: Evoluciones,
  },
  {
    path: '/entornos/:docId/:id',
    key: 'detalles-entorno',
    Component: EntornosDetalles,
  },
  {
    path: '/entornos/:docId',
    key: 'entorno',
    Component: Entornos,
  },
  {
    path: '/cronologia/:docId',
    key: 'cronologia',
    Component: CronologiaWithLayout,
  },
  {
    path: '/declaraciones/:docId',
    key: 'declaraciones',
    Component: DeclaracionesWithLayout,
  },
  {
    path: '/representantes/:docId',
    key: 'representantes',
    Component: Representantes,
  },
  {
    path: '/inmuebles-sociedad/:sociedad/:docId/:id',
    key: 'detalles-inmuebles-sociedad',
    Component: InmueblesDetalles,
  },
  {
    path: '/inmuebles/:docId/:id',
    key: 'detalles-inmuebles',
    Component: InmueblesDetalles,
  },
  {
    path: '/inmuebles/:docId',
    key: 'inmuebles',
    Component: InmueblesWithLayout,
  },
  {
    path: '/prestamos/:docId',
    key: 'prestamos',
    Component: PrestamosWithLayout,
  },
  {
    path: '/rankings/:docId',
    key: 'rankings',
    Component: Rankings,
  },
  {
    path: '/mutualidades/:docId',
    key: 'mutualidades',
    Component: Mutualidades,
  },
  {
    path: '/resumen-temporada/:docId/:tmp',
    key: 'resumen-temporada',
    Component: Temporada,
  },
  {
    path: '/avales/:docId',
    key: 'avales',
    Component: AvalesWithLayout,
  },
  {
    path: '/admin/:docId',
    key: 'admin',
    Component: Admin,
  },
  {
    path: '/sociedades/:docId',
    key: 'sociedades',
    Component: Sociedades,
  },
  {
    path: '/detalles-sociedad/:section/:sociedad/:docId',
    key: 'detalles-sociedad',
    Component: SociedadesDetalles,
  },
  {
    path: '/datos-otras-sociedad/:sociedadId/:docId',
    key: 'datos-otras-sociedad',
    Component: SociedadesDetallesOtrasSociedadesDetalles,
  },
  {
    path: '/ayuda/:docId',
    key: 'ayuda',
    Component: Ayuda,
  },
  {
    path: '/mis-datos/:docId',
    key: 'mis-datos',
    Component: MisDatos,
  },
];

export default routes;
