import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeaderBlue from '../../HeaderBlue';

const propTypes = {
  body: PropTypes.arrayOf(
    PropTypes.shape({
      extra: PropTypes.node,
      isBold: PropTypes.bool,
      label: PropTypes.string,
      neto: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      value: PropTypes.node,
    }).isRequired,
  ).isRequired,
  title: PropTypes.string,
  isStriped: PropTypes.bool,
};
const defaultProps = {
  isStriped: true,
};

const TemporadaDatosEconomicosTable = (prop) => {
  const { body, title, isStriped } = prop;
  const [visible, setVisible] = useState(true);

  return (
    <>
      <HeaderBlue
        className="mb-1"
        title={title}
        right={(
          <a className="button is-small flex-end" onClick={() => setVisible(!visible)}>
            <span className="material-icons">{visible ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</span>
          </a>
        )}
      />
      {visible && (
        <table className={`table is-fullwidth mt-1 ${isStriped ? 'is-striped' : ''}`}>
          <tbody>
            {body.map(({
              extra,
              isBold = false,
              label,
              neto,
              value,
            }) => (
              <tr key={label}>
                <th className={isBold ? '' : 'text-regular'}>{label}</th>
                <td className={`has-text-right is-hidden-mobile ${isBold ? 'is-bold' : ''}`}>{extra}</td>
                <td className={`has-text-right ${isBold ? 'is-bold' : ''}`}>{value}</td>
                {neto && <td className={`has-text-right ${isBold ? 'is-bold' : ''}`}>{neto}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

TemporadaDatosEconomicosTable.propTypes = propTypes;
TemporadaDatosEconomicosTable.defaultProps = defaultProps;

export default TemporadaDatosEconomicosTable;
