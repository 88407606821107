import React from 'react';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import Link from '../Link';
import EntornosImageProfile from './ImageProfile';
import DocumentListHeadersBlue from '../DocumentList/Headers/Blue';

const propTypes = {
  detallesToId: PropTypes.string,
  i18nKey: PropTypes.string.isRequired,
  qs: PropTypes.shape(),
};

const defaultProps = {
  detallesToId: 'detalles-entorno',
  qs: {},
};

const cfg = {
  i18nKey: 'dash.entorno',
};

const params = {
  ...cfg,
  defaults: [
    'logo',
    'name',
    'actividad',
    'detalles',
    'comentario',
  ],
  endPoint: 'entorno',
  resources: [
    {
      id: 'logo',
      src: (data) => (
        <EntornosImageProfile
          entorno={data}
        />
      ),
      className: 'sticky-col first-col',
      disabled: true,
    },
    {
      id: 'name',
      className: 'has-text-left',
      src: (data) => data?.name,
    },
    {
      id: 'actividad',
      src: (data) => data?.actividad,
    },
    {
      id: 'comentarios',
      src: (data) => data?.comentarios,
    },
    {
      id: 'detalles',
      className: 'has-text-centered',
      src: (data, w, translations, { detallesToId, sociedad }) => (
        <Link
            className="button is-primary is-outlined is-small"
            params={{ id: data.id, sociedad }}
            toId={detallesToId}
        >
          {translations.formatMessage('dash.inmuebles.resources._ver-inmueble')}
        </Link>
      ),
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentarios) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: data?.name,
              body: data?.comentarios,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
  Header: () => (
    <DocumentListHeadersBlue
      i18nKey={cfg.i18nKey}
    />
  ),
};

const InmueblesListado = (props) => {
  const { qs } = props;

  return (
    <DocumentList
      {...params}
      {...props}
      qs={{ ...(params.qs ? params.qs : {}), ...qs }}
    />
  );
};

InmueblesListado.propTypes = propTypes;
InmueblesListado.defaultProps = defaultProps;

export default InmueblesListado;
