import React, { useMemo } from 'react';
import {
  useProvidedTranslations,
} from '../context';
import {
  useBulldogAPI,
} from '../hooks';
import DocumentList from './DocumentList';
import LayoutNav from './LayoutNav';
import BoxHeroActivoPasivo from './Box/HeroActivoPasivo';
import KnowMore from './KnowMore';

const cfg = {
  i18nKey: 'dash.mutualidades',
};

const params = {
  ...cfg,
  defaults: [
    'year',
    'comienzo',
    'aportacion',
    'rentabilidad',
    'acumulado',
    'comentario',
  ],
  endPoint: 'mutualidades',
  i18nKey: 'dash.mutualidades',
  resources: [
    {
      id: 'year',
      className: 'sticky-col first-col',
      src: (data) => data?.year,
    },
    {
      id: 'comienzo',
      src: (data, w = 'value') => data?.comienzo?.[w],
    },
    {
      id: 'aportacion',
      src: (data, w = 'value') => data?.aportacion?.[w],
      num: true,
    },
    {
      id: 'rentabilidad',
      src: (data, w = 'value') => data?.rentabilidad?.[w],
      num: true,
    },
    {
      id: 'acumulado',
      src: (data, w = 'value') => data?.acumulado?.[w],
    },
    {
      id: 'comentario',
      src: (data, w, translations) => {
        if (!data?.comentario) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: translations.formatMessage('dash.mutualidad.modal.title', { year: data?.year }),
              body: data?.comentario,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Mutualidades = () => {
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isLoading: isLoadingTotals,
    isFetching: isFetchingTotals,
  }] = useBulldogAPI('mutualidades', { tmp: undefined });
  const totals = useMemo(() => {
    const { [data.length - 1]: row = {} } = data
      .sort(({ orderA }, { orderB }) => orderA - orderB);
    return {
      activos: row.acumulado?.raw || 0,
      pasivos: 0,
    };
  });

  return (
    <LayoutNav title={translations.formatMessage(`${params.i18nKey}.header`)}>
      <BoxHeroActivoPasivo
        totals={totals}
        isLoading={isLoadingTotals || isFetchingTotals}
      />
      <div className="box box-white">
        <DocumentList {...params} />
      </div>
    </LayoutNav>
  );
};

export default Mutualidades;
