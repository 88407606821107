import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../context';
import {
  useBulldogAPI,
} from '../../../hooks';
import CronologiaLayout from '../../Cronologia/Layout';

const propTypes = {
  inmueble: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const InmueblesDetallesCronologia = (props) => {
  const { inmueble: { id } } = props;
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('cronologia-inmuebles', { tmp: undefined, inmuebles: id });

  const loading = isLoading || isFetching;

  return (
    <>
      <header className="header-box ">
        <h2>{translations.formatMessage('dash.inmueble.alquiler.cronologia')}</h2>
      </header>
      <div className="body-box b-0 bg17">
        <CronologiaLayout
          data={data}
          isLoading={loading}
          />
      </div>
    </>
  );
};

InmueblesDetallesCronologia.propTypes = propTypes;

export default InmueblesDetallesCronologia;
