import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedLanguage,
  useProvidedUserInfo,
} from '../context';
import {
  toLocaleStringOptions,
} from '../config';

const useUpdatedDate = (key) => {
  const [lang] = useProvidedLanguage();
  const { docId } = useParams();
  const [{
    profiles = [],
  } = {}] = useProvidedUserInfo();
  const profile = profiles.find((element) => element._id === docId) || {};
  const { dates = {} } = profile || {};

  if (dates[key]) {
    return (
      new Date(dates[key] * 1000)
        .toLocaleDateString(lang, toLocaleStringOptions.basic)
    );
  }
  return null;
};

export default useUpdatedDate;
