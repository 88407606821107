import React from 'react';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';

const params = {
  defaults: [
    'persona',
    'type',
    'cantidad',
    'beneficiario',
    'comentario',
  ],
  endPoint: 'avales',
  i18nKey: 'dash.avales',
  resources: [
    {
      id: 'persona',
      className: 'sticky-col first-col',
      src: (data) => data?.persona,
    },
    {
      id: 'type',
      src: (data) => data?.type,
    },
    {
      id: 'cantidad',
      src: (data, w = 'value') => data?.cantidad?.[w],
      num: true,
    },
    {
      id: 'beneficiario',
      src: (data) => data?.beneficiario,
    },
    {
      id: 'comentario',
      src: (data, w, translations) => {
        if (!data?.comentario) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: translations.formatMessage('dash.avales.modal.title', { year: data?.year }),
              body: data?.comentario,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Avales = () => (
    <div className="box box-white">
      <DocumentList {...params} />
    </div>
);

export default Avales;
