import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
} from '../../hooks';
import LoadingSpinner from '../LoadingSpinner';
import TemporadaDatosEconomicos from './DatosEconomicos';
import TemporadaCuadroPrimas from './CuadroPrimas';
import TemporadaAvancePrimas from './AvancePrimas';
import Link from '../Link';

const cfg = {
  menu: [
    { id: 'datos-economicos', Component: TemporadaDatosEconomicos },
    { id: 'cuadro-primas', Component: TemporadaCuadroPrimas },
    { id: 'avance-primas', Component: TemporadaAvancePrimas },
  ],
};

const Temporada = (props) => {
  const { tmp } = useParams();
  const translations = useProvidedTranslations();
  const [item, setItem] = useState(cfg.menu[0].id);
  const { Component = <div /> } = cfg.menu.find(
    (element) => element.id === item,
  );
  const [{
    isLoading,
    isFetching,
    data: {
      data: [{ ...temporada } = {}] = [],
    } = {},
  }] = useBulldogAPI('caracteristias-contratos', { tmp });
  const loading = (
    isLoading
    || isFetching
    || !Object.keys(temporada).length
  );

  return (
    <>
      <nav className="nav-main">
          <div className="nav-main-item">
              <h1>{translations.formatMessage(`dash.temporada.h1._${tmp}`)}</h1>
          </div>
          <div className="nav-main-item">
              <Link
                toId="caracteristias-contratos"
                className="button is-circle"
                params={{ tab: 'caracteristicas' }}
                >
                <span className="material-icons">
                  close
                </span>
              </Link>
          </div>
      </nav>
      <main className="main">
        <div className="container is-fluid">
          <div className="tabs is-centered mt-1">
            <ul>
              {cfg.menu.map(({ id }) => (
                <li key={id} className={`${id === item ? 'is-active' : ''}`}>
                  <a onClick={() => setItem(id)}>
                    {translations.formatMessage(`dash.temporada.tabs._${id}`)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="box box-white mt-2">
            {loading
              ? <LoadingSpinner />
              : <Component tmp={tmp} {...props} />
            }
          </div>
        </div>
      </main>
  </>
  );
};

export default Temporada;
