import React from 'react';
import { staticContent } from '../config';

const LoadingSpinner = () => (
  <div style={{
    backgroundColor: '#EBEBEB', borderRadius: '8px', textAlign: 'center', width: '100%',
  }}>
    <img alt="loading" className="icon-loading" src={`${staticContent}/images/loading.svg`} />
  </div>
);

export default LoadingSpinner;
