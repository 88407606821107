import React from 'react';
import PropTypes from 'prop-types';
import { useAPICache } from '../../hooks';
import context from './context';

const propTypes = {
  cacheKey: PropTypes.string,
  cacheTimeout: PropTypes.number,
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

const defaultProps = {
  cacheKey: 'configuration',
  cacheTimeout: 15 * 60,
};

const ConfigurationProvider = (props) => {
  const {
    cacheKey, cacheTimeout, children, url,
  } = props;

  const value = useAPICache(cacheKey, cacheTimeout, {
    method: 'GET',
    url,
  });

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

ConfigurationProvider.propTypes = propTypes;
ConfigurationProvider.defaultProps = defaultProps;

export default ConfigurationProvider;
