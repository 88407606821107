import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash.chunk';
import Notification from '../Notification';
import {
  useProvidedTranslations,
  useProvidedUserInfo,
} from '../../context';
import {
  useAPIAuthorized,
} from '../../hooks';
import {
  apiBaseURL,
} from '../../config';

const cfg = {
  dates: [
    { _id: 'resumenPatrimonio' },
    { _id: 'patrimonioFinanciero' },
    { _id: 'otrosPatrimonio' },
  ],
  lines: 1,
};

const propTypes = {
  columnsClassName: PropTypes.string,
  dates: PropTypes.shape({
    otrosPatrimonio: PropTypes.number.isRequired,
    patrimonioFinanciero: PropTypes.number.isRequired,
    resumenPatrimonio: PropTypes.number.isRequired,
  }).isRequired,
  sub: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
};

const defaultProps = {
  columnsClassName: undefined,
};

const LoadingSpinner = () => (
  <div className="loader-wrapper">
    <div className="loader is-loading" />
  </div>
);

const formatDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp);
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${date.getUTCFullYear()}-${month}-${day}`;
};

const AdminDates = (props) => {
  const {
    columnsClassName, dates: propDate = {}, profile, sub,
  } = props;
  const [, refresh] = useProvidedUserInfo();
  const [dates, setDate] = useState(propDate);
  const [displayNotification, setDisplayNotification] = useState(false);
  const columns = chunk(cfg.dates, cfg.lines);
  const translations = useProvidedTranslations();
  const [{
    isFetching,
    isLoading,
  }, doPut] = useAPIAuthorized({
    method: 'PUT',
    url: `${apiBaseURL}/admin/users/${sub}`,
    wait: true,
  }, () => {
    setDisplayNotification(true);
    refresh();
    setTimeout(() => {
      setDisplayNotification(false);
    }, 5500);
  });
  const handle = (name, value) => {
    setDate({
      ...dates,
      [name]: new Date(value).getTime() / 1000,
    });
  };
  const loading = isLoading || isFetching;

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="columns mb-0">
        {columns.map((column, i) => (
          <div key={i} className={`column ${columnsClassName || (i === 0 ? 'is-3-tablet' : 'is-3')}`}>
            {column.map(({ _id }) => (
              <div className="field" key={_id}>
                <label className="label">
                  {translations.formatMessage(`dash.admin.dates.form._${_id}`)}
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="date"
                    name={`dates-${_id}`}
                    value={formatDate(dates[_id] * 1000)}
                    onChange={({ target: { value } }) => handle(_id, value)}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <p>
        {displayNotification && (
          <Notification
            message={translations.formatMessage(
              'dash.admin.dates.form.notification.success',
            )}
            timeout={5}
          />
        )}
        {!displayNotification && (
          <button
            className="button is-dark is-small"
            onClick={() => doPut({
              body: { dates, profile },
            })
            }
          >
            {translations.formatMessage('dash.admin.dates.form.cta')}
          </button>
        )}
      </p>
    </>
  );
};

AdminDates.propTypes = propTypes;
AdminDates.defaultProps = defaultProps;

export default AdminDates;
