import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
  useProvidedLanguage,
} from '../../context';
import {
  toLocaleStringOptions,
} from '../../config';
import EvolucionesTable from './Table';
import EvolucionesGraph from './Graph';

const propTypes = {
  data: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
};
const defaultProps = {};

const EvolucionesTabs = (props) => {
  const { data, type } = props;
  const [lang] = useProvidedLanguage();
  const translations = useProvidedTranslations();
  const [tab, setTab] = useState('table');
  const today = (
    new Date().toLocaleDateString(lang, toLocaleStringOptions.basic)
  );

  return (
    <>
      <div className="box box-white">
        <div className="body-box">
          <header className="header-box ">
            <h2>{translations.formatMessage(`dash.evoluciones._${type}.header`, { today })}</h2>
          </header>
          <div className="tabs is-toggle is-toggle-rounded is-centered mt-2">
            <ul>
              {['table', 'graph'].map((element) => (
                <li
                  key={element}
                  className={tab === element ? 'is-active' : ''}>
                    <a onClick={() => setTab(element)}>
                      {translations.formatMessage(`dash.evoluciones.tabs._${element}`)}
                    </a>
                  </li>
              ))}
            </ul>
          </div>
          {tab === 'table' && <EvolucionesTable {...props} data={data[type]} />}
          {tab === 'graph' && <EvolucionesGraph {...props} data={data[type]} />}
        </div>
      </div>
    </>
  );
};

EvolucionesTabs.propTypes = propTypes;
EvolucionesTabs.defaultProps = defaultProps;

export default EvolucionesTabs;
