import ModalBasic from '../../components/Modal/Basic';
import ModalUpgrade from '../../components/Modal/Upgrade';
import AdminModalUpdate from '../../components/Admin/Modal/Update';
import AdminModalMobile from '../../components/Admin/Modal/Mobile';

const modals = {
  basic: {
    Component: ModalBasic,
    containerId: 'main',
  },
  upgrade: {
    Component: ModalUpgrade,
    containerId: 'main',
  },
  'admin-update': {
    Component: AdminModalUpdate,
    containerId: 'main',
  },
  'admin-mobile': {
    Component: AdminModalMobile,
    containerId: 'main',
  },
};

export default modals;
