import React from 'react';
import PropTypes from 'prop-types';
import { useProvidedModal } from '../../../context';

const propTypes = {
  Container: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  containerId: PropTypes.string.isRequired,
  isFullScreen: PropTypes.bool,
};

const defaultProps = {
  isFullScreen: false,
};

const ModalContainerBase = (props) => {
  const { Container, containerId, isFullScreen } = props;
  const [, hideModal, {
    Component,
    contentClassName = 'modal-content',
    id,
    isHiddenOnBlur = true,
    modalClassName,
    params,
  }] = useProvidedModal(containerId);

  if (!id) {
    return null;
  }

  const handleOnClick = () => {
    if (isHiddenOnBlur) {
      hideModal(true);
      if (params.onClose) {
        params.onClose();
      }
    }
  };

  return (
    <div className={`modal is-active is-clipped ${isFullScreen ? 'is-fullscreen' : ''} ${modalClassName}`}>
      <div className="modal-background" style={{ zIndex: 10 }} onClick={handleOnClick} />
      <Container className={contentClassName} onClick={handleOnClick}>
        <Component
          {...params}
          contentClassName={contentClassName}
          fnClose={hideModal}
          handleOnClick={handleOnClick}
        />
      </Container>
    </div>
  );
};

ModalContainerBase.propTypes = propTypes;
ModalContainerBase.defaultProps = defaultProps;

export default ModalContainerBase;
