/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import context from './context';

const propTypes = {
  children: PropTypes.node.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
  }),
  dictionary: PropTypes.shape().isRequired,
  lang: PropTypes.oneOf(['en', 'es']).isRequired,
};

const defaultProps = {
  currency: {
    id: 'EUR',
    rate: 1,
  },
};

const TranslationsProvider = (props) => {
  const {
    children, currency, dictionary, lang,
  } = props;
  const contextValue = useMemo(() => ({ currency }), [currency]);
  return (
    <context.Provider value={contextValue}>
      <IntlProvider locale={lang} messages={dictionary}>
        {children}
      </IntlProvider>
    </context.Provider>

  );
};

TranslationsProvider.propTypes = propTypes;
TranslationsProvider.defaultProps = defaultProps;

export default TranslationsProvider;
