import React from 'react';
import PropTypes from 'prop-types';
import PaginationButton from './Button';

const propTypes = {
  /** Number of neighbours */
  neighbours: PropTypes.number,
  /** Current pagination values */
  pagination: PropTypes.shape({
    /** Documents limit */
    limit: PropTypes.number,
    /** Documents page */
    page: PropTypes.number,
    /** Documents total */
    total: PropTypes.number,
  }),
  /** Function to call when new page selected */
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  neighbours: 1,
  pagination: {
    limit: 0,
    page: 0,
    total: 0,
  },
};

/**
* Helper function to create array [start...end]
* @param {Integer} start - range start
* @param {Integer} end - range end
*/
const generateRangeArray = (start, end) => {
  const rangeArr = [];
  let i = start;

  while (i <= end) {
    rangeArr.push(i);
    i += 1;
  }

  return rangeArr;
};

/** Pagination navigation buttons */
const PaginationNav = (props) => {
  const {
    neighbours,
    onClick,
    pagination: {
      limit,
      page,
      total,
    },
  } = props;
  const totalPages = Math.ceil(total / limit); // Total number of pages

  if (Number.isNaN(totalPages) || totalPages === 1) {
    return null;
  }

  /*
   * Pagination looks like this: (example with neighbours = 1)
   *
   * < (1) ... {5}[6]{8} ... (10) >
   *
   * (x) -> terminal pages: first and last page (always visible)
   * [x] -> current page
   * {x} -> page neighbours
   */

  // First page to appear in pagination numbers
  let startPage = Math.max(1, page - neighbours);
  // Last page to appear in pagination numbers
  let endPage = Math.min(page + neighbours, totalPages);
  startPage = page === totalPages && startPage > 1
    ? startPage -= 1
    : startPage;
  endPage = page === 1 && endPage < totalPages ? endPage += 1 : endPage;
  const paginationRange = generateRangeArray(startPage, endPage);

  const dots = <li><span className="pagination-ellipsis">&hellip;</span></li>;

  return (
    <nav aria-label="pagination" className="pagination is-rounded is-small" role="navigation">
      <ul className="pagination-list">
        <PaginationButton
          isDisabled={page === 1}
          page={page}
          onClick={() => onClick(page - 1)}
        >
          {'<'}
        </PaginationButton>
        {startPage !== 1
        && (
          <PaginationButton
            isDisabled={page === 1}
            isSelected={page === 1}
            page={page}
            onClick={() => onClick(1)}
          >
            1
          </PaginationButton>
        )}
        {startPage > 2 && dots}
        {paginationRange.map((i) => (
          <PaginationButton
            key={i}
            isDisabled={page === i}
            isSelected={page === i}
            page={page}
            onClick={() => onClick(i)}
          >
            {i}
          </PaginationButton>
        ))}
        {endPage < totalPages - 1 && dots}
        {endPage !== totalPages
        && (
          <PaginationButton
            isDisabled={page === totalPages}
            isSelected={page === totalPages}
            page={page}
            onClick={() => onClick(totalPages)}
          >
            {totalPages}
          </PaginationButton>
        )}
        <PaginationButton
          isDisabled={page === totalPages}
          page={page}
          onClick={() => onClick(page + 1)}
        >
          {'>'}
        </PaginationButton>
      </ul>
    </nav>
  );
};

PaginationNav.propTypes = propTypes;
PaginationNav.defaultProps = defaultProps;

export default PaginationNav;
