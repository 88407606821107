import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../../../context';
import Dates from '../Dates';

const propTypes = {
  profile: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    dates: PropTypes.shape({
      otrosPatrimonio: PropTypes.number.isRequired,
      patrimonioFinanciero: PropTypes.number.isRequired,
      resumenPatrimonio: PropTypes.number.isRequired,
    }).isRequired,
    docId: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sub: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

const AdminModalMobile = (props) => {
  const { profile } = props;
  const translations = useProvidedTranslations();
  const [, hideModal] = useProvidedModal();

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {translations.formatMessage('dash.admin.mobile-modal.title')}
          </p>
          <a className="button is-circle-sm" onClick={() => hideModal()}>
            <span className="material-icons">
              close
            </span>
          </a>
        </header>
        <section className="modal-card-body">
          <div className="d-flex">
            <p className="image is-48x48 mr-05">
              <img className="is-rounded" src={profile?.img} />
            </p>
            <p>
              <strong>{profile?.name}</strong><br />{profile?.type}
            </p>
          </div>
          <p className="mt-1">
            <a className="button is-small" rel="noreferrer" href={`https://docs.google.com/spreadsheets/d/${profile?.docId}`} target="_blank">
              {translations.formatMessage('dash.admin-list.open-excel.button')}
            </a>
          </p>
          <hr />
          <Dates {...profile} profile={profile._id} columnsClassName="is-4-tablet" />
        </section>
        <footer className="footer-card">
          <div className="buttons-footer">
            <div className="buttons">
              <button
                className="button is-primary btn-block-xs"
                type="button"
                onClick={() => hideModal()}
              >
                {translations.formatMessage('dash.document-list.modal.close')}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

AdminModalMobile.propTypes = propTypes;

export default AdminModalMobile;
