import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import OtrosPatrimonios from './index';

const OtrosPatrimoniosWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage('dash.otros-patrimonios.header')}>
      <OtrosPatrimonios />
    </LayoutNav>
  );
};

export default OtrosPatrimoniosWithLayout;
