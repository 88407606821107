import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import Debug from 'debug';
import sendRequest from './lib/sendRequest';

const debug = Debug('bulldog:useAPI');

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'fetch-init':
      debug('fetch-init');
      return { ...state, isFetching: true };
    case 'init-loading':
      debug('init-loading');
      return { ...state, isLoading: true };
    case 'fetch-end':
      debug('fetch-end', action);
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        data: action.body,
        error: undefined,
      };
    case 'fetch-error':
      debug('fetch-error', action);
      return {
        ...state,
        isFetching: false,
        isLoading: false,
        data: undefined,
        error: action.err,
      };
    case 'update-data':
      debug('update-data', action);
      return state;
    default:
      throw new Error(`invalid-action: ${action.type}`);
  }
};

const optsReducer = (state, action) => {
  const parsedAction = typeof action === 'function'
    ? action(state)
    : action;
  return ({
    ...state,
    ...parsedAction,
    wait: false,
    dispatchedCount: (state.dispatchedCount || 0) + 1,
  });
};

/**
 * @param {Object} initialOpts - Options passed into sendRequest
 * @param {Boolean} initialOpts.wait - Wait to send request until wait is false
 * @param {Number=1} initialOpts.loadingTimeout -
 *   Seconds to delay isLoading=true state after start to fetch
 */
const useAPI = (initialOpts, onSuccess, onError) => {
  // Request options state
  const [opts, dispatchOpts] = useReducer(optsReducer, initialOpts);

  // We want to avoid re-fetch every time onSuccess/onError changes
  // So we are going to save a reference for each function
  // We will update the ref with a Effect each time onSuccess/onError changes
  // We will add handleSuccess/handleError to call the function
  // that we saved in ref
  // This handlers aren't going to re-define since the invoked
  // function it's on a ref
  // So we will not enter into main effect when onSuccess/onError changes
  const refOnSuccess = useRef(onSuccess);
  const handleSuccess = useCallback((...args) => {
    if (refOnSuccess.current) {
      refOnSuccess.current(...args);
    }
  }, []);

  const refOnError = useRef(onError);
  const handleError = useCallback((...args) => {
    if (refOnError.current) {
      refOnError.current(...args);
    }
  }, []);

  useEffect(() => {
    if (refOnSuccess.current !== onSuccess) {
      refOnSuccess.current = onSuccess;
    }
  }, [onSuccess]);

  useEffect(() => {
    if (refOnError.current !== onError) {
      refOnError.current = onError;
    }
  }, [onError]);

  // request status state (data,loading...)
  const [state, dispatchState] = useReducer(stateReducer, {
    isLoading: !initialOpts.wait,
    isFetching: !initialOpts.wait,
    error: undefined,
    data: undefined,
  });

  useEffect(() => {
    debug('useAPI', opts);

    /*
     * Can’t call useAPI conditionally (because it’s a hook).
     * We add a useEffect with a condition to doFetch only if wait is false
     */
    if (opts.wait) {
      return;
    }

    let isCanceled = false;
    let loadingTimeout;
    const requestWithCancel = async () => {
      // We do not want to set loading immediately to avoid
      // unnecessary re-renders
      loadingTimeout = setTimeout(() => {
        dispatchState({ type: 'init-loading' });
      }, (opts.loadingTimeout || 1) * 1000);
      try {
        dispatchState({ type: 'fetch-init' });
        const [body, statusCode] = await sendRequest(opts);
        clearTimeout(loadingTimeout);// Stop loading
        // If effect has been unmounted
        // we do not dispatch new state
        if (isCanceled) {
          debug('request canceled');
          return;
        }
        dispatchState({ type: 'fetch-end', body });
        handleSuccess(body, statusCode, opts);
      } catch (err) {
        debug('catch err', err);
        clearTimeout(loadingTimeout);// Stop loading
        // If effect has been unmounted
        // we do not dispatch new state
        if (isCanceled) {
          debug('err and canceled');
          return;
        }

        dispatchState({ type: 'fetch-error', err });
        handleError(err);
      }
    };
    requestWithCancel();
    // eslint-disable-next-line consistent-return
    return () => {
      isCanceled = true;
      clearTimeout(loadingTimeout);
    };
  }, [handleError, handleSuccess, opts]);

  // Can call dispatchOpts out of hook
  // in order to change URL, query, method, bearer token...
  return [state, dispatchOpts];
};

export default useAPI;
