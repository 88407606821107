import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../context';
import Link from './Link';

const propTypes = {
  label: PropTypes.string,
  modal: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
  toId: PropTypes.string,
  onClick: PropTypes.func,
  params: PropTypes.shape(),
};
const defaultProps = {
  label: undefined,
  modal: undefined,
  onClick: undefined,
  toId: undefined,
  params: {},
};

const KnowMore = (props) => {
  const {
    label, modal, params, onClick, toId,
  } = props;
  const [displayModal] = useProvidedModal();
  const translations = useProvidedTranslations();
  const handleOnClick = () => {
    if (onClick) {
      return onClick();
    }
    if (modal) {
      return displayModal({
        id: 'basic',
        params: { ...modal },
      });
    }
    return undefined;
  };

  return (
    <Link toId={toId} onClick={handleOnClick} params={params}>
      <span className="is-hidden-mobile">
        {label || translations.formatMessage('dash.know-more')}
      </span>
      {' '}
      <span className="material-icons">keyboard_arrow_right</span>
    </Link>
  );
};

KnowMore.propTypes = propTypes;
KnowMore.defaultProps = defaultProps;

export default KnowMore;
