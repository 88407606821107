/* eslint-disable no-undef */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate as redirectTo } from '@reach/router';
import Debug from 'debug';
import {
  useActiveWindowInterval,
  useAPIAuthorized,
  useAPICache,
  useCache,
} from '../../hooks';
import {
  autoStorageName,
} from '../../config';
import context from './context';
import routes from '../../routes';

const debug = Debug('bulldog:UserInfoProvider');

const propTypes = {
  apiBaseURL: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const cfg = {
  endpoints: {
    auto: 'auto',
    me: 'me',
  },
  refresh: {
    interval: 5 * 60, // 5 mins
  },
};

const UserInfoProvider = (props) => {
  const { apiBaseURL, children } = props;
  const { user: { sub: userId } = {}, isLoading: isLoadingAuth } = useAuth0();
  const [auto, setAuto] = useCache(autoStorageName, null);
  const url = useMemo(() => (auto
    ? `${apiBaseURL}/${cfg.endpoints.auto}/${auto}`
    : `${apiBaseURL}/${cfg.endpoints.me}`
  ), [apiBaseURL, auto]);
  const [resp, doFetch] = useAPICache(
    `user-info-${auto || userId}`,
    cfg.refresh.interval,
    {
      method: 'GET',
      url,
      waitForRefreshCache: !userId,
    },
    null,
    null,
    useAPIAuthorized,
  );
  const { isLoading } = resp || {};

  const refresh = useCallback(() => {
    debug('refresh');
    doFetch({ url });
  }, [doFetch]);

  const setAutoAndRedirect = useCallback(({ sub } = {}) => {
    if (sub) {
      setAuto(sub);
    } else {
      window.localStorage.removeItem(autoStorageName);
    }

    const home = routes.find(({ key }) => key === 'homepage');
    redirectTo(home.path);
    setTimeout(() => {
      window.location.reload();
    }, 850);
  });

  const value = useMemo(
    () => {
      let payload = resp?.data?.data || { isLoading: true };
      if (isLoadingAuth) {
        payload = { isLoading: true };
      }
      return [
        payload.isLoading || { auto, ...payload },
        refresh,
        setAutoAndRedirect,
      ];
    },
    [auto, isLoadingAuth, refresh, resp, userId, setAutoAndRedirect],
  );

  useEffect(() => {
    doFetch({ url });
  }, [auto]);

  useActiveWindowInterval(refresh, cfg.refresh.interval, isLoading);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
};

UserInfoProvider.propTypes = propTypes;

export default UserInfoProvider;
