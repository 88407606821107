import React from 'react';
import PropTypes from 'prop-types';
import {
  useBulldogAPI,
} from '../../hooks';
import DocumentList from '../DocumentList';
import BoxHeroActivoPasivo from '../Box/HeroActivoPasivo';
import KnowMore from '../KnowMore';
import {
  numberFormat,
  toLocaleStringOptions,
} from '../../config';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  i18nKey: 'dash.prestamos',
};

const capitalSRC = (data, w, key) => (
  // eslint-disable-next-line no-nested-ternary
  data?.capital?.[key]
    ? data?.capital?.[key]?.[w]
    : (w === 'value' ? '0€' : 0)
);

const params = {
  ...cfg,
  defaults: [
    'deudor',
    'concesion',
    'vencimiento',
    'capital-prestado',
    'capital-devuelto',
    'capital-pendiente',
    'intereses',
    'intereses-cobrados',
    'comentario'],
  endPoint: 'prestamos',
  resources: [
    {
      id: 'deudor',
      className: 'sticky-col first-col',
      src: (data) => data?.deudor,
      disabled: true,
    },
    {
      id: 'concesion',
      src: (data, w, translations) => (data?.concesion
        ? translations.formatUnixDate(
          data?.concesion,
          toLocaleStringOptions.fullYear,
        )
        : '_'
      ),
    },
    {
      id: 'vencimiento',
      src: (data, w, translations) => (data?.vencimiento
        ? translations.formatUnixDate(
          data?.vencimiento,
          toLocaleStringOptions.fullYear,
        )
        : '_'
      ),
    },
    {
      id: 'capital-prestado',
      src: (data, w = 'value') => capitalSRC(data, w, 'prestado'),
      num: true,
    },
    {
      id: 'capital-devuelto',
      src: (data, w = 'value') => capitalSRC(data, w, 'devuelto'),
      num: true,
    },
    {
      id: 'capital-pendiente',
      src: (data, w = 'value', translations) => {
        const pendiente = (
          capitalSRC(data, 'raw', 'prestado')
          - capitalSRC(data, 'raw', 'devuelto')
        );
        return w === 'value'
          ? `${translations.formatNumber(pendiente, numberFormat)}€`
          : pendiente;
      },
      num: true,
    },
    {
      id: 'intereses',
      src: (data) => `${data?.interes}%`,
    },
    {
      id: 'intereses-cobrados',
      src: (data, w = 'value') => (
        // eslint-disable-next-line no-nested-ternary
        data?.interesesCobrados
          ? data?.interesesCobrados?.[w]
          : (w === 'value' ? '0€' : 0)
      ),
      num: true,
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentario) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: data?.deudor,
              body: data?.comentario,
            }}
          />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const Prestamos = (props) => {
  const { qs } = props;
  const [{
    data: { data: totals = {} } = {},
    isLoading: isLoadingTotals,
    isFetching: isFetchingTotals,
  }] = useBulldogAPI('prestamos', { tmp: undefined, ...qs }, 'totals');

  return (
    <>
      <BoxHeroActivoPasivo
        totals={totals}
        isLoading={isLoadingTotals || isFetchingTotals}
      />
      <div className="box box-white">
        <DocumentList
          {...params}
          qs={{ ...(params.qs ? params.qs : {}), ...qs }}
        />
      </div>
    </>
  );
};

Prestamos.defaultProps = defaultProps;
Prestamos.propTypes = propTypes;

export default Prestamos;
