/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import routes from '../routes';
import Loading from './Loading';
import Login from './Login';
import { usePageTracking } from '../hooks';

const Container = ({ children }) => {
  usePageTracking();

  return <>{children}</>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const Routes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading display />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <Router>
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.key}
            children={() => (
              <Container>
                <route.Component />
              </Container>
            )}
            exact={route.exact}
            path={route.path}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default Routes;
