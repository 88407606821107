import { useReducer } from 'react';
import Debug from 'debug';
import parseAPIQuery from './lib/parseAPIQuery';

const debug = Debug('bulldog:useAPIQuery');

const reduceQuery = (oldQuery = {}, newQuery) => {
  const { resetQuery, ...newQueryToParse } = newQuery;
  if (newQueryToParse === 'reset') {
    return {};
  }

  const parsedNewQuery = parseAPIQuery(newQueryToParse);
  debug('reduceQuery', parsedNewQuery);
  const {
    limit, offset, order, ...filters
  } = parsedNewQuery;

  // On reset query we want to keep the old order and remove old filters
  if (resetQuery) {
    const { order: oldOrder } = oldQuery;
    return { order: oldOrder, ...parsedNewQuery };
  }

  // If we are  updating filters we reset offset
  if (Object.keys(filters).length) {
    const {
      offset: oldOffset, ...oldFilters
    } = oldQuery;
    return { ...oldFilters, ...parsedNewQuery };
  }

  // If we are sorting or changing page limit we have to preserv
  if (order || limit) {
    const {
      offset: oldOffset, ...oldFilters
    } = oldQuery;
    return {
      ...oldFilters,
      ...(order ? { order } : {}),
      ...(limit ? { limit } : {}),
    };
  }

  // Update offset

  if (offset || offset === 0) {
    return { ...oldQuery, offset };
  }

  return { ...oldQuery };
};

const parseQueryAfterReducer = (oldQuery, newQueryToParse) => {
  const newQuery = reduceQuery(oldQuery, newQueryToParse);
  const parsedQuery = Object.assign({}, ...Object.entries(newQuery)
    .filter(([, value]) => value !== '')
    .map(([key, value]) => ({ [key]: value })));
  debug('parsedQuery', parsedQuery);
  return parsedQuery;
};

/**
 * Hook to handle TB API queries
 * @param {Object} qs - Initial qs
 * @returns [query,dispatchQuery] Query status
 *  and function to dispatch new params
 */
const useAPIQuery = (qs) => useReducer(parseQueryAfterReducer, qs);

export default useAPIQuery;
