// https://gist.github.com/tdukart/b87afb278c41245741ae7a0c355a0a0b
/**
 * Given a string, converts it to kebab case (lowercase, hyphen-separated
 * "makeFoo" becomes "make-foo", and "a Multi Word string" becomes
 * @param {string} input - Your input string.
 * @returns {string} Kebab-cased string.
 */
const toKebabCase = (input, keepSymbols = true) => {
  const lowerCasedInput = input
    .replace(/([a-z])([A-Z])/g, (match, group1, group2) => `${group1}-${group2}`)
    .toLowerCase(); // Convert non-camelCase capitals to lowercase.

  return (keepSymbols
    ? lowerCasedInput
    : lowerCasedInput.replace(/[^-a-z0-9]+/g, '-') // Convert non-alphanumeric characters to hyphens
  ).replace(/^-+|-+$/g, '');
};

export default toKebabCase;
