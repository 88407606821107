import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';
import { useBulldogAPI } from '../../hooks';
import BoxHeroActivoPasivo from '../Box/HeroActivoPasivo';
import BoxSinglePopover from '../Box/SinglePopover';
import LoadingSpinner from '../LoadingSpinner';
import InmueblesListado from './Listado';
import InmueblesIngresosGastos from './IngresosGastos';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const cfg = {
  menu: [
    { id: 'listado', Component: InmueblesListado },
    { id: 'ingresos-gastos', Component: InmueblesIngresosGastos },
  ],
};

const Inmuebles = (props) => {
  const { qs } = props;
  const translations = useProvidedTranslations();
  const [tab, setTab] = useState(cfg.menu[0].id);
  const [{
    data: { data: totals = {} } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('inmuebles', { tmp: undefined, ...qs }, 'totals');
  const Component = useMemo(() => {
    const MenuTab = cfg.menu.find(
      (element) => element.id === tab,
    );
    if (!MenuTab) {
      return cfg.menu[0].Component;
    }
    return MenuTab.Component;
  }, [tab]);
  const loading = isLoading || isFetching;

  return (
    <>
      <BoxHeroActivoPasivo
        totals={totals || {}}
        i18nKey="hero-activo-pasivo-inmobiliarios"
        isLoading={loading}
        Pasivos={
          <BoxSinglePopover
            className="is-danger"
            label={translations.formatMessage('dash.box.hero-activo-pasivo-inmobiliarios.pasivos')}
            value={translations.formatMessage('dash.box.hero-activo-pasivo-inmobiliarios.pasivos.tootip')}
            />
        }
      />
      <div className="box box-white">
        <div className="body-box">
          <div className="tabs is-toggle is-toggle-rounded is-centered mt-2">
            <ul>
              {!loading && cfg.menu
                .map(({ id }) => (
                <li key={id} className={`${id === tab ? 'is-active' : ''}`}>
                  <a onClick={() => setTab(id)}>
                    {translations.formatMessage(`dash.inmueble.tabs._${id}`)}
                  </a>
                </li>
                ))
                }
            </ul>
          </div>
            {loading
              ? <LoadingSpinner />
              : <Component i18nKey="dash.inmuebles" qs={qs} {...props} />
            }
          </div>
        </div>
    </>
  );
};

Inmuebles.defaultProps = defaultProps;
Inmuebles.propTypes = propTypes;

export default Inmuebles;
