/* eslint-disable max-len */
import React from 'react';
import {
  useBulldogAPI,
} from '../../hooks';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import LoadingSpinner from '../LoadingSpinner';
import EvolucionesTabs from './Tabs';

const cfg = {
  resources: {
    salarios: [
      {
        id: 'fecha',
        src: (data) => {
          const [start] = data?.fecha.split('-');

          return {
            value: `20${start}`,
            raw: `20${start}`,
          };
        },
      },
      { id: 'brutos' },
      { id: 'netos' },
    ],
    patrimonio: [
      {
        id: 'fecha',
        src: (data) => ({
          value: `${new Date(data?.fecha * 1000).getFullYear()}`,
          raw: `${new Date(data?.fecha * 1000).getFullYear()}`,
        }),
      },
      { id: 'total' },
      { id: 'bancario' },
      { id: 'inmuebles' },
      { id: 'sociedades' },
      { id: 'prestamos' },
      { id: 'otro' },
    ],
  },
};

const Evoluciones = () => {
  const translations = useProvidedTranslations();
  const [{
    data: { data: dataIngresos = [] } = {},
    isLoading: isLoadingIngresos,
    isFetching: isFetchingIngresos,
  }] = useBulldogAPI('evolucion-ingresos', { tmp: undefined });
  const [{
    data: { data: dataPatrimonio = [] } = {},
    isLoading: isLoadingPatrimonio,
    isFetching: isFetchingPatrimonio,
  }] = useBulldogAPI('evolucion-patrimonio', { tmp: undefined });
  const loading = isLoadingIngresos || isFetchingIngresos || isLoadingPatrimonio || isFetchingPatrimonio;

  return (
    <LayoutNav title={translations.formatMessage('dash.evoluciones.header')}>
      {loading && <LoadingSpinner />}
      {!loading && (
        Object.keys(cfg.resources).map((type) => (
          <EvolucionesTabs
            key={type}
            data={{ salarios: dataIngresos, patrimonio: dataPatrimonio } || []}
            resources={cfg.resources[type]}
            type={type}
          />
        )))}
    </LayoutNav>
  );
};

export default Evoluciones;
