/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import {
  useProvidedConfiguration,
  useProvidedLanguage,
  useProvidedModal,
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
} from '../../hooks';
import KnowMore from '../KnowMore';
import LoadingSpinner from '../LoadingSpinner';
import EscudosEquipos from '../EscudosEquipos';
import formatDateFullYear from '../../lib/formatDateFullYear';

const cfg = {
  basics: [
    {
      id: 'contractuales',
      Component: ({ data }) => <>{data?.temporadas?.contractuales}</>,
    },
    {
      id: 'opcional',
      Component: ({ data, translations }) => (
        <>
          {translations.formatMessage(`dash.contrato.caracteristicas.datos-basicos.cesion._${data?.temporadas?.opcional > 0 ? 'yes' : 'no'}`)}
        </>
      ),
    },
    {
      id: 'clausulas-rescision',
      Component: ({ data, handleDisplayModal, translations }) => (
        <KnowMore
          onClick={() => handleDisplayModal({
            body: data?.clausulas?.rescision?.text.join('\n'),
            title: translations.formatMessage('dash.contrato.caracteristicas.clausula-rescision.title'),
          })}
        />
      ),
    },
    {
      id: 'clausulas-seguimiento',
      Component: ({ data, handleDisplayModal, translations }) => (
        <KnowMore
          onClick={() => handleDisplayModal({
            body: data?.clausulas?.seguimiento?.text.join('\n'),
            title: translations.formatMessage('dash.contrato.caracteristicas.clausula-seguimiento.title'),
          })}
        />
      ),
    },
    {
      id: 'cesion',
      Component: ({ data, translations }) => (
        <>
          {translations.formatMessage(`dash.contrato.caracteristicas.datos-basicos.cesion._${data?.cesion ? 'yes' : 'no'}`)}
        </>
      ),
    },
    {
      id: 'calendario',
      Component: () => <KnowMore toId="caracteristias-contratos" params={{ tab: 'calendario' }} />,
    },
  ],
};

const ContratoCaracteristicas = () => {
  const translations = useProvidedTranslations();
  const [lang] = useProvidedLanguage();
  const [{ data: { bgequipos, tmp } = {} }] = useProvidedConfiguration();
  const [{
    data: { data = [] } = {},
    isFetching,
    isLoading,
  }] = useBulldogAPI('caracteristias-contratos', { tmp: undefined });
  const [displayModal] = useProvidedModal();
  const handleDisplayModal = (params) => {
    displayModal({
      id: 'basic',
      params,
    });
  };
  const temporada = useMemo(() => data.find((element) => element.tmp === tmp),
    [data, tmp]) || {};
  const { date: { since, until } = {} } = temporada;
  const loading = isLoading || isFetching;

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && <>
        <header className={`header-box ${bgequipos[temporada?.equipo] || `bg-${temporada?.equipo?.replace(/ /g, '-').toLowerCase()}`}`}>
          <div className="d-flex justify-center">
            <figure className="mr-1">
              <EscudosEquipos name={temporada?.equipo || ''} className="escudo" />
            </figure>
            <div className="equipo-ficha">
              <h1 className="equipo">{temporada?.equipo}</h1>
              <div className="d-flex">
                <p>{since && formatDateFullYear(since * 1000, lang)}</p>
                <p>{' - '}</p>
                <p>{until && formatDateFullYear(until * 1000, lang)}</p>
              </div>
            </div>
          </div>
        </header>
        <div className="body-box">
          <div className="columns is-variable is-6-desktop is-4-tablet">
            <div className="column is-6-tablet">
              <h2>{translations.formatMessage('dash.contrato.caracteristicas.datos-basicos')}</h2>
              <table className="table is-striped is-fullwidth mt-1">
                <tbody>
                  {cfg.basics.map(({ Component, id }) => (
                    <tr key={id}>
                      <th className="text-regular">
                        {translations.formatMessage(`dash.contrato.caracteristicas.datos-basicos.th._${id}`)}
                      </th>
                      <td className="has-text-right">
                        <Component
                          data={temporada}
                          handleDisplayModal={handleDisplayModal}
                          translations={translations}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="column is-6-tablet">
              <h2>{translations.formatMessage('dash.contrato.caracteristicas.temporadas')}</h2>
              <div className="v-scroll mt-1">
                <table className="table is-striped is-fullwidth">
                  <tbody>
                    {data
                      .sort(({ order: orderA }, { order: orderB }) => (
                        orderA - orderB))
                      .map((element) => (
                        <tr key={element.id}>
                          <td>{translations.formatMessage('dash.contrato.caracteristicas.listado-temporadas', element)}</td>
                          <td className="has-text-right">
                            <KnowMore
                              toId="resumen-temporada"
                              params={{ tmp: element.tmp }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
};

export default ContratoCaracteristicas;
