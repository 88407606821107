import React from 'react';
import {
  useProvidedModal,
  useProvidedTranslations,
} from '../../context';
import {
  contactUs,
} from '../../config';

const ModalBasic = () => {
  const [, hideModal] = useProvidedModal();
  const translations = useProvidedTranslations();

  return (
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{translations.formatMessage('dash.modal.upgrade.header')}</p>
        <a className="button is-circle is-circle-sm">
          <span className="material-icons" onClick={() => hideModal()}>
            close
            </span>
          </a>
      </header>
      <section className="modal-card-body">
        <h1>{translations.formatMessage('dash.modal.upgrade.body.h1')}</h1>
        <p className="lead mt-1">
          {translations.formatMessage('dash.modal.upgrade.body.lead')}
        </p>
        <p className="mt-1">
          {translations.formatMessage('dash.modal.upgrade.body.p')}
        </p>
      </section>
      <footer className="modal-card-foot">
        <a className="button is-primary" href={`mailto:${contactUs}`} target="_blank" rel="noreferrer">
          {translations.formatMessage('dash.modal.upgrade.cta')}
        </a>
      </footer>
    </div>
  );
};

export default ModalBasic;
