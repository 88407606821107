import React from 'react';
import PropTypes from 'prop-types';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPI,
  useUpdatedDate,
} from '../../hooks';
import BoxHeroActivoPasivo from '../Box/HeroActivoPasivo';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import LoadingSpinner from '../LoadingSpinner';
import BancosChart from './Chart';
import BancosImageProfile from './ImageProfile';
import Link from '../Link';
import formatDateFullYear from '../../lib/formatDateFullYear';

const propTypes = {
  detallesToId: PropTypes.string,
  qs: PropTypes.shape(),
};

const defaultProps = {
  detallesToId: 'detalles-bancos',
  qs: {},
};

const params = {
  defaults: [
    'banco',
    'activos-corrientes',
    'activos-valores',
    'activos-fondos',
    'activos-etfs',
    'activos-pensiones',
    'activos-otros',
    'activos-total',
    'total-pasivos',
    'total-neto',
    'detalles',
    'comentarios',
  ],
  endPoint: 'bancos',
  i18nKey: 'dash.entidades-financieras',
  resources: [
    {
      id: 'banco',
      src: (data) => (
        <BancosImageProfile
          banco={data}
        />
      ),
      className: 'sticky-col first-col',
      disabled: true,
    },
    {
      id: 'activos-corrientes',
      src: (data, w = 'value') => data?.activos?.corrientes?.[w],
      num: true,
    },
    {
      id: 'activos-valores',
      src: (data, w = 'value') => data?.activos?.valores?.[w],
      num: true,
    },
    {
      id: 'activos-fondos',
      src: (data, w = 'value') => data?.activos?.fondos?.[w],
      num: true,
    },
    {
      id: 'activos-etfs',
      src: (data, w = 'value') => data?.activos?.etfs?.[w],
      num: true,
    },
    {
      id: 'activos-otros',
      src: (data, w = 'value') => data?.activos?.otros?.[w],
      num: true,
    },
    {
      id: 'activos-pensiones',
      src: (data, w = 'value') => data?.activos?.pensiones?.[w],
      num: true,
    },
    {
      id: 'activos-total',
      src: (data, w = 'value') => data?.activos?.total?.[w],
      num: true,
    },
    {
      id: 'total-bruto',
      src: (data, w = 'value') => data?.total?.bruto?.[w],
      num: true,
    },
    {
      id: 'total-pasivos',
      src: (data, w = 'value') => data?.pasivos?.[w],
      num: true,
    },
    {
      id: 'total-neto',
      src: (data, w = 'value') => data?.total?.neto?.[w],
      num: true,
    },
    {
      id: 'email-contacto',
      src: (data) => data?.emailContacto,
    },
    {
      id: 'telefono-contacto',
      src: (data) => data?.telefonoContacto,
    },
    {
      id: 'oficina',
      src: (data) => data?.oficina,
    },
    {
      id: 'direccion-postal',
      src: (data) => data?.direccionPostalPropia,
    },
    {
      id: 'persona-contacto',
      src: (data) => data?.personaContacto,
    },
    {
      id: 'date',
      src: (data) => {
        const date = data?.date;
        return date
          ? formatDateFullYear(date * 1000)
          : null;
      },
    },
    {
      id: 'detalles',
      className: 'has-text-centered',
      src: (data, w, translations, { detallesToId, sociedad }) => (
          <Link
            className="button is-primary is-outlined is-small"
            params={{ id: data.id, sociedad }}
            toId={detallesToId}
          >
            {translations.formatMessage('dash.entidades-financieras.ver-detalles')}
          </Link>
      ),
    },
    {
      id: 'comentarios',
      src: (data) => {
        if (!data?.comentarios) {
          return '-';
        }

        return (
          <KnowMore
            modal={{
              title: data?.banco,
              body: data?.comentarios,
            }}
            />
        );
      },
    },
  ],
  qs: { tmp: undefined },
};

const reducer = (data = [], src) => data.reduce((accumulator, element) => {
  const currentValue = src(element, 'raw') || 0;
  return accumulator + Math.abs(currentValue);
}, 0);

const getActivosChartData = ({
  data, translations, w, payload,
}) => {
  if (!data.length) {
    return [];
  }

  const arr = [
    [
      translations.formatMessage(`dash.entidades-financieras.chart._${w}`),
      '',
    ],
  ];
  payload.forEach((element) => {
    const find = params.resources.find(({ id }) => id === element);
    const src = find
      ? find.src
      : (rows) => rows?.activos?.[element]?.raw;

    arr.push([
      translations.formatMessage(`dash.entidades-financieras.resources._${element}`),
      reducer(data, src),
    ]);
  });
  return arr;
};

const getTotalsChartData = ({
  data, translations, w, src,
}) => {
  if (!data.length) {
    return [];
  }

  const arr = [
    [
      translations.formatMessage(`dash.entidades-financieras.chart._${w}`),
      '',
    ],
  ];
  data.forEach(({ banco, ...element }) => {
    arr.push([banco, src(element)]);
  });
  return arr;
};

const cfg = {
  charts: [
    [
      {
        _id: 'activos',
        chartData: (props) => getActivosChartData({
          ...props,
          w: 'activos',
          payload: [
            'activos-corrientes',
            'activos-etfs',
            'activos-fondos',
            'activos-otros',
            'activos-pensiones',
            'activos-valores',
          ],
        }),
      },
      {
        _id: 'solvencia-financiera',
        chartData: (props) => {
          const { translations } = props;
          const data = getActivosChartData({
            ...props,
            w: 'activos',
            payload: [
              'activos-total',
              'total-pasivos',
            ],
          });
          // WHY: https://trello.com/c/5SVG3Z2W/92-bancos-modificar-f%C3%B3rmula-solvencia-financiera
          const [legend, activos, pasivos, ...rest] = data;
          const precentPasivos = (
            Math.floor((pasivos[1] * 100) / (activos[1] + pasivos[1]))
          );
          return [
            [...legend, { type: 'string', role: 'tooltip' }],
            [activos[0], 100 - precentPasivos, `${activos[0]}: ${translations.formatNumber(activos[1])}`],
            [pasivos[0], precentPasivos, `${pasivos[0]}: ${translations.formatNumber(pasivos[1])}`],
            ...rest,
          ];
        },
      },
    ],
    [
      {
        _id: 'distribucion-activos',
        chartData: (props) => getTotalsChartData({
          ...props,
          w: 'distribucion-activos',
          src: (element) => element?.activos?.total?.raw,
        }),
      },
      {
        _id: 'distribucion-pasivos',
        chartData: (props) => getTotalsChartData({
          ...props,
          w: 'distribucion-pasivos',
          src: (element) => Math.abs(element?.pasivos?.raw || 0),
        }),
      },
    ],
  ],
};

const Bancos = (props) => {
  const { detallesToId, qs } = props;
  const { sociedad } = useParams();
  const translations = useProvidedTranslations();
  const [{
    data: { data = [] } = {},
    isLoading: isLoadingData,
    isFetching: isFetchingData,
  }] = useBulldogAPI('bancos', { tmp: undefined, ...qs });
  const [{
    data: { data: totals = {} } = {},
    isLoading: isLoadingTotals,
    isFetching: isFetchingTotals,
  }] = useBulldogAPI('bancos', { tmp: undefined, ...qs }, 'totals');
  const loading = isLoadingData || isFetchingData;
  const date = useUpdatedDate('patrimonioFinanciero');

  return (
    <>
      {loading && <LoadingSpinner />}
      <BoxHeroActivoPasivo
        totals={totals}
        i18nKey="hero-activo-pasivo-financieros"
        isLoading={isLoadingTotals || isFetchingTotals}
      />
      <div className="box box-white">
        <DocumentList
          {...params}
          qs={{ ...(params.qs ? params.qs : {}), ...qs }}
          detallesToId={detallesToId}
          sociedad={sociedad}
          title={translations.formatMessage('dash.entidades-financieras.h3', { date })}
        />
      </div>
      <div className=" columns is-multiline">
        {cfg.charts.map((charts, id) => (
          <BancosChart charts={charts} data={data} key={id} />
        ))}
      </div>
    </>
  );
};

Bancos.defaultProps = defaultProps;
Bancos.propTypes = propTypes;

export default Bancos;
