import React from 'react';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import formatDateFullYear from '../../lib/formatDateFullYear';

const params = {
  defaults: [
    'name',
    'tmp',
    'target',
    'cumplimiento',
    'importe',
    'devengo',
    'cobro',
    'comentario',
  ],
  endPoint: 'primas-individuales',
  i18nKey: 'dash.cuadro-primas',
  resources: [
    {
      id: 'name',
      className: 'sticky-col first-col',
      src: (data) => data?.name,
    },
    {
      id: 'tmp',
      src: (data) => data?.tmp,
    },
    {
      id: 'target',
      src: (data) => data?.target,
    },
    {
      id: 'avance',
      src: (data) => data?.status,
    },
    {
      id: 'cumplimiento',
      className: 'has-text-right',
      src: (data) => {
        const percent = parseFloat(
          data?.cumplimiento
            .replace('%', '')
            .replace(',', '.'),
        );

        return (
          <div className="d-flex">
            <progress className="progress is-small" value={percent} max="100">{percent}%</progress>
            <span className="ml-05 has-text-right">{percent}%</span>
          </div>
        );
      },
    },
    {
      id: 'importe',
      src: (data, w = 'value') => data?.importe?.[w],
      num: true,
    },
    {
      id: 'devengo',
      src: (data) => (
        formatDateFullYear(data?.devengo * 1000)
      ),
    },
    {
      id: 'cobro',
      src: (data) => (
        formatDateFullYear(data?.cobro * 1000)
      ),
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentarios) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: data?.name,
              body: data?.comentarios,
            }}
          />
        );
      },
    },
  ],
  Header: () => null,
};

const propTypes = {
  tmp: PropTypes.string.isRequired,
};

const TemporadaCuadroPrimas = (props) => {
  const { tmp } = props;

  return <DocumentList {...params} qs={{ tmp }} />;
};

TemporadaCuadroPrimas.propTypes = propTypes;

export default TemporadaCuadroPrimas;
