import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import {
  useProvidedModal,
  useProvidedTranslations,
  useProvidedUserInfo,
} from '../../context';
import { useAPIAuthorized } from '../../hooks';
import LoadingSpinner from '../LoadingSpinner';
import Pagination from '../Pagination';
import LayoutNav from '../LayoutNav';
import { apiBaseURL } from '../../config';
import Dates from './Dates';

const cfg = {
  i18nKey: 'dash.admin-list',
  resources: [
    {
      id: 'profile',
      className: '',
      display: true,
      src: (
        data, _translations, _openUpdateModal, _openMobileModal, saveAuto,
      ) => (
        <>
          <div className='d-flex'>
            <p className="image is-48x48 mr-05">
              <img className="is-rounded" src={data?.img} />
            </p>
            <span
              dangerouslySetInnerHTML={{
                __html: data?.name.replace(' ', '<br />'),
              }}
            />
          </div>
          {data?.type === 'player' && (
            <button
              onClick={() => saveAuto({ sub: data?.sub })}
              className="button is-small is-warning mt-1">
                auto
              </button>
          )}
        </>
      ),
    },
    {
      id: 'type',
      className: 'is-hidden-touch',
      display: true,
      src: (data) => data?.type,
    },
    {
      id: 'excel',
      className: 'is-hidden-touch',
      display: true,
      src: (data, translations) => (
        <a
          rel="noreferrer"
          href={`https://docs.google.com/spreadsheets/d/${data?.docId}`}
          target="_blank"
        >
          {translations.formatMessage(`${cfg.i18nKey}.open-excel.button`)}
        </a>
      ),
    },
    {
      id: 'dates',
      className: 'is-multiline is-hidden-touch',
      display: true,
      src: (data) => (
        <Dates
          dates={data.dates}
          profile={data._id}
          sub={data.sub}
        />
      ),
    },
    {
      id: 'update',
      className: 'has-text-right',
      display: true,
      displayHeader: false,
      src: (data, translations, openUpdateModal, openMobileModal) => (
        <>
          <a
            className="primary is-hidden-touch"
            type="button"
            onClick={() => (
              openUpdateModal(data?.permissions, data?.docId, data?.type)
            )}
          >
            {translations.formatMessage(`${cfg.i18nKey}.update.button`)}
          </a>
          <a
            className="button is-hidden-desktop"
            type="button"
            onClick={() => (
              openUpdateModal(data?.permissions, data?.docId, data?.type)
            )}
          >
            <span className="material-icons">update</span>
          </a>
          <a
            type="button"
            className="button is-hidden-desktop"
            onClick={() => openMobileModal(data)}
            >
            <span className="material-icons">edit</span>
          </a>
        </>
      ),
    },
  ],
};

const Cover = ({ children }) => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage(`${cfg.i18nKey}.header`)}>
      <div className="box box-white">{children}</div>
    </LayoutNav>
  );
};

Cover.propTypes = {
  children: PropTypes.node.isRequired,
};

const Admin = () => {
  const translations = useProvidedTranslations();
  const [
    { data: { data = [], pagination } = {}, isFetching, isLoading },
    doFetch,
  ] = useAPIAuthorized({
    method: 'GET',
    url: `${apiBaseURL}/admin/users`,
  });
  const [displayModal] = useProvidedModal();
  const openUpdateModal = useCallback((resources, docId, profileType) => {
    displayModal({
      id: 'admin-update',
      params: {
        docId,
        profileType,
        resources,
      },
    });
  }, []);
  const openMobileModal = useCallback((profile) => {
    displayModal({
      id: 'admin-mobile',
      params: {
        profile,
      },
    });
  }, []);
  const [, , saveAuto] = useProvidedUserInfo();

  if (isLoading || isFetching) {
    return (
      <Cover>
        <LoadingSpinner />
      </Cover>
    );
  }

  if (data.length === 0) {
    return (
      <Cover>
        <div className="notification is-warning">
          {translations.formatMessage('dash.document-list.empty-result')}
        </div>
      </Cover>
    );
  }

  return (
    <Cover>
      {data.map((user) => (
        <div className="body-box b-0 mb-2" key={user.sub}>
          <div className="table-container">
            <table className="table is-fullwidth admin">
              <thead>
                <tr>
                  {cfg.resources
                    .filter(({ display }) => display)
                    .map(({ displayHeader = true, id, className = 'has-text-right' }) => (
                      <th className={className} key={id}>
                        {displayHeader && translations.formatMessage(
                          `${cfg.i18nKey}.resources._${id}`,
                        )}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {user.profiles.map((row) => (
                  <tr key={row.name}>
                    {cfg.resources
                      .filter(({ display }) => display)
                      .map(
                        ({
                          id,
                          className = 'has-text-right',
                          src = () => '_',
                        }) => (
                          <td className={className} key={id}>
                            {src({ ...row, sub: user.sub }, translations, openUpdateModal, openMobileModal, saveAuto) || '-'}
                          </td>
                        ),
                      )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
      <Pagination
        pagination={pagination}
        onClick={(page) => doFetch({ qs: { page } })}
      />
    </Cover>
  );
};

export default Admin;
