import { useContext } from 'react';
import context from './context';

const useProvidedLoading = () => {
  const loadingContext = useContext(context);
  if (!loadingContext) {
    throw new Error('loading context its mandatory');
  }

  return loadingContext;
};

export default useProvidedLoading;
