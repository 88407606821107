import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../../context';
import {
  useBulldogAPI,
} from '../../../hooks';
import LoadingSpinner from '../../LoadingSpinner';
import KnowMore from '../../KnowMore';
import TemporadaDatosEconomicosTable from './Table';

const cfg = {
  weight: {
    champions: 80,
    convocatorias: 30,
    copa: 60,
    europa: 70,
    goles: 20,
    liga: 50,
    otras: 40,
    partidos: 10,
  },
};

const propTypes = {
  tmp: PropTypes.string.isRequired,
};

const TemporadaDatosEconomicos = (props) => {
  const { tmp } = props;
  const [{
    isLoading,
    isFetching,
    data: {
      data: [data = {}] = [],
    } = {},
  }] = useBulldogAPI('datos-economicos-temporadas', { tmp });
  const { primas = {}, ...datosEconomicosTemporadas } = data;
  const translations = useProvidedTranslations();
  const loading = (
    isLoading
    || isFetching
    || !Object.keys(datosEconomicosTemporadas).length
  );
  const getPrimas = useCallback((type) => {
    if (!primas || !primas[type]) {
      return [];
    }

    const body = [];
    Object.keys(primas[type])
      .map((prima) => ({ key: prima, weight: cfg.weight[prima] || 999 }))
      .sort(({ weight: wA }, { weight: wB }) => wA - wB)
      .forEach(({ key }) => {
        body.push({
          label: translations.formatMessage(`dash.temporada.primas._${key}`),
          value: (
          <KnowMore
            modal={{
              body: primas[type][key],
              title: (
                `${translations.formatMessage(`dash.temporada.datos-economicos.primas-${type}`)}: ${translations.formatMessage(`dash.temporada.primas._${key}`)}`
              ),
            }}
            />
          ),
        });
      });
    return body;
  }, [primas]);
  const primasIndividual = useMemo(() => getPrimas('individual'), [primas.individual]);
  const primasColectiva = useMemo(() => getPrimas('colectiva'), [primas.colectiva]);

  if (loading) {
    return (
      <div className="columns is-multiline is-variable is-6-desktop is-4-tablet">
        <div className="column is-12-desktop is-12-tablet">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="body-box b-0">
      <div className="columns is-multiline is-variable is-6-desktop is-4-tablet">
        <div className="column is-6-desktop is-12-tablet">
          <TemporadaDatosEconomicosTable
            body={[
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.salario-fijo.header.concepto'),
                extra: translations.formatMessage('dash.temporada.datos-economicos.salario-fijo.header.numero'),
                value: translations.formatMessage('dash.temporada.datos-economicos.salario-fijo.header.bruto'),
                neto: translations.formatMessage('dash.temporada.datos-economicos.salario-fijo.header.neto'),
                isBold: true,
              },
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.total'),
                value: datosEconomicosTemporadas?.salario?.total?.bruto?.value,
                neto: datosEconomicosTemporadas?.salario?.total?.neto?.value,
                isBold: true,
              },
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.nominas'),
                extra: datosEconomicosTemporadas?.salario?.nominas?.number || '-',
                value: (
                  datosEconomicosTemporadas?.salario?.nominas?.bruto?.value
                ),
                neto: datosEconomicosTemporadas?.salario?.nominas?.neto?.value,
              },
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.prima-fichaje'),
                extra: datosEconomicosTemporadas?.plazosFicha || '-',
                value: datosEconomicosTemporadas?.salario?.primas?.bruto?.value,
                neto: datosEconomicosTemporadas?.salario?.primas?.neto?.value,
              },
            ]}
            title={translations.formatMessage('dash.temporada.datos-economicos.salario-fijo')}
            isStriped={false}
          />
          <TemporadaDatosEconomicosTable
            body={primasIndividual}
            title={translations.formatMessage('dash.temporada.datos-economicos.primas-individual')}
          />
        </div>
        <div className="column is-6-desktop">
          <TemporadaDatosEconomicosTable
            body={primasColectiva}
            title={translations.formatMessage('dash.temporada.datos-economicos.primas-colectiva')}
          />
          <TemporadaDatosEconomicosTable
            body={[
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.variables.retribuciones-especie'),
                value: (
                  <KnowMore
                    modal={{
                      body: datosEconomicosTemporadas?.retribucionsEspecies,
                      title: translations.formatMessage('dash.temporada.datos-economicos.variables.retribuciones-especie'),
                    }}
                  />
                ),
              },
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.variables.reduccion-descenso'),
                value: (
                  <KnowMore
                    modal={{
                      body: datosEconomicosTemporadas?.reduccionDescenso,
                      title: translations.formatMessage('dash.temporada.datos-economicos.variables.reduccion-descenso'),
                    }}
                  />
                ),
              },
              {
                label: translations.formatMessage('dash.temporada.datos-economicos.variables.aumento-ascenso'),
                value: (
                  <KnowMore
                    modal={{
                      body: datosEconomicosTemporadas?.aumentoAscenso,
                      title: translations.formatMessage('dash.temporada.datos-economicos.variables.aumento-ascenso'),
                    }}
                  />
                ),
              },
            ]}
            title={translations.formatMessage('dash.temporada.datos-economicos.otras-consideraciones')}
            isStriped={false}
          />
        </div>
      </div>
    </div>
  );
};

TemporadaDatosEconomicos.propTypes = propTypes;

export default TemporadaDatosEconomicos;
