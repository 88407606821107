import React, { useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../../context';
import {
  useBulldogAPIByPk,
} from '../../../hooks';
import LoadingSpinner from '../../LoadingSpinner';
import InmueblesDetallesAlquiler from './Alquiler';
import InmueblesDetallesCronologia from './Cronologia';
import InmueblesDetallesDatos from './Datos';
import InmueblesDetallesIngresosGastos from './IngresosGastos';
import Link from '../../Link';

const cfg = {
  menu: [
    { id: 'datos', Component: InmueblesDetallesDatos, filter: () => true },
    { id: 'alquiler', Component: InmueblesDetallesAlquiler, filter: (inmueble) => inmueble?.alquiler?.enabled },
    { id: 'cronologia', Component: InmueblesDetallesCronologia, filter: () => true },
    { id: 'ingresos-gastos', Component: InmueblesDetallesIngresosGastos, filter: () => true },
  ],
};

const InmueblesDetalles = (props) => {
  const { id: inmuebleId, sociedad } = useParams();
  const translations = useProvidedTranslations();
  const [tab, setTab] = useState(cfg.menu.find(({ filter }) => filter()).id);
  const { Component = <div /> } = cfg.menu.find(
    (element) => element.id === tab,
  );
  const [{
    isLoading,
    isFetching,
    data: {
      data: inmueble = {},
    } = {},
  }] = useBulldogAPIByPk('inmuebles', inmuebleId);
  const loading = (
    isLoading
    || isFetching
    || !Object.keys(inmueble).length
  );

  return (
    <>
      <nav className="nav-main">
          <div className="nav-main-item">
            <h1>{inmueble?.direccion}: {inmueble?.municipio}</h1>
          </div>
          <div className="nav-main-item">
              <Link
                toId={sociedad ? 'detalles-sociedad' : 'inmuebles'}
                className="button is-circle"
                params={{
                  section: 'inmuebles',
                  sociedad,
                }}
                >
                <span className="material-icons">
                  close
                </span>
              </Link>
          </div>
      </nav>
      <main className="main">
        <div className="container is-fluid">
          <div className="tabs is-centered mt-1">
            <ul>
              {loading
                ? <LoadingSpinner />
                : (
                  cfg.menu
                    .filter(({ filter }) => filter(inmueble))
                    .map(({ id }) => (
                    <li key={id} className={`${id === tab ? 'is-active' : ''}`}>
                      <a onClick={() => setTab(id)}>
                        {translations.formatMessage(`dash.inmueble.tabs._${id}`)}
                      </a>
                    </li>
                    ))
                )}
            </ul>
          </div>
          <div className="box box-white mt-2">
            {loading
              ? <LoadingSpinner />
              : <Component inmueble={inmueble} {...props} />
            }
          </div>
        </div>
      </main>
  </>
  );
};

export default InmueblesDetalles;
