import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import Cronologia from './index';

const CronologiaWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav
      mainClassName="main bg-cover is-fixed bg1"
      title={translations.formatMessage('dash.cronologia.header')}
    >
      <Cronologia />
    </LayoutNav>
  );
};

export default CronologiaWithLayout;
