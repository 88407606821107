import { useContext } from 'react';
import context from './context';

const useProvidedUserInfo = () => {
  const userInfoContext = useContext(context);
  if (!userInfoContext) {
    throw new Error('user info context its mandatory');
  }

  return userInfoContext;
};

export default useProvidedUserInfo;
