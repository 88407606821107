import React from 'react';
import PropTypes from 'prop-types';
import {
  useBulldogAPI,
} from '../../hooks';
import CronologiaLayout from './Layout';

const propTypes = {
  qs: PropTypes.shape(),
};

const defaultProps = {
  qs: {},
};

const Cronologia = (props) => {
  const { qs } = props;
  const [{
    data: { data = [] } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('cronologia', { tmp: undefined, limit: 50, ...qs });

  const loading = isLoading || isFetching;

  return (
    <CronologiaLayout
      data={data}
      isLoading={loading}
      />
  );
};

Cronologia.defaultProps = defaultProps;
Cronologia.propTypes = propTypes;

export default Cronologia;
