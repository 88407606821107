import React, { useState, useMemo } from 'react';
import {
  useProvidedConfiguration,
  useProvidedTranslations,
} from '../../context';
import { useBulldogAPI } from '../../hooks';
import BoxOverview from '../Box/Overview';
import LoadingSpinner from '../LoadingSpinner';

const cfg = {
  boxes: [
    { background: 'bg2', name: 'bruta' },
    { background: 'bg3', name: 'neta' },
  ],
};

const ResumenCantidadesPendientes = () => {
  const [{
    data: {
      data = [],
    } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('ingresos', { tmp: undefined });
  const [{ data: { tmp: current } = {} }] = useProvidedConfiguration();
  const [tab, setTab] = useState(current);
  const translations = useProvidedTranslations();
  const loading = isLoading || isFetching;
  const selectedTmp = useMemo(() => (
    data.find(({ tmp }) => tmp === tab)), [data, tab]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="tabs is-centered mt-1">
        <ul>
          {data
            .sort(({ order: a }, { order: b }) => b - a)
            .map((el) => (
              <li key={el.id} className={`${tab === el.tmp ? 'is-active' : ''}`}>
                <a onClick={() => setTab(el.tmp)}>
                  {translations.formatMessage(
                    current === el.tmp
                      ? 'dash.resumen.tabs_current'
                      : 'dash.resumen.tabs_pendiente',
                  )}{` ${el.tmp}`}
                </a>
              </li>
            ))
            }
        </ul>
      </div>
      <div className="columns is-multiline">
        {selectedTmp && cfg.boxes.map(({ background, name }) => (
          <BoxOverview
            key={name}
            background={background}
            bottom={{ value: selectedTmp?.overview[name]?.pendiente?.value }}
            left={{ value: selectedTmp?.overview[name]?.total?.value }}
            right={{ value: selectedTmp?.overview[name]?.pagada?.value }}
            title={translations.formatMessage(`dash.resumen.h2_${name}`, { tmp: tab })}
          />
        ))}
      </div>
    </>
  );
};

export default ResumenCantidadesPendientes;
