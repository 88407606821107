import React, { useMemo } from 'react';
import {
  useProvidedTranslations,
  useProvidedLanguage,
} from '../context';
import {
  useBulldogAPI,
} from '../hooks';
import LayoutNav from './LayoutNav';
import LoadingSpinner from './LoadingSpinner';
import {
  toLocaleStringOptions,
} from '../config';

const days = (seconds) => {
  if (!seconds) {
    return '-';
  }
  const now = new Date() / 1000;
  return Math.abs(Math.ceil((seconds - now) / 86400));
};

const percent = (data, type, element) => {
  const ref = data?.[type]?.[element]?.ref
    || data?.[type === 'future' ? 'past' : 'future']?.[element]?.date;

  if (!ref) {
    return 0;
  }

  const now = new Date() / 1000;
  const date = data?.[type]?.[element]?.date;
  return Number(Math.abs(((now - ref) * 100) / (date - ref)).toFixed(2));
};

const cfg = {
  future: [
    { id: 'nomina' },
    { id: 'ficha' },
    { id: 'temporada' },
    { id: 'contrato' },
    { id: 'carrera' },
    { id: 'representante' },
  ],
  past: [
    { id: 'temporada' },
    { id: 'contrato' },
    { id: 'carrera' },
  ],
};

const Temporizadores = () => {
  const [lang] = useProvidedLanguage();
  const translations = useProvidedTranslations();
  const [{
    data: { data: [data = {}] = [] } = {},
    isLoading,
    isFetching,
  }] = useBulldogAPI('temporizadores', { tmp: undefined });
  const loading = isLoading || isFetching;
  const today = (
    new Date().toLocaleDateString(lang, toLocaleStringOptions.basic)
  );
  const percents = useMemo(() => {
    const list = {};
    Object.keys(cfg).forEach((type) => {
      list[type] = {};
      cfg[type].forEach(({ id: element }) => {
        list[type][element] = percent(data, type, element);
      });
    });
    return list;
  }, [data]);

  return (
    <LayoutNav title={translations.formatMessage('dash.temporizadores.header')}>
      {loading && <LoadingSpinner />}
      {Object.keys(cfg).map((key) => (
        <div key={key} className="box">
          <header className="header-box">
            <h2>{translations.formatMessage(`dash.temporizadores.box.header._${key}`, { today })}</h2>
          </header>
            <div className="body-box bg-cover bg13">
              <div className="columns is-multiline">
                {cfg[key].map((element) => (
                  <div className="column is-4-desktop is-6-tablet" key={element.id}>
                  <div className="box-item">
                    <p className="qty color-primary has-text-centered">
                      {days(data?.[key]?.[element.id]?.date)}
                    </p>
                    <progress
                      className="progress is-small is-primary mb-1"
                      value={percents?.[key]?.[element.id] || 0} max="100">{percents?.[key]?.[element.id] || 0}%</progress>
                    <h3 className="h3-small has-text-centered">{translations.formatMessage(`dash.temporizadores.box.list._${key}._${element.id}`)}</h3>
                  </div>
                </div>
                ))}
              </div>
            </div>
        </div>
      ))}
    </LayoutNav>
  );
};

export default Temporizadores;
