import Debug from 'debug';
import axios from 'axios';

const debug = Debug('bulldog:lib:sendRequest');

export default async function sendRequest(opts) {
  debug('sendRequest', opts);
  const {
    bearer, body, formData, method, qs, url, ...extraOpts
  } = opts;

  // Parse formData if exists
  let formBody;
  if (formData) {
    // eslint-disable-next-line no-undef
    formBody = new FormData();
    Object.entries(formData).forEach(
      ([key, value]) => formBody.append(key, value),
    );
  }

  const headers = {
    ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
    ...(formData ? { 'Content-Type': 'multipart/form-data' } : {}),
  };

  const resp = await axios({
    ...extraOpts,
    url,
    method,
    params: qs,
    data: formBody || body,
    headers,
  });

  return [resp.data, resp.status, resp];
}
