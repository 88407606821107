import React from 'react';
import { staticContent } from '../config';

const Footer = () => (
  <footer>
    <p>
      <a href="#">
        <img src={`${staticContent}/images/pkf-attest-neg.svg`} alt="PKF Attest" className="logo-footer" />
      </a>
    </p>
    <p className="logo-title">Sport Finance</p>
  </footer>
);

export default Footer;
