import React from 'react';
import PropTypes from 'prop-types';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import DocumentListHeadersBlue from '../DocumentList/Headers/Blue';
import { useProvidedTranslations } from '../../context';
import formatDateFullYear from '../../lib/formatDateFullYear';

const sort = ({ date: dateA }, { date: dateB }) => dateA - dateB;

const cfg = {
  i18nKey: 'dash.avance-primas',
  tables: [
    'por-partidos',
    'por-goles',
    'por-convocatorias',
  ],
};

const params = {
  ...cfg,
  defaults: [
    'fecha',
    'numero',
    'partido',
    'competicion',
    'computa',
    'more-info',
    'comentario',
  ],
  endPoint: 'avance-primas',
  resources: [
    {
      id: 'fecha',
      className: 'sticky-col first-col',
      src: (data) => (
        formatDateFullYear(data?.date * 1000)
      ),
    },
    {
      id: 'numero',
      src: (data) => data?.numero,
    },
    {
      id: 'partido',
      src: (data) => `${data?.partido} ${data?.minutos ? `${data?.minutos}'` : ''}`,
    },
    {
      id: 'competicion',
      src: (data) => data?.competicion,
    },
    {
      id: 'tmp',
      src: (data) => data?.tmp,
    },
    {
      id: 'computa',
      src: (data, w, translations) => translations.formatMessage(
        `dash.basic.${data?.computa ? 'yes' : 'no'}`,
      ),
    },
    {
      id: 'more-info',
      className: 'has-text-right',
      src: (data, w, translations) => (
        <a
          className="button is-primary is-outlined is-small"
          target="_blank"
          href={data?.enlace}
          rel="noreferrer"
        >
          {translations.formatMessage(
            'dash.avance-primas.resources._more-info',
          )}
        </a>
      ),
    },
    {
      id: 'comentario',
      src: (data, w, translations) => {
        if (!data?.comentario) {
          return null;
        }
        return (
          <KnowMore
            modal={{
              title: translations.formatMessage(
                `dash.avance-primas.type._${data?.type}`,
                data,
              ),
              body: data?.comentario,
            }}
          />
        );
      },
    },
  ],
  sort,
};

const propTypes = {
  tmp: PropTypes.string.isRequired,
};

const TemporadaAvancePrimas = (props) => {
  const translations = useProvidedTranslations();
  const { tmp } = props;

  return (
    <>
      {cfg.tables.map((type) => (
        <div className="mb-4" key={type}>
          <DocumentList
            {...params}
            qs={{ tmp, type }}
            Header={() => (
              <DocumentListHeadersBlue
                title={translations.formatMessage(`${cfg.i18nKey}.h3._${type}`)}
              />
            )}
          />
        </div>
      ))}
    </>
  );
};

TemporadaAvancePrimas.propTypes = propTypes;

export default TemporadaAvancePrimas;
