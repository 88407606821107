import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useParams,
} from 'react-router-dom';
import {
  useProvidedTranslations,
} from '../../context';
import {
  useBulldogAPIByPk,
} from '../../hooks';
import LoadingSpinner from '../LoadingSpinner';
import Link from '../Link';
import EntornosImageProfile from './ImageProfile';
import EntornosLink from './Link';

const cfg = {
  enlaces: 5,
};

cfg.getResources = (data, translations) => {
  const response = [
    { id: 'actividad', src: data?.actividad },
    { id: 'direccion', src: data?.direccion },
    { id: 'contacto', src: data?.contacto },
    { id: 'email', src: data?.email },
    {
      id: 'web',
      src: (
        <EntornosLink
          link={{
            url: data?.web,
            cta: translations.formatMessage('dash.entornos.resources._web'),
          }}
        />
      ),
    },
  ];

  for (let i = 0; i <= cfg.enlaces; i += 1) {
    if (data.enlaces && data.enlaces[i]) {
      response.push({
        id: 'enlaces-interes',
        src: (
          <EntornosLink
            link={data.enlaces[i]}
          />
        ),
      });
    }
  }

  return response;
};

const EntornosDetalles = () => {
  const { id: pk } = useParams();
  const translations = useProvidedTranslations();
  const [{
    isLoading,
    isFetching,
    data: {
      data: entorno = {},
    } = {},
  }] = useBulldogAPIByPk('entorno', pk);
  const loading = (
    isLoading
    || isFetching
    || !Object.keys(entorno).length
  );
  const resources = React.useMemo(() => (
    cfg.getResources(entorno, translations)
  ), [entorno, translations]);

  return (
    <>
      <nav className="nav-main">
          <div className="nav-main-item">
            <h1>{entorno?.name}</h1>
          </div>
          <div className="nav-main-item">
              <Link
                toId="entorno"
                className="button is-circle"
                params={{
                  section: 'entorno',
                }}
                >
                <span className="material-icons">
                  close
                </span>
              </Link>
          </div>
      </nav>
      <main className="main">
        <div className="container is-fluid">
          <div className="box box-white mt-2">
            {loading
              ? <LoadingSpinner />
              : (
                <div className="body-box b-0">
                  <h3 className="h3-header">{entorno?.name || translations.formatMessage('dash.entorno.detalles')}</h3>
                  <table className="table is-striped is-fullwidth mt-1">
                    <tbody>
                      <tr>
                        <td colSpan={2} className="has-text-centered">
                          <EntornosImageProfile entorno={entorno} />
                        </td>
                      </tr>
                      {resources.map(({ className, id, src }, i) => (
                        <tr key={id} className={i === 0 ? 'tableizer-firstrow' : undefined}>
                          <td className={className}>{translations.formatMessage(`dash.inmueble.list._${id}`)}</td>
                          <td className={`has-text-right ${className}`}>{src}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {entorno?.comentarios && (
                    <ReactMarkdown className="has-text-dark mt-1">
                      {entorno?.comentarios}
                    </ReactMarkdown>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </main>
  </>
  );
};

export default EntornosDetalles;
