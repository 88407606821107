import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Debug from 'debug';
import { useAuth0 } from '@auth0/auth0-react';
import useAPIDefault from './useAPI';

const debug = Debug('bulldog:useAPIAuthorized');

/**
 * Calls API using bearer from context
 * @param {Object} opts - Request opts
 * @param {Boolean=true} opts.waitForToken - True to do not fetch
 *   until useProvidedAuth token is loaded
 * @param {function} onSuccess
 * @param {function} onError
 * @param {Hook} useAPI - useAPI hook
 */
const useAPIAuthorized = (opts, onSuccess, onError, useAPI = useAPIDefault) => {
  const {
    getAccessTokenSilently,
    isLoading: isLoadingAuth,
    isAuthenticated,
    logout,
  } = useAuth0();
  const [nextFetchOpts, setNextFetchOpts] = useState(opts.wait ? false : {});
  const { waitForToken = true } = opts;
  const [
    token,
    setToken,
  ] = useState();
  (async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  })();

  // Handle API authorization errors
  const handleError = useCallback((resp) => {
    debug('handleError %j', resp);
    const { response: { status } = {} } = resp;
    if (onError) {
      onError(resp);
    }
    if (opts.waitForToken === false) {
      return;
    }

    switch (status) {
      case 401:
        logout();
        break;
      default:
        break;
    }
  }, [logout, onError, opts.waitForToken]);

  const [apiResp, doFetch] = useAPI({
    ...opts,
    wait: true,
    bearer: token,
  }, onSuccess, handleError);
  const handleFetch = useCallback((newOpts, ...args) => {
    if (!token) {
      debug('no fetch, set waiting fetch to true');
      setNextFetchOpts((prev) => ({ ...(prev || {}), ...newOpts }));
      return;
    }
    debug('will do fetch', token);
    doFetch({ ...newOpts, bearer: token }, ...args);
  }, [doFetch, token]);

  // Re-fetch data when token changes
  useEffect(() => {
    if ((token || !waitForToken) && !isLoadingAuth && nextFetchOpts) {
      debug('fetch', token);
      setNextFetchOpts(false);
      doFetch({ bearer: token, ...nextFetchOpts });
    }
  }, [doFetch, nextFetchOpts, token, waitForToken, isLoadingAuth]);
  const parsedResp = useMemo(() => ({
    ...apiResp,
    isLoading: (
      apiResp.isLoading
      || isLoadingAuth
      || (!token && waitForToken)),
  }), [apiResp, isLoadingAuth, nextFetchOpts, token, waitForToken]);

  return [
    parsedResp,
    handleFetch,
  ];
};

export default useAPIAuthorized;
