import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import Avales from './index';

const cfg = {
  i18nKey: 'dash.avales',
};

const AvalesWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage(`${cfg.i18nKey}.header`)}>
      <Avales />
    </LayoutNav>
  );
};

export default AvalesWithLayout;
