import React from 'react';
import {
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../routes';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  toId: PropTypes.string,
  params: PropTypes.shape(),
  pathname: PropTypes.string,
};

const defaultProps = {
  className: '',
  toId: undefined,
  params: {},
  pathname: undefined,
};

const Link = (props) => {
  const {
    children, toId, params, pathname, className, ...rest
  } = props;
  const route = routes.find(({ key }) => key === toId);
  const { docId } = useParams();
  const replace = { docId, ...params };

  if (toId && route) {
    let { path } = route;
    Object.keys(replace).forEach((element) => {
      path = path.replace(`:${element}`, replace[element]);
    });

    return (
      <RouterLink {...rest} to={path} className={`${className} ${pathname === path ? 'is-active' : ''}`}>
        {children}
      </RouterLink>
    );
  }

  return <a href={toId} {...rest}>{children}</a>;
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
