import React from 'react';
import PropTypes from 'prop-types';
import {
  staticContent,
} from '../../config';

const propTypes = {
  entorno: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

const EntornosImageProfile = (props) => {
  const { entorno: { logo, name } } = props;

  if (!logo) {
    return null;
  }

  return (
    <img
      className="is-maxw-90"
      src={`${staticContent}/images/entornos/${logo}`}
      alt={name}
    />
  );
};

EntornosImageProfile.propTypes = propTypes;

export default EntornosImageProfile;
