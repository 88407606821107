import React from 'react';
import {
  useProvidedTranslations,
} from '../../context';
import LayoutNav from '../LayoutNav';
import Declaraciones from './index';

const cfg = {
  i18nKey: 'dash.declaraciones',
};

const DeclaracionesWithLayout = () => {
  const translations = useProvidedTranslations();

  return (
    <LayoutNav title={translations.formatMessage(`${cfg.i18nKey}.header`)}>
      <Declaraciones />
    </LayoutNav>
  );
};

export default DeclaracionesWithLayout;
