import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';

const propTypes = {
  hide: PropTypes.func.isRequired,
  i18nKey: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.shape({
    Component: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    src: PropTypes.func,
  }).isRequired).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelected: PropTypes.func.isRequired,
};

const defaultProps = {
  i18nKey: 'dash.document-list.modal',
};

const DocumentListModal = (props) => {
  const {
    hide, i18nKey, resources, selected, setSelected,
  } = props;
  const translations = useProvidedTranslations();
  const handleClick = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((el) => el !== id));
      return;
    }
    setSelected([...selected, id]);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            {translations.formatMessage(`${i18nKey}.modal.title`)}
          </p>
          <a className="button is-circle-sm" onClick={() => hide()}>
            <span className="material-icons">
              close
            </span>
          </a>
        </header>
        <section className="modal-card-body">
          <p className="lead">
            {translations.formatMessage(`${i18nKey}.modal.lead`)}
          </p>
          <div className="columns mt-1 is-multiline">
            {resources.map(({ id, disabled = false }) => (
              <div key={id} className="column is-6-tablet">
                <div className="field">
                  <input
                    checked={selected.includes(id)}
                    className="is-checkradio is-info"
                    disabled={disabled}
                    id={`checkbox-${id}`}
                    type="checkbox"
                    onChange={() => handleClick(id)}
                  />
                  <label htmlFor={`checkbox-${id}`}>
                    {translations.formatMessage(`${i18nKey}.resources._${id}`)}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </section>
        <footer className="footer-card">
          <div className="buttons-footer">
            <div className="buttons">
              <button
                className="button is-primary btn-block-xs"
                type="button"
                onClick={() => hide()}
              >
                {translations.formatMessage('dash.document-list.modal.close')}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

DocumentListModal.propTypes = propTypes;
DocumentListModal.defaultProps = defaultProps;

export default DocumentListModal;
