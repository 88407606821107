import React from 'react';
import PropTypes from 'prop-types';
import { staticContent } from '../config';
import {
  useProvidedLoading,
  useProvidedTranslations,
} from '../context';

const propTypes = {
  display: PropTypes.bool,
};

const defaultProps = {
  display: false,
};

const Loading = ({ display }) => {
  const [loading] = useProvidedLoading();
  const translations = useProvidedTranslations();

  if (!loading && !display) {
    return null;
  }
  const loadingText = loading.text
  || translations.formatMessage(loading.translationKey || 'dash.loading');

  return (
    <div className="loading bg-cover is-100vh bg1">
      <div className="content">
        <div className="control has-text-centered">
          <img alt={loadingText} className="icon-loading" src={`${staticContent}/images/loading.svg`} />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
