import React from 'react';
import DocumentList from '../DocumentList';
import KnowMore from '../KnowMore';
import DocumentListHeadersBlue from '../DocumentList/Headers/Blue';
import formatDateFullYear from '../../lib/formatDateFullYear';
import EscudosEquipos from '../EscudosEquipos';

const cfg = {
  i18nKey: 'dash.contrato.calendario',
};

const params = {
  ...cfg,
  defaults: [
    'tipo',
    'equipo',
    'bruto',
    'irpf',
    'seguridad-social',
    'otros',
    'neto',
    'fecha-prevista',
    'bruto-cobrado',
    'neto-cobrado',
    'fecha-cobro',
    'bruto-pendiente',
    'neto-pendiente',
    'comentario',
  ],
  endPoint: 'calendario-cobro-temporadas',
  resources: [
    {
      id: 'tipo',
      className: 'sticky-col first-col-wide',
      src: (data) => data?.type,
    },
    {
      id: 'equipo',
      src: (data) => <EscudosEquipos name={data?.equipo} />,
    },
    {
      id: 'bruto',
      src: (data, w = 'value') => data?.bruto?.total?.[w],
      num: true,
    },
    {
      id: 'irpf',
      src: (data, w = 'value') => data?.irpf?.[w],
      num: true,
    },
    {
      id: 'seguridad-social',
      src: (data, w = 'value') => data?.ss?.[w],
      num: true,
    },
    {
      id: 'otros',
      src: (data, w = 'value') => data?.otros?.[w],
      num: true,
    },
    {
      id: 'neto',
      src: (data, w = 'value') => data?.neto?.total?.[w],
      num: true,
    },
    {
      id: 'fecha-prevista',
      src: (data) => {
        const date = data?.fechas?.prevista;
        return date
          ? formatDateFullYear(date * 1000)
          : null;
      },
    },
    {
      id: 'bruto-cobrado',
      src: (data, w = 'value') => data?.bruto?.cobrado?.[w],
      num: true,
    },
    {
      id: 'neto-cobrado',
      src: (data, w = 'value') => data?.neto?.cobrado?.[w],
      num: true,
    },
    {
      id: 'fecha-cobro',
      src: (data) => {
        const date = data?.fechas?.cobro;
        return date
          ? formatDateFullYear(date * 1000)
          : null;
      },
    },
    {
      id: 'bruto-pendiente',
      className: 'hl',
      src: (data, w = 'value') => data?.bruto?.pendiente?.[w],
      num: true,
    },
    {
      id: 'neto-pendiente',
      className: 'hl',
      src: (data, w = 'value') => data?.neto?.pendiente?.[w],
      num: true,
    },
    {
      id: 'comentario',
      src: (data) => {
        if (!data?.comentarios) {
          return '-';
        }

        return (
          <KnowMore
            modal={{
              title: data?.type,
              body: data?.comentarios,
            }}
            />
        );
      },
    },
  ],
  qs: { tmp: undefined },
  Header: () => (
    <DocumentListHeadersBlue
      i18nKey={cfg.i18nKey}
    />
  ),
};

const ContratoCalendario = () => <DocumentList {...params} />;

export default ContratoCalendario;
