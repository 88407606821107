import { useContext } from 'react';
import context from './context';

/**
 * Loads Notifications context
 * @returns {[Object,function]}
 */
const useProvidedNotifications = () => {
  const notificationsContext = useContext(context);
  if (!notificationsContext) {
    throw new Error('notifications context its mandatory');
  }

  return notificationsContext;
};

export default useProvidedNotifications;
