import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedTranslations,
} from '../../context';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  isDisabled: false,
  isSelected: false,
};

const PaginationButton = ({
  children, isDisabled, isSelected, onClick, page,
}) => {
  const translations = useProvidedTranslations();

  return (
    <li>
      <a
        aria-label={translations.formatMessage('dash.pagination.go-to', { page })}
        className={`pagination-link ${isSelected ? 'is-current' : ''}`}
        disabled={isDisabled}
        type="button"
        onClick={isDisabled ? () => null : () => onClick()}
      >
        {children}
      </a>
    </li>
  );
};

PaginationButton.propTypes = propTypes;
PaginationButton.defaultProps = defaultProps;

export default PaginationButton;
