/* eslint-disable max-len */
import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Debug from 'debug';
import context from './context';
import parseElapsed from './lib/parseElapsed';
import parseTimeAmount from './lib/parseTimeAmount';
import toKebabCase from './lib/toKebabCase';

const debug = Debug('bulldog:Translations:useProvidedTranslations');
const parseCurrencyOpts = (contextOpts = {}, paramsOpts) => {
  const {
    id = contextOpts.id,
    rate = contextOpts.rate || 1,
  } = paramsOpts || {};
  if (!id) {
    throw new Error('currency id is mandatory');
  }
  return { id, rate };
};
const useProvidedTranslations = () => {
  const intl = useIntl();
  const translationArgs = useContext(context);

  return useMemo(() => ({
    /**
     * Format message using translations
     * @param {String} id - Message id
     * @param {Object} values - object which are used to fill placeholders in the message
     * @param {Object} opts - other possible options: defaultMessage and description
     * @param {String=id} opts.defaultMessage - Message to show if no translation provided
     * @param {String=id} opts.description - Message description
     * @returns {String} - return a formatted message string
     */
    formatMessage: (id, values, { defaultMessage = id, ...opts } = {}) => intl.formatMessage(
      { ...opts, defaultMessage, id: toKebabCase(id) },
      values,
      { ignoreTag: true },
    ),

    /**
     * Format number in context locale
     * @param {Number} number - Number to format
     * @param {Object} options - Options to format number
     * @param {String} options.style - Style to format (percent, currency)
     * @param {String} options.currency - Currency to format (EUR,USD...)
     * @returns {String} - Parsed number
     */
    formatNumber: (number, options) => new Intl.NumberFormat('de-DE', options).format(number),

    /**
     * Format date in context locale
     * @param {Date} date - Date to format
     * @param {Object} options - Options to format date
     * @returns {String} - Parsed date
     */
    formatDate: (date, options) => intl.formatDate(date, options),

    /**
     * Format date in context locale
     * @param {Number} unixTimestamp - Unix date to parse
     * @param {Object} options - Options to format date
     * @returns {String} - Parsed date
     */
    formatUnixDate: (unixTimestamp, options) => intl.formatDate(unixTimestamp * 1000, options),

    /**
     * Format a currency into a string
     * @param {Number} amount - Number to format
     * @returns {String} -
     * [Amount parsed, currency symbol string, true if amount goes before currency]
     */
    formatCurrency: (amount, currencyOpts) => {
      const {
        id: currencyId,
        rate: currencyRate,
      } = parseCurrencyOpts(translationArgs.currency, currencyOpts);
      const convertedAmount = amount * currencyRate;
      return new Intl.NumberFormat(intl.locale, { style: 'currency', currency: currencyId })
        .format(convertedAmount);
    },

    /**
     * Format a currency in 3 parts
     * @param {Number} amount - Number to format
     * @param {Object?} currencyOpts - If not present it's loaded from context
     * @param {String} currencyOpts.id - Currency id
     * @param {Number} currencyOpts.rate - Currency rate
     * @returns {[Number,String,Boolean]} -
     * [Amount parsed, currency symbol string, true if amount goes before currency]
     */
    formatCurrencyParts: (amount, currencyOpts) => {
      const {
        id: currencyId,
        rate: currencyRate,
      } = parseCurrencyOpts(translationArgs.currency, currencyOpts);
      debug('formatCurrencyParts %j', { amount, currencyId, currencyRate });
      const convertedAmount = amount * currencyRate;
      const formatOpts = { style: 'currency', currency: currencyId };
      const priceEn = new Intl.NumberFormat('en', formatOpts).format(convertedAmount);
      const [, currency] = priceEn.match(/([^0-9]+)([0-9].*$)/) || [];
      const amountInLocale = new Intl.NumberFormat(intl.locale, formatOpts).format(convertedAmount);
      const isAmountFirst = amountInLocale.match(/^[0-9]/);
      const [, amountToRender] = amountInLocale.match(/([0-9].*[0-9])/) || [];
      return [amountToRender, currency, isAmountFirst];
    },

    /**
     * Format number in context locale shortened with Ks and Ms
     * @param {Number} number - Number to format
     * @param {Object} options - Options to format number
     * @param {String} options.style - Style to format (percent, currency)
     * @param {String} options.currency - Currency to format (EUR,USD...)
     * @returns {[String,String]} - [Parsed number,Symbol (mm ,k or null)]
     */
    formatNumberShort: (numberToParse, options) => {
      const number = Number(numberToParse);
      const millions = number / 1000000;
      const thousands = number / 1000;

      if (millions > 1) {
        return [intl.formatNumber(millions, { maximumFractionDigits: 2, ...options }), 'mm'];
      }

      if (thousands > 1) {
        return [intl.formatNumber(thousands, { maximumFractionDigits: 2, ...options }), 'k'];
      }

      return [intl.formatNumber(number, options)];
    },

    /**
     * Format elapsed time
     * @param {Number} elapsedSeconds - Elapsed time in seconds
     * @returns {String} - Elapsed string ej. "1 hour ago"
     */
    formatElapsed: (elapsedSeconds) => parseElapsed(elapsedSeconds, intl.locale),

    /**
     * Formats a time amount
     * @param {Number} leftSeconds - Left time in seconds
     * @returns {String} - Parsed string ej. "1 hour"
     */
    formatTimeAmount: (leftSeconds) => parseTimeAmount(leftSeconds, intl.locale),
  }), [intl, translationArgs]);
};

export default useProvidedTranslations;
