import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import {
  useProvidedTranslations,
  useProvidedLanguage,
} from '../../context';
import {
  chartColors,
} from '../../config';

const cfg = {
  options: {
    colors: chartColors,
    curveType: 'function',
    legend: { position: 'bottom' },
    hAxis: {
      showTextEvery: 1,
    },
  },
};

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  type: PropTypes.string.isRequired,
};
const defaultProps = {};


const EvolucionesGraph = (props) => {
  const { data, resources, type } = props;
  const translations = useProvidedTranslations();
  const [lang] = useProvidedLanguage();
  const chartData = useMemo(() => {
    const arr = [
      resources.map(({ id }) => (
        translations.formatMessage(`dash.evoluciones.table.th._${type}._${id}`)
      )),
    ];
    data
      .sort(({ date: dateA }, { date: dateB }) => dateA - dateB)
      .forEach((dataRow) => {
        const row = [];
        resources.forEach(({
          id,
          src = (element) => element?.[type]?.[id] || {},
        }) => row.push(src(dataRow, lang, translations).raw));
        arr.push(row);
      });

    return arr;
  }, [data]);

  return <div className="table-container">
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={chartData}
      options={cfg.options}
    />
  </div>;
};

EvolucionesGraph.propTypes = propTypes;
EvolucionesGraph.defaultProps = defaultProps;

export default EvolucionesGraph;
