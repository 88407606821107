import React from 'react';
import PropTypes from 'prop-types';
import {
  useProvidedConfiguration,
} from '../context';
import {
  staticContent,
} from '../config';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  className: 'escudo-tabla mr-1',
};

const EscudosEquipos = (props) => {
  const { className, name } = props;
  const [{ data: { escudos } = {} }] = useProvidedConfiguration();
  const src = escudos[name] || `${name.replace(/ /g, '-').toLowerCase()}.jpg`;

  if (!name) {
    return null;
  }

  return (
    <img
      alt={name}
      className={className}
      src={`${staticContent}/images/equipos/${src}`}
    />
  );
};

EscudosEquipos.propTypes = propTypes;
EscudosEquipos.defaultProps = defaultProps;

export default EscudosEquipos;
